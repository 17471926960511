import Cookies from 'js-cookie'

import varna from 'utils/varna'

import { ONE_DAY, REDIRECT_KEY, THIRTY_MINUTES } from './auth0-constants'

export const saveCurrentUrl = () => {
  const { pathname, href } = document.location
  const params = new URL(href).searchParams
  const requestUri = params.get('request_uri')

  const path = pathname.substring(1)
  const onErrorPage = path && !isNaN(Number(path))

  // Don't save the current url if the url doesn't lead to a specific page
  const doNotSave =
    onErrorPage || ['/', '/dashboard', '/login', '/logout'].includes(pathname)
  if (doNotSave && !requestUri) return

  // If requestUri exist in the url (redirected from docs), use that value instead
  const lastAppUrl = requestUri || href
  sessionStorage.setItem(REDIRECT_KEY, lastAppUrl)
  Cookies.set(REDIRECT_KEY, lastAppUrl, {
    expires: THIRTY_MINUTES / ONE_DAY,
    sameSite: 'lax'
  })
}

export const getRedirectUrl = () => {
  const { href, origin } = document.location
  const params = new URL(href).searchParams

  // prettier-ignore
  const currentUrl = href.includes('?code=') ? origin : href
  const url =
    params.get('request_uri') || sessionStorage.getItem(REDIRECT_KEY) || currentUrl
  return url
}

export const throwAuthError = (message: string, data?: any) => {
  varna.info(`Auth0 (custom): ${message}`, data)
  throw new Error(`Auth0: ${message}`)
}
