import React, { Fragment, PureComponent } from 'react'
import classnames from 'classnames'
import mixpanel from 'utils/mixpanel'

import Button from 'components/Button'
import { Close, Feedback as FeedbackIcon } from 'icons'

import feedbackImg from './images/feedback.svg'
import frownSelImg from './images/frown-sel.png'
import frownImg from './images/frown.png'
import lifeSaverImg from './images/life_saver.svg'
import okSelImg from './images/ok-sel.png'
import okImg from './images/ok.png'
import smileSelImg from './images/smile-sel.png'
import smileImg from './images/smile.png'
import './FeedbackForm.scss'

const images = {
  frown: [frownImg, frownSelImg, "It's not for me"],
  ok: [okImg, okSelImg, "It's OK"],
  smile: [smileImg, smileSelImg, 'I love it!']
}

export default class FeedbackForm extends PureComponent {
  state = {
    active: false,
    feedback: null,
    score: null,
    screen: 'home'
  }

  constructor(props) {
    super(props)
    window.openFeedback = this.toggleWindow
  }

  sendFeedback = async () => {
    const { feedback, score } = this.state
    this.setState({ sending: true })

    try {
      mixpanel.track('User Sent Feedback', {
        score: score === null ? null : score * 50,
        feedback
      })
      this.setState({ sending: false, msg: 'Sent!' })
      await new Promise((resolve) => setTimeout(resolve, 1600))
      this.toggleWindow()
    } catch (e) {
      this.setState({ sending: false, msg: 'Sending Failed' })
      await new Promise((resolve) => setTimeout(resolve, 2000))
      this.setState({ msg: null })
    }
  }

  toggleWindow = (state) => {
    const active = state || this.state.active
    if (active) {
      this.setState({
        active: false,
        score: null,
        feedback: null,
        msg: null,
        screen: 'home'
      })
    } else {
      this.setState({ active: true })
    }
  }

  openServiceDesk = () => {
    window.open(
      'https://closedloop.atlassian.net/servicedesk/customer/portal/3',
      '_blank'
    )
    this.toggleWindow()
  }

  render() {
    const { feedback, msg, score, sending } = this.state
    const active = this.props.active || this.state.active
    const screen = this.props.screen || this.state.screen

    if (!active) return null

    return (
      <div className="feedback-form">
        <div className="feedback-form__header">
          <FeedbackIcon className="feedback-form__feedback-icon" alt="" />
          Help & Feedback
          <Close
            className="feedback-form__close-icon"
            alt="Close Feedback Form"
            onClick={() => this.toggleWindow()}
            fill="#fff"
          />
        </div>
        <div className={classnames('feedback-form__body', screen)}>
          {screen === 'home' && (
            <Fragment>
              <Button
                className="feedback-form__need-assistance"
                type="link"
                onClick={this.openServiceDesk}
              >
                <img src={lifeSaverImg.default} alt="life saver" />
                <span>Need Assistance</span>
              </Button>
              <Button
                className="feedback-form__need-a-suggestion"
                type="link"
                onClick={() => this.setState({ screen: 'feedback' })}
              >
                <img src={feedbackImg.default} alt="discussion bubbles" />
                <span>Leave Feedback</span>
              </Button>
            </Fragment>
          )}
          {screen === 'feedback' && (
            <Fragment>
              <div className="feedback-form__section">
                <span className="smiley-text">How do you like our platform?</span>
                {Object.keys(images).map((name, i) => (
                  <div
                    key={`name-${i}`}
                    className="feedback-form__smiley"
                    onClick={() => this.setState({ score: i })}
                  >
                    <img
                      className="deselected-smiley"
                      src={images[name][0]}
                      style={score === i ? { opacity: 0 } : {}}
                      alt={name}
                    />
                    <img className="selected-smiley" src={images[name][1]} alt={name} />
                    <div>{images[name][2]}</div>
                  </div>
                ))}
              </div>
              <div className="feedback-form__section">
                <span>How can we improve?</span>
                <textarea
                  onChange={(e) => {
                    this.setState({ feedback: e.target.value })
                  }}
                />
              </div>
              {msg ? (
                <div className="feedback-form__msg">{msg}</div>
              ) : (
                <Button
                  type="primary"
                  disabled={!(score !== null || feedback)}
                  onClick={this.sendFeedback}
                >
                  {sending ? 'Sending' : 'Submit'}
                </Button>
              )}
              <div className="feedback-form__contact-us">
                Need help? &nbsp;
                <a href="mailto:support@closedloop.ai" target="_top">
                  Contact us
                </a>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    )
  }
}
