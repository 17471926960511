import React from 'react'

export default {
  tooltip: {
    pmpmCostBreakdown: (
      <>
        <b>The per member per month (PMPM) cost breakdown</b> includes paid claims only,
        for all members with eligibility during the selected month(s). The paid cost is
        displayed for all cost centers except prescriptions, which represents out of
        pocket costs.
      </>
    ),
    costCenters: {
      costCenter: (
        <>
          <b>Cost centers</b> are determined based on claim type. Note, the professional
          cost center includes carrier and durable medical equipment (DME) claim types.{' '}
        </>
      ),
      pmpm: (
        <>
          <b>Per member per month (PMPM) cost</b> is the sum of costs divided by the total
          number of members. It includes paid medical claims only, for all members with
          eligibility during the month/year(s) selected above. The paid cost is displayed
          for all cost centers except prescriptions, which represents out of pocket costs.
        </>
      ),
      pmpmYOY: (
        <>
          The <b>percent change in per member per month (PMPM)</b> cost for the selected
          month(s), compared to the same month(s) in the previous year. It includes paid
          medical claims only, for all members with eligibility during the month/year(s)
          selected above.
        </>
      ),
      totalCost: (
        <>
          <b>Total cost</b> includes paid medical claims only, for all members with
          eligibility during the month/year(s) selected above. The paid cost is displayed
          for all cost centers except prescriptions, which represents out of pocket costs.
        </>
      ),
      totalCostYOY: (
        <>
          The <b>percent change in total cost (TC)</b> for the selected month(s), compared
          to the same month(s) in the previous year. It includes paid medical claims only,
          for all members with eligibility during the month/year(s) selected above.
        </>
      )
    }
  },
  inline: {}
}
