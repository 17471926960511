import { useState } from 'react'

const useForceUpdate: () => [number, () => void] = () => {
  const [trigger, setTrigger] = useState(0)
  const forceUpdate = () => {
    setTrigger(trigger + 1)
  }
  return [trigger, forceUpdate]
}

export default useForceUpdate
