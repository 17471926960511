import React, { useEffect } from 'react'
import mixpanel from 'utils/mixpanel'

import { AuthorizationAccess } from 'state/AuthorizationState'
import TabSync from 'providers/AuthProvider/TabSync'

import Button from 'components/Button'
import HeadlineLayout from 'components/Layout/HeadlineLayout'
import { getErrorDetails } from 'utils/helpers'

import Vault from '../../graphics/Vault'
import ExceptionPageLayout from './components/ExceptionPageLayout'

const tabSync = new TabSync('authSync')

const UnauthorizedScreen = () => {
  const authorizationAccess = AuthorizationAccess()
  const match = document.location.search.match(/\?e=?(.+)/)
  const errorCode = match ? match[1] : ''

  mixpanel.trackPageViewed({
    page_name: 'Exception',
    exception_type: '401',
    exception_code: errorCode
  })

  useEffect(() => {
    tabSync.onMessage((msg) => {
      if (msg === 'login') {
        setTimeout(() => window.history.back(), 2000)
      }
    })

    return () => {
      tabSync.onMessage(() => null)
    }
  }, [])

  var reason = (function (code) {
    switch (code) {
      case '84946':
        return 'Invalid Audience'
      case '57928':
        return (
          <span>
            We're in the process of linking your accounts. Click the button below and sign
            in again.
          </span>
        )
      default:
        return 'Unauthorized'
    }
  })(errorCode)

  const onClick = () =>
    errorCode === '84946'
      ? window.location.reload()
      : authorizationAccess.logout({ reason: 'manual' })

  return (
    <ExceptionPageLayout className="unauthorized-page">
      <HeadlineLayout
        title={401}
        subtitle={reason}
        content={
          <Button type="primary" className="mt-16" onClick={onClick}>
            Logout
          </Button>
        }
        graphics={<Vault className="w-2xl" />}
      />
    </ExceptionPageLayout>
  )
}

const showUnauthorizedScreen = (e) => {
  const authorizationAccess = AuthorizationAccess()
  const { detail, message } = getErrorDetails(e)
  const { pathname } = document.location

  if (detail.startsWith('Token expired')) {
    return authorizationAccess.logout({ reason: 'expired' })
  }

  if (pathname !== '/401') {
    const errorCode = detail.startsWith('Invalid audience.')
      ? '84946'
      : detail === 'Auth rejected token: Unauthorized'
      ? '57928'
      : message === 'Unauthorized'
      ? '97311'
      : '38016'
    document.location = errorCode ? '/401?e=' + errorCode : '/401'
  }
}

export { UnauthorizedScreen as default, showUnauthorizedScreen }
