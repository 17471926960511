import React from 'react'
import { noop } from 'lodash'

import './Blanket.scss'

export interface BlanketProps {
  /** Whether mouse events can pierce the blanket. If true, onBlanketClicked will not be fired */
  canClickThrough?: boolean
  /** Whether the blanket has a tinted background color. */
  isTinted?: boolean
  /** Handler function to be called when the blanket is clicked */
  onBlanketClicked?: (event: React.MouseEvent<HTMLDivElement>) => void
}

const Blanket: React.FC<BlanketProps> = ({
  canClickThrough = false,
  isTinted = false,
  onBlanketClicked = noop
}) => {
  const onClick = canClickThrough ? undefined : onBlanketClicked

  return (
    <div
      className="blanket_v3"
      onClick={onClick}
      style={{
        opacity: isTinted ? 1 : 0,
        pointerEvents: canClickThrough ? 'none' : 'initial'
      }}
    />
  )
}

Blanket.displayName = 'Blanket'
export default Blanket
