import React from 'react'
import { Router as Wouter } from 'wouter'
import makeMatcher from 'wouter/matcher'

import useLocation from './useLocation'

const Router = ({ children }) => {
  const defaultMatcher = makeMatcher()

  return (
    <Wouter
      hook={useLocation}
      matcher={(patterns, path) => {
        for (let pattern of [patterns].flat()) {
          const [match, params] = defaultMatcher(pattern, path)
          if (match) return [match, params]
        }

        return [false, null]
      }}
    >
      {children}
    </Wouter>
  )
}
export default Router
