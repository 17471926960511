import React from 'react'

import './ModalBody.scss'

export interface ModalBodyProps {
  children: React.ReactNode
}

const ModalBody: React.FC<ModalBodyProps> = ({ children }) => {
  return <div className="modal_v3__body">{children}</div>
}

ModalBody.displayName = 'ModalBody'
export default ModalBody
