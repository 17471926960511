import React, { ReactNode } from 'react'

import './HeadlineLayout.scss'

type HeadlineLayoutProps = {
  title?: ReactNode
  subtitle?: ReactNode
  content?: ReactNode
  graphics?: ReactNode
  children?: ReactNode
}

const HeadlineLayout = ({
  content,
  children,
  graphics,
  title,
  subtitle
}: HeadlineLayoutProps) => {
  return (
    <div className="headline-layout">
      <div className="headline-layout-wrapper">
        {graphics && <div>{graphics}</div>}
        <div className="headline-layout-content">
          {title && <h2 className="headline-layout-title">{title}</h2>}
          {subtitle && <div className="headline-layout-subtitle">{subtitle}</div>}
          {content && <div>{content}</div>}
          {children && <div>{children}</div>}
        </div>
      </div>
    </div>
  )
}

export default HeadlineLayout
