import { useMemo } from 'react'
import { createState, useState } from '@hookstate/core'
import { find, get, set, startCase } from 'lodash'

import API from 'providers/API'

import { mapValues } from 'utils/helpers'

// hookState isn't synchronous so we'll point to a global obj for instant truth
let fetchState: FetchState = {}

const initialState = createState({
  dataAdapterTemplates: [],
  fetchState: {}
})

const stateWrapper = (state) => {
  const wrapper = {
    get fetchedDataAdapterTemplates() {
      return !!get(fetchState, `fetched`)
    },
    get fetchingDataAdapterTemplates() {
      return !!get(fetchState, `fetching`)
    },
    get fetchingFailedDataAdapterTemplates() {
      return !!get(fetchState, `failedToFetch`)
    },
    get dataAdapterTemplates() {
      const dataAdapterTemplates = state.dataAdapterTemplates.get()

      if (!dataAdapterTemplates) return null
      return dataAdapterTemplates
    },
    findDataAdapterTemplate({ ...templateParams }: {}) {
      if (typeof templateParams !== 'object') {
        throw new Error('Data Adapter Template State: Params should be an object')
      }

      return find(this.dataAdapterTemplates, templateParams)
    },
    async fetchDataAdapterTemplates() {
      set(fetchState, `fetching`, true)
      state.fetchState.set(fetchState)

      try {
        const res = await API.get('/content/dataAdapterTemplates')
        const dataAdapterTemplates = enhanceDataAdapterTemplates(res.data)

        fetchState = { ...fetchState, fetched: true, fetching: false }

        state.fetchState.set(fetchState)
        state.dataAdapterTemplates.set(dataAdapterTemplates)
        return dataAdapterTemplates
      } catch (err) {
        fetchState = { ...fetchState, failedToFetch: true, fetching: false }

        state.fetchState.set(fetchState)
        return Promise.reject(err)
      }
    }
  }
  return mapValues(wrapper, (f) =>
    typeof f === 'function' ? f.bind(wrapper) : f
  ) as typeof wrapper
}

const DataAdapterTemplateState = () => {
  const state = useState(initialState)
  return useMemo(() => stateWrapper(state), [state])
}
const DataAdapterTemplateAccess = () => stateWrapper(initialState)
export { DataAdapterTemplateState as default, DataAdapterTemplateAccess }

function enhanceDataAdapterTemplates(templates) {
  return templates.map(({ dataAdapterTemplateFields, tags, ...template }) => {
    return {
      ...template,
      expectedFields: dataAdapterTemplateFields,
      tags: tags.map((tag) => ({
        color: tag.color,
        name: startCase(tag.name),
        id: `tag-${tag.name}-id`,
        standardTag: true
      }))
    }
  })
}
