import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import PropTypes from 'prop-types'

export default class Archive extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(color = '#8E8E8E') {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
        <svg width="18px" height="17px" viewBox="0 0 18 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-1320.000000, -133.000000)" fill={color} fillRule="nonzero">
              <g transform="translate(288.000000, 72.000000)">
                <g transform="translate(31.000000, 27.000000)">
                  <g transform="translate(960.000000, 33.000000)">
                    <path d="M44.5597522,4.81972898 C44.0266841,4.81972898 44.6858409,4.81972898 43.594547,4.67595263 L43.594547,15.7538996 C43.594547,16.3247694 44.0266841,16.6116992 44.5597522,16.6116992 L55.4402478,16.6116992 C55.9733159,16.6116992 56.405453,16.3247694 56.405453,15.7538996 L56.405453,4.67595263 C55.6223308,4.81972898 55.9733159,4.81972898 55.4402478,4.81972898 L44.5597522,4.81972898 Z M44.5597522,4.67595263 L55.4402478,4.67595263 C56.5548448,4.67595263 55.2888527,4.67595263 57.4584042,4.67595263 L57.4584042,15.9828579 C57.4584042,17.1764948 56.8646915,17.7393188 55.7500945,17.7393188 L44.2982167,17.7393188 C43.1836197,17.7393188 42.5415958,17.1764948 42.5415958,15.9828579 L42.5415958,4.67595263 C46.0105277,4.67595263 43.4451552,4.67595263 44.5597522,4.67595263 Z M47.9042757,7.88869456 L52.0957243,7.88869456 C52.3850834,7.88869456 52.6196553,8.16898779 52.6196553,8.51474709 C52.6196553,8.86050634 52.3850834,9.14079962 52.0957243,9.14079962 L47.9042757,9.14079962 C47.6149166,9.14079962 47.3803447,8.86050634 47.3803447,8.51474709 C47.3803447,8.16898779 47.6149166,7.88869456 47.9042757,7.88869456 Z M42.7142857,2.30435412 C42.3355762,2.30435412 42.0285714,2.62224249 42.0285714,3.01437767 L42.0285714,3.52197518 C42.0285714,3.91411036 42.3355762,4.23199873 42.7142857,4.23199873 L57.2857143,4.23199873 C57.6644238,4.23199873 57.9714286,3.91411036 57.9714286,3.52197518 L57.9714286,3.01437767 C57.9714286,2.62224249 57.6644238,2.30435412 57.2857143,2.30435412 L42.7142857,2.30435412 Z M42.4453953,1.2393188 L57.5631192,1.2393188 C58.509893,1.2393188 59,1.7491535 59,2.72949144 L59,3.80354394 C59,4.78388188 58.509893,5.29703405 57.5631192,5.29703405 L42.4453953,5.29703405 C41.4986215,5.29703405 41,4.78388188 41,3.80354394 L41,2.72949144 C41,1.7491535 41.4986215,1.2393188 42.4453953,1.2393188 Z"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        className={this.props.className}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
