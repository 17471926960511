import { useMemo } from 'react'
import { createState, useState } from '@hookstate/core'
import { find, get, keyBy, set, size, sortBy } from 'lodash'

import { AuthorizationAccess } from 'state/AuthorizationState'
import { NavigationAccess } from 'state/NavigationState'
import API from 'providers/API'

import { showForbiddenScreen } from 'components/ExceptionPages/Forbidden'
import { showServerError } from 'components/ExceptionPages/ServerError'
import { showUnauthorizedScreen } from 'components/ExceptionPages/Unauthorized'
import { mapValues, unproxy } from 'utils/helpers'
import { getErrorDetails } from 'utils/helpers'

import { DataAdapterAccess } from './DataAdapterState'

// hookState isn't synchronous so we'll point to a global obj for instant truth
const fetchState = {}

const initialState = createState({
  personStores: {},
  summaryStats: {},
  fetchState: {}
})

const stateWrapper = (state) => {
  const wrapper = {
    get fetchedPersonStores(): boolean {
      const status = state.fetchState.get() || {}
      return status.fetchedPersonStores || !!get(fetchState, `fetchedPersonStores`)
    },
    get failedToFetchPersonStores(): boolean {
      const status = state.fetchState.get() || {}
      return (
        status.failedToFetchPersonStores || !!get(fetchState, `failedToFetchPersonStores`)
      )
    },
    get fetchingPersonStores(): boolean {
      const status = state.fetchState.get() || {}
      return status.fetchingPersonStores || !!get(fetchState, `fetchingPersonStores`)
    },
    get personStoreIds(): string[] {
      return wrapper.personStores.map((ps: PersonStore) => ps.id)
    },
    get personStores(): PersonStore[] {
      return sortBy(
        Object.values<PersonStore>(state.personStores.get()).flatMap((ps) => ps),
        'name'
      )
    },
    findPersonStore({
      id: psId,
      ...predicates
    }: Partial<PersonStore>): PersonStore | undefined {
      const stores = wrapper.personStores

      return psId || size(predicates) === 0
        ? get(state.personStores.get(), `[${psId}]`)
        : find(Object.values(stores), predicates)
    },
    async fetchPersonStores() {
      if (wrapper.failedToFetchPersonStores) return
      set(fetchState, `fetchingPersonStores`, true)
      state.fetchState.set(fetchState)
      try {
        const res = await API.get('/ps/personStores')
        const filtered = res.data
          .filter(
            (ps: PersonStore) =>
              !ps.id.startsWith('api_test_') && !ps.id.startsWith('unittest_')
          )
          .map(enhancePersonStore)

        const personStores = keyBy<PersonStore>(filtered, 'id')
        state.personStores.set(personStores)
        set(fetchState, `fetchedPersonStores`, true)
        set(fetchState, `fetchingPersonStores`, false)
        state.fetchState.set(fetchState)
        return personStores
      } catch (e) {
        const { code: statusCode, detail } = getErrorDetails(e)
        set(fetchState, `failedToFetchPersonStores`, true)
        set(fetchState, `fetchingPersonStores`, false)
        state.fetchState.set(fetchState)

        // If the backend finds that the token used is expired, log the user out of the app
        if (
          statusCode === 401 &&
          (detail === 'Expired JWT' || detail.startsWith('Signed JWT rejected'))
        ) {
          return AuthorizationAccess().logout({ reason: 'expired' })
        }
        if (
          statusCode === 401 ||
          detail.includes('Bearer token does not contain a valid issuer')
        ) {
          showUnauthorizedScreen(e)
        } else if (statusCode === 403) {
          showForbiddenScreen(e)
        } else {
          showServerError(e)
        }
      }
    },
    async fetchPersonStore({ id, withCols = true }: { id: string; withCols?: boolean }) {
      if (!id) return Promise.reject('Person Store State: ID was not specified')
      try {
        const res = await API.get(`/ps/personStore/${id}?withCols=${withCols}`)
        const personStore = enhancePersonStore(res.data)
        state.personStores.merge({ [personStore.id]: personStore })
        return personStore
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async fetchSummaryStats<T = SummaryStat>({
      psId = NavigationAccess().psId
    }): Promise<Dictionary<T & { clExpression: string }>> {
      if (state.summaryStats[psId!].get()) {
        return unproxy(state.summaryStats[psId!].get())
      }

      const { data: stats }: { data: SummaryStats } = await API.get(
        `/ps/personStore/${psId}/summaryStats`
      )

      // Add admit stat manually
      const admitAdapter = await DataAdapterAccess()
        .fetchDataAdapter({
          psId,
          name: 'CL_Admit'
        })
        .catch(() => null) // Don't fail if CL_Admit is missing

      if (
        admitAdapter &&
        admitAdapter.fields?.some((field) => field.name === 'admitDate')
      ) {
        stats.admit = {
          agg: 'CountDistinct',
          dataAdapterName: 'CL_Admit',
          selectField: 'admitDate',
          summaryStatName: 'admit'
        }
      }

      const summaryStats = Object.keys(stats).reduce((newStats, statName) => {
        const stat = stats[statName]
        if (!stat) return newStats

        let clExpression = ''
        if ('clExpression' in stat) {
          clExpression = stat.clExpression!
        } else if ('dataAdapterName' in stat) {
          const select = stat.selectField ? `select=[${stat.selectField}]` : undefined
          const agg = stat.agg ? `agg=[${stat.agg}]` : undefined
          clExpression = `{${[stat.dataAdapterName, select, agg]
            .filter(Boolean)
            .join(', ')}}`
        }

        return {
          ...newStats,
          [statName]: { ...stat, clExpression }
        }
      }, {})

      state.summaryStats.merge({ [psId!]: summaryStats })

      return summaryStats
    }
  }
  return mapValues(wrapper, (f) =>
    typeof f === 'function' ? f.bind(wrapper) : f
  ) as typeof wrapper
}

const PersonStoreState = () => {
  const state = useState(initialState)
  return useMemo(() => stateWrapper(state), [state])
}
const PersonStoreAccess = () => stateWrapper(initialState)
export { PersonStoreState as default, PersonStoreAccess }

function enhancePersonStore(ps: PersonStore) {
  // Move entity id to root
  ps.primaryEntityId = get(ps.entities[ps.primaryEntityName], 'idFieldName')
  return ps
}
