const b64DecodeUnicode = (str: string = '') => {
  return decodeURIComponent(
    window.atob(str).replace(/(.)/g, function (m, p) {
      var code = p.charCodeAt(0).toString(16).toUpperCase()
      if (code.length < 2) {
        code = '0' + code
      }
      return '%' + code
    })
  )
}

const base64Decode = (str: string = ''): string => {
  var output = str.replace(/-/g, '+').replace(/_/g, '/')
  switch (output.length % 4) {
    case 0:
      break
    case 2:
      output += '=='
      break
    case 3:
      output += '='
      break
    default:
      throw new Error('Illegal base64url string!')
  }

  try {
    return b64DecodeUnicode(output)
  } catch (err) {
    return window.atob(output)
  }
}

export default base64Decode
