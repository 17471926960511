import React, { useMemo } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import { noop } from 'lodash'

export interface TrashProps extends IconProps {
  alt: string
  id?: string
  className?: string
  fill?: Color
  onClick?: () => void
}

const Trash: React.FC<TrashProps> = ({
  alt,
  id,
  fill = '#333333',
  className,
  onClick = noop
}) => {
  const markup = useMemo(
    () =>
      encodeURIComponent(
        renderToStaticMarkup(
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.94336 5.07178H16.4434"
              stroke={fill}
              strokeWidth="1.6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.69336 5.07178V3.57178C6.69336 3.17395 6.85139 2.79242 7.1327 2.51112C7.414 2.22981 7.79553 2.07178 8.19336 2.07178H11.1934C11.5912 2.07178 11.9727 2.22981 12.254 2.51112C12.5353 2.79242 12.6934 3.17395 12.6934 3.57178V5.07178M14.9434 5.07178V15.5718C14.9434 15.9696 14.7853 16.3511 14.504 16.6324C14.2227 16.9137 13.8412 17.0718 13.4434 17.0718H5.94336C5.54553 17.0718 5.164 16.9137 4.8827 16.6324C4.60139 16.3511 4.44336 15.9696 4.44336 15.5718V5.07178H14.9434Z"
              stroke={fill}
              strokeWidth="1.6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.19336 8.82178V13.3218"
              stroke={fill}
              strokeWidth="1.6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.1934 8.82178V13.3218"
              stroke={fill}
              strokeWidth="1.6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )
      ),
    [fill]
  )

  return (
    <img
      alt={alt}
      id={id}
      onClick={onClick}
      className={classnames('icon-trash', className)}
      src={`data:image/svg+xml,${markup}`}
    />
  )
}

Trash.displayName = 'Trash'
export default Trash
