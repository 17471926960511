import React, { useMemo } from 'react'
import mixpanel from 'utils/mixpanel'

import AuthorizationAccess from 'state/AuthorizationState'

import Button from 'components/Button'
import HeadlineLayout from 'components/Layout/HeadlineLayout'
import { getErrorDetails } from 'utils/helpers'

import { AppEnv } from 'v1/Constants'
import Vault from '../../graphics/Vault'
import ExceptionPageLayout from './components/ExceptionPageLayout'

const Forbidden = () => {
  const authorizationAccess = AuthorizationAccess()
  const match = document.location.search.match(/\?e=?(.+)/)
  const errorCode = match ? match[1] : ''
  const showLogout = ['86520', '86527', '86533'].includes(errorCode)

  mixpanel.trackPageViewed({
    page_name: 'Exception',
    exception_type: '403',
    exception_code: errorCode
  })

  const reason = useMemo(() => {
    switch (errorCode) {
      case '86527':
        return (
          <span>
            Your account is not part of an organization. If this is a mistake,{' '}
            <a
              href="https://closedloop.atlassian.net/servicedesk/customer/portal/3"
              target="blank"
            >
              contact
            </a>{' '}
            our team for assistance.
          </span>
        )
      case '86520':
        return (
          <span>
            Your account does not have permission to view this page,{' '}
            <a
              href="https://closedloop.atlassian.net/servicedesk/customer/portal/3"
              target="blank"
            >
              contact
            </a>{' '}
            our team for assistance.
          </span>
        )
      case '86533':
        return (
          <span>
            We're in the process of linking your accounts. Click the button below and sign
            in again.
          </span>
        )
      default:
        return 'Forbidden'
    }
  }, [errorCode])

  return (
    <ExceptionPageLayout className="forbidden-page">
      <HeadlineLayout
        title={403}
        subtitle={reason}
        content={
          <Button
            className="mt-16"
            type="primary"
            onClick={() => {
              if (showLogout) {
                authorizationAccess.logout({ reason: 'manual' })
              } else {
                sessionStorage.removeItem(AppEnv + '_refreshUrl')
                sessionStorage.removeItem(AppEnv + '_redirectAfterLoginUrl')

                window.location.assign('/')
              }
            }}
          >
            {showLogout ? 'Log Out' : 'Go Home'}
          </Button>
        }
        graphics={<Vault />}
      />
    </ExceptionPageLayout>
  )
}

const showForbiddenScreen = (error) => {
  const { pathname } = document.location

  if (pathname !== '/403') {
    var errorCode = (function (e) {
      const err = getErrorDetails(e)
      switch (true) {
        case err.detail.includes('is required to perform this action.'):
          return '86520'
        case err.detail.includes('is not assigned to an organization'):
          return '86527'
        case err.detail.includes('Auth0 is in the process of linking your accounts'):
          return '86533'
        default:
          return ''
      }
    })(error)

    let route = '/403'
    if (errorCode) route += '?e=' + errorCode
    document.location = route
  }
}

export { Forbidden as default, showForbiddenScreen }
