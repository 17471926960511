import React from 'react'
import { noop } from 'lodash'

import Button, { ButtonProps } from 'components/Button'
import Tooltip, { TooltipProps } from 'components/Tooltip'

import './ModalFooter.scss'

export type ActionProps = Omit<ButtonProps, 'children' | 'onClick'> & {
  tooltipProps?: Omit<TooltipProps, 'children'>
  text: string
  onClick?: (e?) => void
}
export interface ModalFooterProps {
  actions?: ActionProps[]
}

const ModalFooter: React.FC<ModalFooterProps> = ({ actions = [] }) => {
  return (
    <div className="modal_v3__footer">
      {actions.map(({ text, onClick, tooltipProps, ...props }, i) => {
        return (
          <Tooltip
            key={i}
            disabled={!tooltipProps}
            overlay={tooltipProps?.overlay}
            {...tooltipProps}
          >
            <Button key={`action-${text}-${i}`} {...props} onClick={onClick || noop}>
              {text}
            </Button>
          </Tooltip>
        )
      })}
    </div>
  )
}

ModalFooter.displayName = 'ModalFooter'
export default ModalFooter
