import React from 'react'

export default {
  tooltip: {
    downloadButtonUpsell:
      'Upgrade to ClosedLoop Professional or ClosedLoop Enterprise to download CSV files of patient lists.',
    exclusionCriteria: (
      <>
        Make selections here to exclude patients from the list based on their recent
        health history. The timeframe in which the criterion occurred are denoted by the
        (#M) following the criterion. For example: (12M) indicates patients are excluded
        if the criterion occurred in the past twelve months.
        <br />
        <br />
        To be considered as meeting the criteria and to be excluded, only diagnoses
        received within the indicated timeframe and coded as the primary diagnosis are
        considered. In addition, and for care in the outpatient setting, there must be at
        least two such diagnoses. For care in the inpatient setting, there must be at
        least one such diagnosis.
        <br />
        <br />
        The aim of this filter and criteria are to target active cases of the condition
        and eliminate one-time use of codes or provisional diagnoses that do not
        conclusively indicate the presence of the condition in question. Even with the
        filter applied, you may notice some patients on the list who have been diagnosed
        with the condition. This can occur if the diagnosis was in a non-primary position
        or instances with only one primary outpatient diagnosis.
      </>
    ),
    historicCost: (
      <span>
        Historic Per Member Per Month (PMPM) is calculated using program paid amounts,
        which are the payments made by CMS for Part A and Part B services after any
        patient cost-sharing contributions. This calculation is based on the current
        patient population in your organization's BCDA data feed from CMS.
      </span>
    ),
    annualEventCost: (
      <span>
        Annual costs are estimated using research based assumptions for cost per event and
        prevalence, given the total population size observed in your organization's data.
      </span>
    ),
    percentile: (
      <span>
        Patients' risk score <b>percentile</b> is determined based on the testing
        population for the predictive model. This ensures the patient's risk percentile is
        relative to a consistent baseline for Medicare patients, and only updates when
        there are changes in their individual health history. The higher the risk score,
        the more likely the event described in the Overview is to occur for this patient.
      </span>
    ),
    riskGroup: (
      <span>
        Patients are assigned to a <b>risk group</b> based on their percentile as follows:
        &gt;97 percentile are Very High Risk, 91-97 are High Risk, 71-90 are Elevated
        Risk, 41-70 are Normal Risk, and 0-40 are Low Risk.
      </span>
    ),
    topIncreasingFactors:
      'The top three factors that contribute to an increase in the prediction value. A wider red bar indicates a greater impact on the risk percentile.',
    exclusionCriteriaUpsell:
      'Upgrade to ClosedLoop Enterprise to apply exclusion criteria.'
  },
  inline: {
    networkErrorMessage: (
      <span>
        There was a problem loading the list of patients. Please check your network
        settings and try again.
      </span>
    ),
    filterErrorMessage: (
      <span>
        Filters are currently unavailable. Remove filters to view patient data. Please
        reach out to{' '}
        <a className="inline" href="mailto: support@closedloop.ai">
          ClosedLoop Support
        </a>{' '}
        for assistance
      </span>
    ),
    staleDataAlert: (
      <span>
        The last data update was more than ten days ago. Contact{' '}
        <a className="inline" href="mailto: support@closedloop.ai">
          ClosedLoop Support
        </a>{' '}
        for more information
      </span>
    )
  }
}
