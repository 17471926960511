import React, { MouseEventHandler, useMemo } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import { noop } from 'lodash'

export interface DownloadIconProps extends IconProps {
  alt: string
  id?: string
  className?: string
  fill?: Color
  onClick?: MouseEventHandler<HTMLImageElement>
}

const DownloadIcon: React.FC<DownloadIconProps> = ({
  alt,
  id,
  fill = '#232829',
  className,
  onClick = noop
}) => {
  const markup = useMemo(
    () =>
      encodeURIComponent(
        renderToStaticMarkup(
          <svg
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.00016 9L10.6668 6.33333M8.00016 9V1V9ZM8.00016 9L5.3335 6.33333L8.00016 9Z"
              stroke={fill}
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1.3335 10.334L1.7475 11.9907C1.8196 12.2791 1.98605 12.5352 2.22038 12.7182C2.45472 12.9012 2.7435 13.0006 3.04083 13.0007H12.9595C13.2568 13.0006 13.5456 12.9012 13.7799 12.7182C14.0143 12.5352 14.1807 12.2791 14.2528 11.9907L14.6668 10.334"
              stroke={fill}
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )
      ),
    [fill]
  )

  return (
    <img
      alt={alt}
      id={id}
      onClick={onClick}
      className={classnames('download-icon', className)}
      src={`data:image/svg+xml,${markup}`}
    />
  )
}

DownloadIcon.displayName = 'DownloadIcon'
export default DownloadIcon
