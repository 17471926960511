import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export default class OldDownloadIcon extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(fill = '#8E8E8E', strokeWidth = '0.3') {
    return encodeURIComponent(
      renderToStaticMarkup(
        <svg width="17" height="16" xmlns="http://www.w3.org/2000/svg">
          <defs />
          <path
            d="M16 13v2.8H1V13h1v1.7h13V13h1.2zm-2.3-4.4l-.7-.8-4 4V.5H8v11.1l-4-4-.8.9 5.3 5.2 5.2-5.2z"
            fill={fill}
            fillRule="nonzero"
            stroke={fill}
            strokeWidth={strokeWidth}
          />
        </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        id={this.props.id}
        className={classnames('download-icon', this.props.className)}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(
          this.props.fill,
          this.props.strokeWidth
        )}`}
      />
    )
  }
}
