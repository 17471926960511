const getElement = (selector) => {
  if (!selector) {
    return null
  }

  if (selector.nodeType) {
    return selector
  }

  const queried = document.querySelector(selector)
  if (queried) {
    return queried
  }

  const selectorType = selector.startsWith('#') ? 'id' : 'class'
  let element

  if (selectorType === 'class') {
    const elements = document.getElementsByClassName(selector.substr(1))
    if (elements.length) {
      element = elements[0]
    }
  } else {
    element = document.getElementById(selector.substr(1))
  }

  return element
}

export default getElement
