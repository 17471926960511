import ls from 'v1/utils/localStorage'

const getToggle = (obj) => {
  const bool = ls.getItem(obj.id)
  if (bool === 'true') obj.default = true
  else if (bool === 'false') obj.default = false
  return obj.default
}

const enableMedAdherenceCharts = getToggle({
  id: 'enableMedAdherenceCharts',
  default: false
})

const enableUncategorizedProcedureCharts = getToggle({
  id: 'enableUncategorizedProcedureCharts',
  default: false
})

const showErrorsInConsole = getToggle({
  id: 'showErrorsInConsole',
  default: false
})

export {
  enableMedAdherenceCharts,
  enableUncategorizedProcedureCharts,
  showErrorsInConsole
}
