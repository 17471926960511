/*
  Can be accessed at: 

  const locale = useLocale()
  locale.t('sankey.aria.<key>')
*/

export default {
  aria: {
    chartNode: ({ nodeName, nodeTotal, nodeCurrent, children }) => {
      let description = ''
      if (nodeTotal !== nodeCurrent)
        description += `The total for ${nodeName} is ${nodeTotal}. `
      if (nodeCurrent)
        description += `The current number of ${nodeName} is ${nodeCurrent}. `
      if (children?.length)
        description += children
          .map((child) => `${child.total} have transitioned to ${child.name}. `)
          .join('')
      return description
    }
  }
}
