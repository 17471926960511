import React from 'react'
import {
  AuthenticationMethodInputType,
  AuthenticationMethodType,
  ConnectorType,
  CustomerAuthenticationTestInfoType
} from 'types/enums'

import { Link } from 'providers/Router'

/*
  Can be accessed at: 

  const locale = useLocale()
  locale.t('connector.<key>')
*/

export default {
  tooltip: {
    about: (
      <span>
        A <b>connector</b> is used to provide authentication methods for viewing data from
        your source, importing data to the ClosedLoop platform, and exporting data to an
        external location.
      </span>
    ),
    closedLoopStaticS3UploadSnippet: (
      <span>
        Use this API endpoint to upload files directly to the ClosedLoop S3 bucket.
        {/* For more information on API Uploads, see the documentation{' '}
        <Link href="#" className="inline">
          here
        </Link>
        . */}
      </span>
    ),
    bcdaLastAttributionUpdate: (
      <span>
        Last Attribution Update is the last time that BCDA updated the beneficiary list of
        your organization, which includes updates from runout files. For more information
        please see the{' '}
        <a
          className="inline"
          href="https://bcda.cms.gov/guide.html"
          target="_blank"
          rel="noreferrer"
        >
          BCDA website
        </a>
        .
      </span>
    ),
    bcdaStatus: (
      <span>
        Active connectors will have their data refreshed every Tuesday at 5:00PM CT.
        Inactive connectors will not have their data refreshed automatically. Connectors
        are put in the 'Active' state when they have authentication methods present, and
        are placed in the 'Inactive' state if they do not have authentication
        methods&nbsp;saved.
      </span>
    ),
    bcdaUpdateType:
      'Incremental jobs append the most recent data onto the previously downloaded data. Full refresh jobs are a full replacement with all data available through the BCDA connection.'
  },
  message: {
    archive: {
      success: ({ connectorName, archiveTerm }) =>
        `${connectorName} has been ${archiveTerm}d.`,
      failure: {
        unauthorized: ({ archiveTerm }) =>
          `You are not authorized to ${archiveTerm} connectors.`,
        general: ({ connectorName, archiveTerm, errorDetail }) => (
          <>
            <p>{`${connectorName} could not be ${archiveTerm}d.`}</p>
            <p>{`Error: ${errorDetail}`}</p>
          </>
        )
      }
    }
  },
  inline: {
    basicInformation: {
      description: {
        [ConnectorType.ClosedLoopS3Connector]: (
          <span>
            ClosedLoop hosts an S3 bucket for storage of raw data files. You may upload
            data via the ClosedLoop API as demonstrated below or via authentication
            methods provisioned in the Authentication&nbsp;tab.
          </span>
        ),
        [ConnectorType.CustomerS3Connector]:
          'This connector gives the ClosedLoop platform access to an external S3 bucket.',
        [ConnectorType.CustomerBCDAConnector]:
          'ClosedLoop retrieves data through the BCDA connection every Tuesday at 5:00pm CT and automatically loads it to the platform. Rarely, CMS is delayed in updating the data available to BCDA. If you believe that may be the case, press the Reload All Data button to retrieve the data currently available from the BCDA connection.'
      }
    },
    authentication: {
      clConnectorMultipleAuthDescription: (
        <>
          The authentication methods provisioned here allow your team to connect to the
          ClosedLoop-hosted AWS bucket.
        </>
      ),
      createHelperText: {
        [AuthenticationMethodInputType.CustomerBCDACredentialInput]: (
          <span>
            Learn how to obtain BCDA credentials{' '}
            <a
              className="inline"
              rel="noreferrer"
              href="https://bcda.cms.gov/guide.html"
              target="_blank"
            >
              here
            </a>
            , through the{' '}
            <a
              className="inline"
              rel="noreferrer"
              href="https://acoms.cms.gov/"
              target="_blank"
            >
              ACO-MS portal
            </a>
            , or KCC and ACO REACH models can use the{' '}
            <a
              className="inline"
              rel="noreferrer"
              href="https://4innovation.cms.gov/"
              target="_blank"
            >
              4innovation portal
            </a>
            . Make sure to add ClosedLoop's IP address (34.202.99.23) to the approved
            list. This process will take BCDA up to 60 minutes.
          </span>
        ),
        [AuthenticationMethodInputType.ClosedLoopDelegatedIAMRoleInput]: (
          suggestedExternalId
        ) =>
          `To assume a role within the ClosedLoop AWS account to access the hosted S3 bucket, a ClosedLoop IAM Role will be set up to trust your AWS account and require a unique external ID of your choosing (We suggest something like ${suggestedExternalId}). `,
        [AuthenticationMethodInputType.CustomerAWSStaticCredentialInput]: (
          <>
            {`To allow the ClosedLoop platform to authenticate as a user within your organization's AWS account, you must provision static credentials specifically generated for the ClosedLoop platform. `}
            <Link
              style={{ display: 'inline' }}
              href="https://docs.aws.amazon.com/sdkref/latest/guide/access-iam-users.html"
            >
              See this resource for help.
            </Link>
            <br />
            <br />
            {`ClosedLoop must be given sufficient permissions to read/write within the S3 URI of the connector. Listed below are the minimum required permission settings. You may copy and paste them.`}
          </>
        ),
        [AuthenticationMethodInputType.CustomerDelegatedIAMRoleInput]: ({
          clAWSAccountId,
          suggestedExternalId
        }) => (
          <>
            {`To allow the ClosedLoop platform to assume a role within your organization's AWS account, you must create and configure this IAM role to trust the ClosedLoop AWS account (${clAWSAccountId}) and require a unique external ID (We suggest something like ${suggestedExternalId}). `}
            <Link
              style={{ display: 'inline' }}
              href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_roles_create_for-user.html"
            >
              See this resource for help.
            </Link>
            <br />
            <br />
            {`The role must be given sufficient permissions to read/write within the S3 URI of the connector. Below is the minimum permissions set required. You may copy and paste this.`}
          </>
        )
      }
    },
    confirmAuthMethodDelete: {
      [ConnectorType.ClosedLoopS3Connector]:
        'Please ensure that your organization is no longer using this authentication method before deleting it.',
      [ConnectorType.CustomerS3Connector]:
        "Please ensure your replacement authentication method works before deleting this one. Otherwise, the ClosedLoop platform will be unable to access your organization's S3 bucket.",
      [ConnectorType.CustomerBCDAConnector]:
        "Please ensure your replacement BCDA Credentials work before deleting this one. Otherwise, the ClosedLoop platform will be unable to access your organization's BCDA data."
    }
  }
}
