import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import PropTypes from 'prop-types'

export default class OldOvalInfo2 extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(color = '#0055FF') {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z" fill={color}/>
        <path d="M8 4.21C8.2 4.21 8.36 4.27167 8.48 4.395C8.60333 4.51833 8.665 4.66833 8.665 4.845C8.665 5.02833 8.60333 5.18333 8.48 5.31C8.36 5.43333 8.2 5.495 8 5.495C7.80333 5.495 7.64333 5.43333 7.52 5.31C7.4 5.18333 7.34 5.02833 7.34 4.845C7.34 4.66833 7.4 4.51833 7.52 4.395C7.64333 4.27167 7.80333 4.21 8 4.21ZM8.715 6.73V11.27H10.18V12H6.15V11.27H7.755V7.46H6.2V6.73H8.715Z" fill="white"/>
        <path d="M8.48 4.395L8.33664 4.53448L8.33858 4.53642L8.48 4.395ZM8.48 5.31L8.33671 5.17048L8.33666 5.17053L8.48 5.31ZM7.52 5.31L7.37476 5.4476L7.37858 5.45142L7.52 5.31ZM7.52 4.395L7.37857 4.25357L7.37665 4.25553L7.52 4.395ZM8.715 6.73H8.915V6.53H8.715V6.73ZM8.715 11.27H8.515V11.47H8.715V11.27ZM10.18 11.27H10.38V11.07H10.18V11.27ZM10.18 12V12.2H10.38V12H10.18ZM6.15 12H5.95V12.2H6.15V12ZM6.15 11.27V11.07H5.95V11.27H6.15ZM7.755 11.27V11.47H7.955V11.27H7.755ZM7.755 7.46H7.955V7.26H7.755V7.46ZM6.2 7.46H6V7.66H6.2V7.46ZM6.2 6.73V6.53H6V6.73H6.2ZM8 4.41C8.15552 4.41 8.26046 4.45616 8.33665 4.53447L8.62335 4.25553C8.45954 4.08717 8.24448 4.01 8 4.01V4.41ZM8.33858 4.53642C8.42387 4.62171 8.465 4.72061 8.465 4.845H8.865C8.865 4.61606 8.7828 4.41496 8.62142 4.25358L8.33858 4.53642ZM8.465 4.845C8.465 4.97836 8.42221 5.08266 8.33671 5.17048L8.62329 5.44952C8.78446 5.28401 8.865 5.07831 8.865 4.845H8.465ZM8.33666 5.17053C8.26046 5.24884 8.15552 5.295 8 5.295V5.695C8.24448 5.695 8.45954 5.61783 8.62335 5.44947L8.33666 5.17053ZM8 5.295C7.849 5.295 7.74233 5.24949 7.66142 5.16858L7.37858 5.45142C7.54433 5.61718 7.75767 5.695 8 5.695V5.295ZM7.66519 5.17245C7.58231 5.08496 7.54 4.98002 7.54 4.845H7.14C7.14 5.07664 7.21769 5.2817 7.37481 5.44755L7.66519 5.17245ZM7.54 4.845C7.54 4.71888 7.58074 4.61937 7.66335 4.53447L7.37665 4.25553C7.21926 4.41729 7.14 4.61779 7.14 4.845H7.54ZM7.66142 4.53642C7.74233 4.45551 7.849 4.41 8 4.41V4.01C7.75767 4.01 7.54433 4.08782 7.37858 4.25358L7.66142 4.53642ZM8.515 6.73V11.27H8.915V6.73H8.515ZM8.715 11.47H10.18V11.07H8.715V11.47ZM9.98 11.27V12H10.38V11.27H9.98ZM10.18 11.8H6.15V12.2H10.18V11.8ZM6.35 12V11.27H5.95V12H6.35ZM6.15 11.47H7.755V11.07H6.15V11.47ZM7.955 11.27V7.46H7.555V11.27H7.955ZM7.755 7.26H6.2V7.66H7.755V7.26ZM6.4 7.46V6.73H6V7.46H6.4ZM6.2 6.93H8.715V6.53H6.2V6.93Z" fill="white"/>
        </svg>
        
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        className={this.props.className}
        style={this.props.style}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
