import React from 'react'

import { Link } from 'providers/Router'

/*
  Can be accessed at: 

  const locale = useLocale()
  locale.t('deployment.<key>')
*/

export default {
  tooltip: {
    about: (
      <span>
        A <b>deployment</b> is an{' '}
        <Link
          href="https://apps.closedloop.ai/docs/api-tutorial/deploy-models.html#trial-staged-production-run-types"
          className="inline"
        >
          end-to-end process
        </Link>{' '}
        that allows for the automation of a workflow. A deployment can include a
        combination of{' '}
        <Link
          href="https://apps.closedloop.ai/docs/ml-ops/understand-deployments.html#deployment-components"
          className="inline"
        >
          triggers, predict and result steps
        </Link>
        .
      </span>
    ),
    resultDataAdapterSnapshot: (
      <span>
        The <b>data adapter snapshot</b> determines the data adapter, entity, and
        terminology versions used in saved query runs for this result step.
      </span>
    ),
    predictDataAdapterSnapshot: (
      <span>
        The <b>data adapter snapshot</b> determines the entity, data adapter, and
        terminology versions used in model runs for this predict step.
      </span>
    ),
    population: ({ type }) => (
      <span>
        The <b>population</b> used for this {type} step.
      </span>
    ),
    populationSelectionSteps: (
      <span>
        The <b>population selection steps</b> for the saved query used in this results
        step.
      </span>
    ),
    pythonResultsRetrieval: (
      <span>
        <b>Python code</b> to{' '}
        <Link
          href="https://apps.closedloop.ai/docs/ml-ops/work-w-deployments.html#use-api-retrieve-results-step"
          className="inline"
        >
          retrieve
        </Link>{' '}
        the most recent results produced by this result step.
      </span>
    ),
    curlResultsRetrieval: (
      <span>
        <b>cURL command</b> to{' '}
        <Link
          href="https://apps.closedloop.ai/docs/ml-ops/work-w-deployments.html#use-api-retrieve-results-step"
          className="inline"
        >
          retrieve
        </Link>{' '}
        the most recent results produced by this result step.
      </span>
    ),
    uploadEvents: (
      <span>
        <b>Upload events</b> indicate that data was uploaded to ClosedLoop. Some upload
        events may trigger deployment runs to start.
      </span>
    ),
    deploymentRuns: <span>Information for all historical runs of this deployment.</span>,
    results: (
      <span>
        The output generated by each <b>result</b> step in every deployment run, across
        all deployments.
      </span>
    ),
    resultsProduction: (
      <span>
        The output generated by each <b>result</b> step in every production deployment
        run, across all deployments.
      </span>
    ),
    resultsStaged: (
      <span>
        The output generated by each <b>result</b> step in every staged deployment run,
        across all deployments.
      </span>
    ),
    archiveDisabled:
      'This deployment has a version that is in production or staged and cannot be archived. Move all versions of this deployment to inactive to archive.',
    editDisabled: 'This deployment must be restored to be edited.',
    predictStepModelDisabled:
      'You must select a predictor, above, before you can choose a model.',
    performanceHistory: (
      <span>
        <b>Performance History</b> shows the accuracy of the deployed model over time. The
        yellow vertical lines represent when the deployment was updated to use a new
        model. The gray dotted line represents the accuracy on the test set for the
        deployed model, and the blue line is the live accuracy.
      </span>
    ),
    featureDrift: (
      <span>
        <b>Feature Drift</b> shows the drift for the most important features in the model.
        A high drift means the model should be reevaluated and/or retrained.
      </span>
    )
  },
  inline: {
    updatedRecordsManualRun:
      'This deployment is set to use updated records only. The "updated records only" setting does not apply to manually triggered runs.',
    updatedDataAdapterSnapshot: ({ resultStepName, snapshot, predictStepName }) => (
      <span>
        The data adapter snapshot for <b>{resultStepName}</b> has been updated to{' '}
        <b>{snapshot}</b> to align with the data adapter snapshot assigned to linked
        predict step <b>{predictStepName}</b>.
      </span>
    ),
    postProcessingFunctionLabel: 'Run a post processing function',
    postProcessingFunctionDisabledMessage:
      'The post processing function can be modified in the first result step.',
    lambdaFunctionLabel: 'Select a post processing function',
    lambdaFunctionDescription:
      'A post processing function is a pre-defined set of code that uses the output of these results steps.'
  },
  message: {
    archive: {
      success: ({ deploymentName, archiveTerm }) =>
        `${deploymentName} has been ${archiveTerm}d.`,
      failure: {
        unauthorized: ({ archiveTerm }) =>
          `You are not authorized to ${archiveTerm} deployments.`,
        general: ({ deploymentName, archiveTerm, errorDetail }) => (
          <>
            <p>{`${deploymentName} could not be ${archiveTerm}d.`}</p>
            <p>{`Error: ${errorDetail}`}</p>
          </>
        )
      }
    },
    deploy: {
      success: ({ deploymentName, destinationTerm }) =>
        `${deploymentName} was transitioned to ${destinationTerm} successfully.`,
      failure: {
        unauthorized: 'You are not authorized to deploy.',
        general: ({ deploymentName, destinationTerm, errorDetail }) => (
          <>
            <p>{`${deploymentName} could not be transitioned to ${destinationTerm}.`}</p>
            <p>{`Error: ${errorDetail}`}</p>
          </>
        )
      }
    },
    restore: {
      success: ({ deploymentName, oldVersion, newVersion }) =>
        `Version ${oldVersion.major}.${oldVersion.minor} of ${deploymentName} has been restored. It has been assigned a new version number, ${newVersion.major}.${newVersion.minor}.`,
      failure: {
        unauthorized: 'You are not authorized to restore.',
        general: ({ deploymentName, errorDetail }) => (
          <>
            <p>{`${deploymentName} could not be restored.`}</p>
            <p>{`Error: ${errorDetail}`}</p>
          </>
        )
      }
    }
  },
  modal: {
    restore: {
      title: ({ deploymentName, versionString }) =>
        `Do you want to restore ${deploymentName} v${versionString}?`,
      body: (
        <>
          <p>
            Restoring this deployment will create a new inactive version with the same
            information.
          </p>
          <p>
            This change will not impact staged or production deployment versions. They
            will continue to run until they are transitioned to inactive.
          </p>
        </>
      )
    },
    confirmQueryChange: {
      title: 'Are you sure you want to change the saved query used in this result step?',
      body: 'You may lose your parameter settings.',
      cancel: 'Continue using existing saved query',
      confirm: 'Update saved query'
    },
    confirmNoTriggers: {
      title: 'Are you sure you want to save without triggers?',
      body: 'If you do not specify any triggers, the deployment can only be triggered manually, on an ad hoc basis. For the deployment to execute, you must manually click “Run Deployment” on the deployment runs table each time you want it to run.',
      cancel: 'Add Triggers',
      confirm: 'Save without triggers'
    },
    archive: {
      body: 'This deployment cannot be transitioned to staged or production once archived.'
    },
    unarchive: {
      body: 'Once unarchived, this deployment will become an inactive deployment. Inactive deployments can then be transitioned to a staged or production state.'
    }
  },
  input: {
    transactionId: {
      description:
        'By default, this deployment will run using the full population for all steps. However, this deployment has one or more steps that limit the population to updated records only. To limit this deployment run to updated records from a recent data load only, specify the Data Upload Transaction Id below. You can find the Data Upload Transaction Id under details for an upload event.'
    }
  }
}
