import React from 'react'
import classnames from 'classnames'
import { noop } from 'lodash'
import mixpanel from 'utils/mixpanel'

import { Link } from 'providers/Router'

import Spinner from 'components/Spinner'
import { phiCheck } from 'utils/helpers'

import './Button.scss'

export interface ButtonProps {
  'aria-label'?: string
  children: React.ReactNode
  className?: string
  disabled?: boolean
  href?: string
  iconBefore?: React.ReactNode
  id?: string
  loading?: boolean
  onBlur?: () => void
  onClick?: (event: any) => void
  onFocus?: () => void
  onMouseDown?: (event: any) => void
  size?: 'default' | 'compact'
  style?: React.CSSProperties
  tabIndex?: number
  target?: '_blank' | '_self' | '_parent' | '_top'
  type?: 'default' | 'primary' | 'secondary' | 'danger' | 'link' | 'clear' | 'icon'
  trackClick?: boolean
  trackingData?: Pick<
    ITrackerInfo,
    'click_icon' | 'page_name' | 'click_text' | 'object_name'
  >
}

const Button: React.FC<ButtonProps> = React.forwardRef<any, ButtonProps>(
  (
    {
      children,
      className,
      disabled = false,
      href,
      iconBefore,
      id,
      loading = false,
      onBlur = noop,
      onClick = noop,
      onFocus = noop,
      onMouseDown = noop,
      size = 'default',
      style = {},
      tabIndex,
      target = '_self',
      type = 'default',
      'aria-label': ariaLabel,
      trackClick = true,
      trackingData
    },
    ref
  ) => {
    const isExternalLink = href && href.startsWith('http')
    const Component = isExternalLink ? 'a' : href ? Wrapper : 'button'
    const isInteractive = !disabled && !loading

    const iconStart = loading ? (
      <Spinner invert={['primary', 'danger'].includes(type)} size={16} inline />
    ) : (
      iconBefore
    )

    const handleOnClick = (e) => {
      // Stopping a click here instead of using pointer-events: none, for accessibility reasons.
      if (disabled) {
        // This is a design decision. I am thinking that most cases where the button is disabled, we would NOT want something getting clicked behind it.
        e.stopPropagation()
        return
      }
      // Track button clicks if there is not an href prop. Otherwise, we
      // track clicks inside Link.tsx instead
      if (!href && trackClick) {
        mixpanel.track('CTA Clicked', {
          click_type: 'Button',
          click_text:
            phiCheck.obscureData(e.currentTarget?.textContent) ||
            e.currentTarget?.getAttribute('aria-label'),
          ...trackingData
        })
      }
      onClick(e)
    }

    return (
      <Component
        id={id}
        className={classnames('button_v3', className, type, `size-${size}`, {
          disabled,
          loading
        })}
        onBlur={onBlur}
        onClick={handleOnClick}
        onFocus={onFocus}
        onMouseDown={onMouseDown}
        ref={ref}
        href={isInteractive ? href : undefined}
        tabIndex={disabled ? -1 : tabIndex}
        target={target}
        style={style}
        aria-label={ariaLabel}
      >
        {iconStart && <span className="button_v3__icon">{iconStart}</span>}
        {children && <span className="flex-center">{children}</span>}
      </Component>
    )
  }
)

Button.displayName = 'Button'
export default Button

function Wrapper({ href = '', children, ...props }) {
  return (
    <Link {...props} href={href}>
      {children}
    </Link>
  )
}
