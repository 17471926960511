import React, { useRef, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import mixpanel from 'utils/mixpanel'

import AuthorizationState from 'state/AuthorizationState'
import { setFeatureFlagUser } from 'state/FeatureFlagState'
import API from 'providers/API'
import { SetLocation } from 'providers/Router'

import { showForbiddenScreen } from 'components/ExceptionPages/Forbidden'
import { getErrorDetails, getRotatedCookie, print, setRotatedCookie } from 'utils/helpers'
import { useComponentWillMount } from 'utils/hooks'
import varna from 'utils/varna'

import { IdleTimeout } from 'v1/Constants'
import AuthClient from './auth0-2.0'
import { TWENTY_MINUTES } from './auth0-constants'
import { getRedirectUrl } from './auth0-helpers'
import AuthLoading from './AuthLoading'

const client = new AuthClient()

export default function NewAuthProvider({ children }) {
  const { setAuthService, setUser, user } = AuthorizationState()
  const usingCachedUser = useRef(false)
  const [loading, setLoading] = useState(true)
  const setLocation = SetLocation()

  const { pathname, href } = document.location
  const path = pathname.substring(1)
  const onErrorPage = !!path && !isNaN(Number(path))

  const setupApp = async (userObj) => {
    const { name, email } = userObj
    const permissions = userObj['https://closedloop.ai/permissions']
    const organization = userObj['https://closedloop.ai/organization']

    if (userObj.email !== user?.email || organization !== user?.organization) {
      // Set up mixpanel and varna for tracking
      API._init()
      mixpanel.identify(email)
      varna.setUser({ email })
      setFeatureFlagUser({
        key: email,
        name,
        email,
        organization,
        isAdmin: permissions.includes('cl:all:admin'),
        isOperator:
          permissions.includes('cl:all:admin') ||
          permissions.includes('cl:platform:operator')
      })
    }

    setUser(userObj)
    setLoading(false)
    setRotatedCookie('lastAction', String(Date.now()))
  }

  useComponentWillMount(() => {
    const user = client.getCachedUser()
    if (user) {
      usingCachedUser.current = true
      setupApp(user)
    }

    setAuthService({ logout: client.logout })

    if (onErrorPage) return

    client.onInit(async () => {
      try {
        const isAuthenticated = await client.isAuthenticated()

        if (path === 'logout') {
          await client.logout({ reason: 'manual' })
        } else if (isAuthenticated) {
          setRotatedCookie('lastAction', String(Date.now()))
          setupApp(await client.getUser())
          usingCachedUser.current = false
          const isHome = ['/', '/dashboard', '/login', '/logout'].includes(pathname)
          if (isHome && getRedirectUrl() !== href) return setLocation(getRedirectUrl())
        } else {
          const params = new URL(href).searchParams
          const state = params.get('state')
          const code = params.get('code')
          const error = params.get('error')
          const errorDescription = params.get('error_description')

          if (error) {
            showForbiddenScreen(new Error(error, { cause: new Error(errorDescription!) }))
          } else if (state && code) {
            // Auth0 forces the app to relaunch which causes the user to log in again. Cancel if the code was recently used.
            const exCode = sessionStorage.getItem('ex-code') ?? ''
            if (exCode === code.slice(-10)) {
              await new Promise((resolve) => setTimeout(resolve, 1000))
              return (window.location.href = getRedirectUrl())
            }
            sessionStorage.setItem('ex-code', code.slice(-10))

            const { redirectUrl } = await client.handleRedirect()
            await setupApp(await client.getUser())
            if (redirectUrl.includes('/docs/')) document.location.href = redirectUrl
            else setLocation(redirectUrl)
          } else {
            client.login()
          }
        }
      } catch (e) {
        const err = getErrorDetails(e)
        print('Auth init error', err)
        if (err.detail === 'Login required') client.login()
        else console.error('auth init failed', err)
      }
    })
  })

  const onIdle = () => {
    if (loading || !user?.email || usingCachedUser.current) return

    mixpanel.track('Authentication Event', {
      auth_event_type: 'Timeout'
    })
    setTimeout(() => {
      client.logout({ reason: 'idle' })
    }, Math.random() * 2000)
  }

  // If a user is activly using the platform and it's been 20 minutes
  // since their token has been updated, request a new token.
  // Tokens are short-lived at 2 hours so by requesting a new one, we
  // can keep the user logged in for as long as they are active.
  const onAction = () => {
    if (loading || !user?.email || usingCachedUser.current) return

    const lastActionStr = getRotatedCookie('lastAction')
    const lastAction = lastActionStr ? Number(lastActionStr) : undefined

    if (!lastAction) {
      setRotatedCookie('lastAction', String(Date.now()))
    } else if (Date.now() - lastAction > TWENTY_MINUTES) {
      setRotatedCookie('lastAction', String(Date.now()))
      client.getUser()
    }
  }

  useIdleTimer({
    onIdle,
    onAction,
    debounce: 1000,
    startOnMount: true,
    syncTimers: 200,
    timeout: IdleTimeout * 1000,
    crossTab: true
  })

  const showLoadingState = loading && !onErrorPage

  return (
    <>
      <AuthLoading loading={showLoadingState} />
      {!showLoadingState && children}
    </>
  )
}
