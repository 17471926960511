import { useEffect, useRef, useState } from 'react'
import { APP_PATHS } from 'Apps'
import { invert, isNil, omitBy } from 'lodash'
import mpb, { Config } from 'mixpanel-browser'
import { Mixpanel, RequestOptions } from 'mixpanel-browser'

import { NavigationAccess } from 'state/NavigationState'

import { phiCheck, print } from 'utils/helpers'
import { useForceUpdate } from 'utils/hooks'

import { AppUrl, BuildNumber, MixpanelToken } from 'v1/Constants'

const DEBUG = false

// If using locally, stub out mixpanel
const mp =
  DEBUG || !AppUrl.startsWith('http://localhost')
    ? mpb
    : ({
        init: () => null,
        identify: () => null,
        people: { set: () => null },
        track: async () => null
      } as unknown as Mixpanel)

const mixpanel = {
  init: () => {
    const config: Partial<Config> = {
      api_host: `${AppUrl}/mixpanel`,
      ignore_dnt: true
    }
    mp.init(MixpanelToken, config, '')
  },
  people: {
    set: (properties) => {
      if (mp.people?.set) mp.people.set(properties)
    }
  },
  identify: (uniqueId: string) => {
    if (mp.identify) mp.identify(uniqueId)
  },
  track: (
    eventName: string,
    properties: ITrackerInfo | IAuthInfo,
    options?: RequestOptions
  ) => {
    // Ad blocker will prevent mixpanel from initiating. The track method won't exist in this case.
    if (!mp.track) return

    const { app, psId } = NavigationAccess()

    const data = {
      page_name: getPageName(),
      ps_id: psId,
      app: invert(APP_PATHS)[app as string],
      $current_url: document.location.href,
      ...phiCheck.obscureData(properties || {}),
      date: new Date().toISOString(),
      client_build: BuildNumber,
      screen_pixel_ratio: window.devicePixelRatio,
      screen_width_actual: window.innerWidth,
      screen_height_actual: window.innerHeight
    }
    if (phiCheck.urlContainsPHI(window.location.href)) {
      data['$current_url'] = ''
    }
    if (phiCheck.urlContainsPHI(window.document.referrer)) {
      data['$referrer'] = ''
    }

    // When debugging, print what would be sent to mixpanel but escape before data is sent.
    if (DEBUG) return print('event', eventName, properties)

    return new Promise((resolve, reject) => {
      mp.track.call(
        mp,
        eventName,
        data,
        options ??
          ((success) => {
            success ? resolve(success) : reject()
          })
      )
    })
  },
  trackPageViewed: useTrackPageViewed,
  trackOnMount: useTrackOnMount,
  //To be used when arguments have async dependencies
  trackOnMountIf: useTrackOnMountIf
}

function getPageName() {
  const metaTag: HTMLMetaElement | null = document.querySelector('meta[name="page-key"]')
  return metaTag?.content as ITrackerInfo['page_name']
}

function useTrackPageViewed(
  properties?: ITrackerInfo | (() => ITrackerInfo) | (() => Promise<ITrackerInfo>)
) {
  const [trackId, restart] = useForceUpdate()
  const { app, psId } = NavigationAccess()
  const { current: startTime } = useRef(new Date().valueOf())
  const timeOnPage = useRef(0)
  const [data] = useState({
    properties,
    pageName: getPageName(),
    app,
    psId,
    currentUrl: document.location.href
  })

  const updateTimeOnPage = () => {
    timeOnPage.current = (new Date().valueOf() - startTime) / 1000
  }

  const updateEventData = (obj) => {
    for (let key in obj) {
      data[key] = obj[key]
    }
  }

  const sendToMixpanel = (options?: RequestOptions) => {
    if (timeOnPage.current) {
      ;(async () => {
        const eventProperties =
          typeof data.properties === 'function'
            ? await data.properties()
            : data.properties

        const pageProperties = {
          page_name: data.pageName,
          ps_id: psId,
          app: app && invert(APP_PATHS)[app],
          $current_url: data.currentUrl
        }

        mixpanel.track(
          'Page Viewed',
          {
            ...omitBy(pageProperties, isNil),
            ...(eventProperties ?? {}),
            time: startTime,
            time_on_page: Math.round(timeOnPage.current)
          },
          options
        )

        // Reset time on page once sent
        timeOnPage.current = 0
      })()
    }
  }

  // If the user leaves their page (refresh, close tab, logged out), the component won't unmount to fire page-viewed.
  // Let the browsers beforeUnload event handle sending the event to mixpanel.
  useEffect(() => {
    const onUnload = () => {
      updateTimeOnPage()
      updateEventData({
        app,
        psId,
        pageName: getPageName(),
        currentUrl: document.location.href
      })
      sendToMixpanel({ transport: 'sendBeacon' })
    }

    window.addEventListener('beforeunload', onUnload)
    return () => window.removeEventListener('beforeunload', onUnload)
  }, [])

  useEffect(() => {
    return updateTimeOnPage
  }, [trackId])

  useEffect(() => {
    // The new page may start loading before the cleanup function runs.
    // We'll pull the page details now so that the values reflect the page being viewed.
    updateEventData({ app, psId, properties, pageName: getPageName() })
    return () => sendToMixpanel()
  }, [properties, trackId])

  // restart allows you to stop the timer, send the tracking event, and start the timer again.
  // This is helpful on pages where the component does not unmount between url changes.
  return { restart }
}

function useTrackOnMount(eventName: string, properties: ITrackerInfo) {
  useEffect(() => {
    mixpanel.track(eventName, properties)
  }, [])
}

function useTrackOnMountIf(
  ifFn: () => boolean,
  eventName: string,
  properties: ITrackerInfo
) {
  const finished = useRef(false)

  if (!finished.current && ifFn()) {
    finished.current = true
    mixpanel.track(eventName, properties)
  }
}

export default mixpanel
