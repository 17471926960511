import React from 'react'
import classnames from 'classnames'
import { kebabCase } from 'lodash'

import './Pill.scss'

type pillColors =
  | 'lightBlue'
  | 'lightGray'
  | 'lightGreen'
  | 'lightYellow'
  | 'yellow'
  | 'purple'

export interface PillProps {
  color: pillColors
  label: React.ReactNode
  noMarginLeft?: boolean
}

const Pill: React.FC<PillProps> = ({ color, label, noMarginLeft = false }) => {
  return (
    <div
      className={classnames('pill_v3', kebabCase(color), {
        empty: !label,
        'no-margin-left': noMarginLeft
      })}
    >
      {label}
    </div>
  )
}

Pill.displayName = 'Pill'
export default Pill
