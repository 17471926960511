import React, { useCallback, useEffect, useRef, useState } from 'react'
import classnames from 'classnames'

import Button from 'components/Button'

import { AlertOptions } from '../Alert'
import AlertCombo from './AlertCombo'
import AlertSingle from './AlertSingle'
import './AlertPopup.scss'

export type AlertPopupType = 'success' | 'error' | 'info' | 'warning'

export interface AlertPopupProps {
  centered?: boolean
  className?: string
  closeable?: boolean
  combos?: AlertOptions['combos']
  message: React.ReactNode
  moreInfo?: AlertOptions['moreInfo']
  noMargin?: boolean
  style?: React.CSSProperties
  timeout?: AlertOptions['timeout']
  type: AlertPopupType
  onClose?: () => void
}

/**
 * @param combos Used to combine messages of different types. See the Alert 'Combo' story.
 */
const AlertPopup: React.FC<AlertPopupProps> = (props) => {
  const {
    type = 'success',
    centered,
    className,
    closeable = true,
    combos,
    timeout = 4000,
    message = '',
    moreInfo,
    noMargin = false,
    style,
    onClose
  } = props
  const [fadeOut, setFadeOut] = useState(false)
  const [hideAlert, setHideAlert] = useState(false)
  const elRef = useRef<HTMLDivElement>(null)
  const timer: any = useRef()

  const dismissAlert = useCallback(() => {
    if (onClose) onClose()
    else setHideAlert(true)
  }, [onClose])

  const startTimer = () => {
    timer.current = setTimeout(() => {
      setFadeOut(true)
    }, timeout)
  }

  useEffect(() => {
    if (timeout !== 0) startTimer()
    elRef.current?.focus()
  }, [])

  useEffect(() => {
    if (fadeOut) {
      clearTimeout(timer.current)
      timer.current = setTimeout(dismissAlert, 500)
    }
  }, [fadeOut, dismissAlert])

  if (hideAlert) return null

  return (
    <div
      tabIndex={0}
      ref={elRef}
      className={classnames('alert-popup', className, type, {
        'fade-out': fadeOut,
        'no-margin': noMargin
      })}
      style={style}
      onMouseEnter={() => clearTimeout(timer.current)}
      onMouseLeave={() => {
        if (timeout !== 0) startTimer()
      }}
    >
      {combos ? (
        <AlertCombo centered={centered} combos={combos} primaryType={type} />
      ) : (
        <AlertSingle
          centered={centered}
          message={message}
          moreInfo={moreInfo}
          type={type}
        />
      )}
      {closeable && (
        <Button
          className="alert-popup__close"
          onClick={dismissAlert}
          type="clear"
          aria-label="Close Alert"
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.29753 5.15364L10.3022 1.14892L9.15804 0.00471862L5.15332 4.00944L1.1486 0.00471862L0.00439637 1.14892L4.00911 5.15364L0.00439637 9.15836L1.1486 10.3026L5.15332 6.29785L9.15804 10.3026L10.3022 9.15836L6.29753 5.15364Z"
              fill={'#ccc'}
            />
          </svg>
        </Button>
      )}
    </div>
  )
}

export default AlertPopup
