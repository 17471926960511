import React, { AriaRole, useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { kebabCase } from 'lodash'

import { uid } from 'utils/helpers'

import './Portal.scss'

const getContainer: any = (id) => document.getElementById(id)

const updateContainer = (
  container,
  { className, style = {}, zIndex, role, tabIndex, onMouseEnter, onMouseLeave }
) => {
  container.setAttribute('class', 'portal-container')
  if (className) {
    container.classList.add(...className.split(' '))
  }
  const styleStr = Object.entries({
    opacity: container.style.opacity,
    ...style,
    zIndex
  })
    .map(([k, v]) => `${kebabCase(k)}:${v}`)
    .join('; ')
  container.setAttribute('style', styleStr)
  container.setAttribute('role', role)
  container.setAttribute('tabIndex', tabIndex)
  container.onMouseEnter = onMouseEnter
  container.onMouseLeave = onMouseLeave
  return container
}

const createContainer = (containerProps) => {
  const container = document.createElement('div')
  container.setAttribute('id', containerProps.id)
  updateContainer(container, containerProps)
  document.body.appendChild(container)
  return container
}

export interface PortalProps {
  children: React.ReactNode
  className?: string
  zIndex: number | string
  style?: React.CSSProperties
  id?: string
  tabIndex?: number
  role?: AriaRole
  onMouseEnter?: (e: MouseEvent) => void
  onMouseLeave?: (e: MouseEvent) => void
}

const Portal: React.FC<PortalProps> = React.forwardRef<any, PortalProps>(
  (
    {
      children,
      className,
      zIndex = 0,
      style,
      id: idProp,
      role,
      tabIndex,
      onMouseEnter,
      onMouseLeave
    },
    ref
  ) => {
    const [id] = useState(idProp ?? 'portal-' + uid(10))
    const [isMounted, setIsMounted] = useState(false)
    const [container, setContainer] = useState()

    const removeContainer = useCallback(() => {
      const currentContainer: any = container || getContainer(id)

      if (currentContainer) {
        currentContainer.remove()
      }
      setIsMounted(false)
    }, [container, id])

    useEffect(() => {
      const containerProps = {
        id,
        className,
        style,
        zIndex,
        role,
        tabIndex,
        onMouseEnter,
        onMouseLeave
      }

      const portalContainer = getContainer(id) || createContainer(containerProps)

      if (portalContainer !== container) {
        setContainer(portalContainer)
        if (typeof ref === 'function') ref(portalContainer)
      } else updateContainer(portalContainer, containerProps)
      // eslint-disable-next-line
    }, [id, zIndex, JSON.stringify(style), className, role, tabIndex])

    useEffect(() => {
      setIsMounted(true)
      return removeContainer
      // eslint-disable-next-line
    }, [])

    return isMounted && container ? ReactDOM.createPortal(children, container) : null
  }
)

Portal.displayName = 'Portal'
export default Portal
