/* stolen from fb */

// Let the document know when the mouse is being used
document.body.addEventListener('mousedown', function () {
  document.body.classList.add('using-mouse')
  document.body.classList.remove('using-keyboard')
})

// Re-enable focus styling when Tab is pressed
document.body.addEventListener('keydown', function (event) {
  if (event.keyCode === 9) {
    document.body.classList.remove('using-mouse')
    document.body.classList.add('using-keyboard')
  }
})
