class LocalStorage {
  memory = {}
  storage = window.localStorage

  isSupported() {
    try {
      const testKey = '__test_key__'
      this.storage.setItem(testKey, testKey)
      this.storage.removeItem(testKey)
      return true
    } catch (e) {
      return false
    }
  }

  clear() {
    if (this.isSupported()) {
      this.storage.clear()
    } else {
      this.memory = {}
    }
  }

  getItem(name) {
    if (this.isSupported()) {
      return this.storage.getItem(name)
    }
    if (name in this.memory) {
      return this.memory[name]
    }
    return null
  }

  key(index) {
    if (this.isSupported()) {
      return this.storage.key(index)
    } else {
      return Object.keys(this.memory)[index] || null
    }
  }

  removeItem(name) {
    if (this.isSupported()) {
      this.storage.removeItem(name)
    } else {
      delete this.memory[name]
    }
  }

  setItem(name, value) {
    if (this.isSupported()) {
      this.storage.setItem(name, value)
    } else {
      this.memory[name] = String(value)
    }
  }
}

export default new LocalStorage()
