import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export default class CloseImage extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(color = '#8E8E8E') {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
        <svg width="10px" height="10px" viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <defs>
            <path d="M5.76152344,4.99863281 L9.24511719,1.51777344 C9.45566406,1.30722656 9.45566406,0.966796875 9.24511719,0.757617187 C9.03457031,0.547070312 8.69414063,0.547070312 8.48496094,0.757617187 L5,4.23710937 L1.51503906,0.75625 C1.30449219,0.545703125 0.9640625,0.545703125 0.754882813,0.75625 C0.544335938,0.966796875 0.544335938,1.30722656 0.754882813,1.51640625 L4.23847656,4.99726562 L0.754882813,8.47949219 C0.544335938,8.69003906 0.544335938,9.03046875 0.754882813,9.23964844 C0.86015625,9.34492187 0.998242188,9.396875 1.13496094,9.396875 C1.27304688,9.396875 1.40976563,9.34492187 1.51503906,9.23964844 L5,5.75878906 L8.48496094,9.24101562 C8.59023438,9.34628906 8.72695313,9.39824219 8.86503906,9.39824219 C9.003125,9.39824219 9.13984375,9.34628906 9.24511719,9.24101562 C9.45566406,9.03046875 9.45566406,8.69003906 9.24511719,8.48085937 L5.76152344,4.99863281 Z" id="path-1"></path>
          </defs>
          <g id="App" stroke="none" strokeWidth="1" fill={color} fillRule="evenodd">
            <g id="Model---Delete-Modal" transform="translate(-861.000000, -245.000000)">
              <g id="Report-Modal">
                <g id="Modal" transform="translate(551.000000, 225.000000)">
                  <g id="Header" transform="translate(310.000000, 20.000000)">
                    <g id="Icon/Close">
                      <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-1"></use>
                      </mask>
                      <use id="Mask" fillOpacity="0.50" fill="#000000" fillRule="nonzero" xlinkHref="#path-1"></use>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        id={this.props.id}
        className={classnames('close-image', this.props.className)}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
