import React, { useEffect, useRef, useState } from 'react'
import ResizeObserver from 'react-resize-observer'
import classnames from 'classnames'
import { isEqual, keyBy, noop, size } from 'lodash'

import Button from 'components/Button'
import { unproxy } from 'utils/helpers'

import Tag, { TagProps } from './Tag'
import TagCreator from './TagCreator'
import './Tags.scss'

export interface TagsProps {
  className?: string
  /* Enable suggestions from previously defined tags */
  autoSuggest?: boolean
  description?: string
  editable?: boolean
  label?: string
  /* list of tags that should be shown first */
  promotedTags?: TagProps[]
  singleRow?: boolean
  abbreviated?: boolean
  noMargin?: boolean
  tags?: TagProps[]
  onChange?: React.Dispatch<React.SetStateAction<TagProps[] | undefined>>
  placeholder?: string
  noTagCreator?: boolean
  onMoreTagsClick?: (e: Event) => void
}

const Tags: React.FC<TagsProps> = ({
  className,
  autoSuggest = true,
  description,
  editable = false,
  label,
  singleRow = false,
  abbreviated = false,
  noMargin,
  promotedTags = [],
  tags = [],
  onChange = noop,
  placeholder = undefined,
  noTagCreator = false,
  onMoreTagsClick
}) => {
  const elementRef = useRef<HTMLDivElement>(null)
  const [visibleTagCount, setVisibleTagCount] = useState<number>()

  const promoted: TagProps[] = []
  const sortedTags: TagProps[] = []
  const promotedKeys = keyBy(promotedTags, 'id')
  const hiddenTagCount =
    visibleTagCount !== undefined && visibleTagCount < tags.length
      ? tags.length - visibleTagCount
      : undefined

  tags.forEach((t) => {
    const tagClone = { ...t }
    tagClone.closable = !editable ? t.closable : t.closable !== false
    if (promotedKeys[tagClone.id]) promoted.push(tagClone)
    else sortedTags.push(tagClone)
  })

  const tagList = [...promoted, ...sortedTags]
  const displayTagList =
    abbreviated && hiddenTagCount ? tagList.slice(0, visibleTagCount) : tagList

  const abbreviateTags = () => {
    if (elementRef.current && abbreviated) {
      const elements = elementRef.current?.querySelectorAll<HTMLDivElement>('.tag_v3')

      if (elements?.length) {
        let firstHiddenTagIndex

        let elementIndex = 0
        let rowIndex = 0
        let lastOffset
        let rowWidth = 0

        const container =
          elementRef.current?.querySelector<HTMLDivElement>('.tags_v3__container')

        while (rowIndex < 2 && elementIndex < elements.length) {
          const offset = elements[elementIndex].getBoundingClientRect().top

          if (lastOffset === undefined) lastOffset = offset

          if (offset > lastOffset) {
            rowWidth = elements[elementIndex].getBoundingClientRect().width + 20

            if (rowIndex === 1 && firstHiddenTagIndex === undefined) {
              firstHiddenTagIndex = elementIndex
            }

            rowIndex++
          } else {
            rowWidth += elements[elementIndex].getBoundingClientRect().width + 20 + 4
          }

          if (
            container &&
            rowIndex === 1 &&
            rowWidth > container.getBoundingClientRect().width - 80
          ) {
            if (firstHiddenTagIndex === undefined) {
              firstHiddenTagIndex = elementIndex - 1
            }
          }

          elementIndex++
          lastOffset = offset
        }

        if (firstHiddenTagIndex) {
          setVisibleTagCount(firstHiddenTagIndex + 1)
        } else {
          setVisibleTagCount(1000)
        }
      } else {
        setVisibleTagCount(0)
      }
    }
  }

  useEffect(() => {
    if (visibleTagCount === undefined) abbreviateTags()
  }, [elementRef.current])

  return (
    <div
      className={classnames(
        'tags_v3',
        {
          editable,
          'no-margin': noMargin,
          'single-row': singleRow,
          abbreviated
        },
        className
      )}
      ref={abbreviated ? elementRef : undefined}
    >
      {abbreviated && <ResizeObserver onResize={() => abbreviateTags()} />}
      {!!label && <label className="tags_v3__label">{label}</label>}
      {!!description && <div className="tags_v3__description">{description}</div>}
      <div
        className="tags_v3__container"
        style={{
          gridTemplateColumns: singleRow ? `repeat(${tagList.length}, auto)` : '',
          visibility: abbreviated && visibleTagCount === undefined ? 'hidden' : 'visible'
        }}
      >
        {size(tagList) === 0 && !editable && <span className="tags_v3__no-tags">-</span>}
        {displayTagList.map((tagProps) => {
          if (abbreviated) tagProps.maxWidth = '120px'

          return (
            <Tag
              {...tagProps}
              key={tagProps.id}
              onClose={() => onChange(tags.filter((t) => t.id !== tagProps.id))}
            />
          )
        })}
        {editable && (
          <TagCreator
            autoSuggest={autoSuggest}
            onCreate={(tag) => onChange([...tags, tag])}
            tags={tagList}
            disabled={noTagCreator}
            placeholder={placeholder}
          />
        )}
        {abbreviated && hiddenTagCount ? (
          onMoreTagsClick ? (
            <Button className="tags_v3__show-more" type="link" onClick={onMoreTagsClick}>
              +{hiddenTagCount} Tags
            </Button>
          ) : (
            <div className="tags_v3__show-more">+{hiddenTagCount} Tags</div>
          )
        ) : null}
      </div>
    </div>
  )
}

Tags.displayName = 'Tags'

export { Tag }
export default React.memo(Tags, (prevProps, nextProps) => {
  return (
    prevProps.className === nextProps.className &&
    prevProps.autoSuggest === nextProps.autoSuggest &&
    prevProps.label === nextProps.label &&
    prevProps.description === nextProps.description &&
    prevProps.placeholder === nextProps.placeholder &&
    prevProps.editable === nextProps.editable &&
    prevProps.abbreviated === nextProps.abbreviated &&
    prevProps.singleRow === nextProps.singleRow &&
    prevProps.noMargin === nextProps.noMargin &&
    isEqual(unproxy(prevProps.tags), unproxy(nextProps.tags))
  )
})
