import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export default class OvalCheckEnterprise extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(color = '#F7C31A') {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.6673 0.73999L13.0937 2.94719L16.3281 2.40239L17.1769 5.57119L20.1921 6.8624L19.1929 9.98639L21.0321 12.7024L18.5025 14.7904L18.5809 18.0696L15.3241 18.4584L13.6169 21.26L10.6673 19.8256L7.71694 21.26L6.00974 18.4584L2.75295 18.0696L2.83135 14.7904L0.301758 12.7024L2.14094 9.98639L1.14175 6.8624L4.15695 5.57119L5.00576 2.40239L8.24015 2.94719L10.6673 0.73999ZM10.6673 3.79441C6.68015 3.79441 3.44336 7.0312 3.44336 11.0176C3.44336 15.0048 6.68015 18.2416 10.6673 18.2416C14.6537 18.2416 17.8905 15.0048 17.8905 11.0176C17.8905 7.0312 14.6537 3.79441 10.6673 3.79441ZM10.6673 4.59441C14.2121 4.59441 17.0905 7.4728 17.0905 11.0176C17.0905 14.5632 14.2121 17.4416 10.6673 17.4416C7.12175 17.4416 4.24336 14.5632 4.24336 11.0176C4.24336 7.4728 7.12175 4.59441 10.6673 4.59441ZM6.02575 12.3544L8.63214 14.9616C8.94495 15.2736 9.45135 15.2736 9.76335 14.9616L15.3089 9.41598C15.6209 9.10398 15.6209 8.59678 15.3089 8.28478C14.9961 7.97278 14.4897 7.97278 14.1769 8.28478L9.19775 13.264L7.15695 11.2232C6.84415 10.9112 6.33775 10.9112 6.02575 11.2232C5.71295 11.5352 5.71295 12.0424 6.02575 12.3544Z" fill={color}/>
        </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        id={this.props.id}
        className={classnames('oval-check-icon', this.props.className)}
        style={this.props.style}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
