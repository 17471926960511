import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import PropTypes from 'prop-types'

export default class Star extends PureComponent {
  static defaultProps = {
    alt: 'star'
  }

  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(color = '#D6D6D6') {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
        <svg width="40px" height="38px" viewBox="0 0 40 38" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-732.000000, -472.000000)" fill={color} stroke={color} strokeWidth="2">
                  <g>
                      <polygon transform="translate(752.032329, 491.415672) rotate(-360.000000) translate(-752.032329, -491.415672) " points="752.075176 502.266184 741.807237 508.022808 743.767116 496.40728 734.928313 487.38327 746.88523 485.82836 752.075176 474.765689 757.219511 485.82836 769.136346 487.51872 760.383236 496.450127 762.213193 508.065655"></polygon>
                  </g>
              </g>
          </g>
        </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        id={this.props.id}
        className={this.props.className}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
