import React, { lazy, Suspense } from 'react'
import { find } from 'lodash'
import { Redirect, Route, Switch } from 'wouter'

import AuthorizationState from 'state/AuthorizationState'
import PersonStoreState from 'state/PersonStoreState'
import UserPreferencesState from 'state/UserPreferencesState'

import Spinner from 'components/Spinner'
import { unproxy } from 'utils/helpers'

import FeedbackForm from 'v1/components/FeedbackForm'
import 'styles/index.scss'
import 'styles/accessibility'

const DataSciencePlatform = lazy(() => import('./DataSciencePlatform'))
const PatientHealthForecast = lazy(() => import('./PatientHealthForecast'))
const SelfServiceUserManagement = lazy(() => import('./SelfServiceUserManagement'))
const Evaluate = lazy(() => import('./Evaluate'))
const ACOSolutionBundle = lazy(() => import('./ACOSolutionBundle'))
const ProviderCostDashboard = lazy(() => import('./ProviderCostDashboard'))
const TeamDashboard = lazy(() => import('./TeamDashboard'))

const Unauthorized = lazy(() => import('components/ExceptionPages/Unauthorized'))
const Forbidden = lazy(() => import('components/ExceptionPages/Forbidden'))
const ServerError = lazy(() => import('components/ExceptionPages/ServerError'))
const NotFound = lazy(() => import('components/ExceptionPages/NotFound'))

const Wrapper = ({ component: Comp }) => (
  <Suspense fallback={<div />}>
    <Comp />
  </Suspense>
)

export const APP_PATHS: { [key in Application]: string } = {
  platform: 'platform',
  phf: 'forecasts',
  ssum: 'user-management',
  cost_dashboard: 'cost-dashboard',
  acosb: 'aco-predict',
  eval: 'evaluate',
  team: 'team-dashboard'
}

export const APP_NAMES: { [key in Application]: string } = {
  platform: 'Data Science Platform',
  phf: 'Patient Health Forecast',
  ssum: 'User Management',
  team: 'Team Dashboard',
  acosb: 'ACO-Predict',
  eval: 'Evaluate',
  cost_dashboard: 'Cost Dashboard'
}

const primaryPersonStores = ['tutorial_2021', 'tutorial_2023', 'ui_sandbox']

const Apps = () => {
  const authorizationState = AuthorizationState()
  const personStoreState = PersonStoreState()
  const { getPreference } = UserPreferencesState()
  const { personStores, findPersonStore, fetchPersonStores } = personStoreState
  const { fetchedPersonStores, fetchingPersonStores, failedToFetchPersonStores } = personStoreState // prettier-ignore
  const isLoggedIn = !!authorizationState.user?.email
  const { pathname } = window.location

  // Fetch person stores for user
  if (
    isLoggedIn &&
    !fetchedPersonStores &&
    !fetchingPersonStores &&
    !failedToFetchPersonStores
  ) {
    fetchPersonStores()
  }

  // Prevent api routes from reaching react-router
  // Most likely not needed after src/v1 is removed
  if (pathname.startsWith('/api/v1')) return <div />

  // Prevent docs from reaching switch.
  if (pathname.startsWith('/docs')) return <div />

  // Check that the user has access to each app
  const appAccess = Object.keys(APP_PATHS).reduce<{ [key: string]: boolean }>(
    (results, appName) => ({
      ...results,
      [appName]: authorizationState.hasPermission(`ext:${appName}:basic_read` as any)
    }),
    {}
  )
  // Check if user has access to any apps. Show 401 if true
  const noAccess = !Object.values(appAccess).some((b) => b)

  // Full screen exceptions
  if (pathname.startsWith('/403')) return <Wrapper component={Forbidden} />
  if (pathname.startsWith('/404')) return <Wrapper component={NotFound} />
  if (pathname.startsWith('/500')) return <Wrapper component={ServerError} />
  if (pathname.startsWith('/401') || (noAccess && isLoggedIn)) {
    return <Wrapper component={Unauthorized} />
  }

  // Wait until user has logged in before trying to route them
  if (!isLoggedIn || pathname === '/login') return <div className="page-content" />

  // If no recent path or user does not have access, route them to the primary person store
  const RouteMe = (appOverride?: string) => {
    const personStoreToLoad =
      getPreference('selectedPersonStore') ||
      find(primaryPersonStores, (id) => findPersonStore({ id })) ||
      unproxy(
        findPersonStore({ organization: authorizationState.user.organization }) ||
          personStores[0]
      )?.id

    const routes = {
      platform: `/platform/${personStoreToLoad}/automl/predictors/`,
      phf: `/forecasts/${personStoreToLoad}/`,
      ssum: `/user-management/${personStoreToLoad}/`,
      eval: `/evaluate/${personStoreToLoad}/`,
      acosb: `/aco-predict/${personStoreToLoad}/`,
      team: `/team-dashboard/${personStoreToLoad}/monitor/queue-messages`
    }

    let toRoute =
      routes[appOverride ?? ''] ??
      (appAccess.platform
        ? routes.platform
        : appAccess.phf
        ? routes.phf
        : appAccess.ssum
        ? routes.ssum
        : appAccess.eval
        ? routes.eval
        : appAccess.acosb
        ? routes.acosb
        : appAccess.team
        ? routes.team
        : '/403')

    if (pathname === '/dashboard') {
      // Get most recent path visited
      const recentPath = ''

      // Make sure user has access to that person store and to the app
      const recentApp = recentPath.match(/^\/(\w+)\//)?.[1]
      const recentPsId = recentPath.match(/^\/\w+\/(\w+)\//)?.[1]

      // If they have access, route the user to that location instead of the default route
      if (
        recentApp &&
        Object.values(APP_PATHS).includes(recentApp) &&
        findPersonStore({ id: recentPsId })
      ) {
        toRoute = recentPath
      }
    }

    return <Redirect replace={true} to={toRoute} />
  }

  return (
    <div id="app">
      <FeedbackForm screen="feedback" />
      <Suspense fallback={<div />}>
        <Switch>
          <Route path="/">{fetchedPersonStores ? RouteMe() : Spinner}</Route>
          <Route path="/dashboard">{fetchedPersonStores ? RouteMe() : Spinner}</Route>
          <Route path="/404" component={NotFound} />

          {appAccess.platform && (
            <Route path="/platform/:psId*" component={DataSciencePlatform} />
          )}
          {appAccess.phf && (
            <>
              <Route path="/forecasts/">
                {fetchedPersonStores ? RouteMe('phf') : <Spinner />}
              </Route>
              <Route path="/forecasts/:psId*" component={PatientHealthForecast} />
            </>
          )}
          {appAccess.ssum && (
            <>
              <Route path="/user-management/">
                {fetchedPersonStores ? () => RouteMe('ssum') : Spinner}
              </Route>
              <Route
                path="/user-management/:psId*"
                component={SelfServiceUserManagement}
              />
            </>
          )}
          {appAccess.eval && (
            <>
              <Route path="/evaluate/">
                {fetchedPersonStores ? () => RouteMe('eval') : Spinner}
              </Route>
              <Route path="/evaluate/:psId*" component={Evaluate} />
            </>
          )}
          {appAccess.acosb && (
            <>
              <Route path="/aco-predict/">
                {fetchedPersonStores ? () => RouteMe('aco-predict') : Spinner}
              </Route>
              <Route path="/aco-predict/:psId*" component={ACOSolutionBundle} />
            </>
          )}
          {appAccess.cost_dashboard && (
            <>
              <Route path="/cost-dashboard/">
                {fetchedPersonStores ? () => RouteMe('cost-dashboard') : Spinner}
              </Route>
              <Route path="/cost-dashboard/:psId*" component={ProviderCostDashboard} />
            </>
          )}
          {appAccess.team && (
            <>
              <Route path="/team-dashboard/">
                {fetchedPersonStores ? () => RouteMe('team') : Spinner}
              </Route>
              <Route path="/team-dashboard/:psId*" component={TeamDashboard} />
            </>
          )}

          {/* 
            Catch all 
            If it is an old platform route, append /platform/ to the start of the url
            If not, show 404 */}
          <Route>
            {() => {
              const swimLanes = ['data-sources', 'data-prep', 'automl', 'ml-ops']
              const isPlatform = swimLanes.some((lane) => pathname.includes(`/${lane}/`))

              return (
                <Redirect
                  replace={true}
                  to={
                    isPlatform && !pathname.startsWith('/platform')
                      ? `/platform${pathname}`
                      : '/404'
                  }
                />
              )
            }}
          </Route>
        </Switch>
      </Suspense>
    </div>
  )
}

export default Apps
