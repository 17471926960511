import React, { useMemo } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

export interface CheckmarkIconProps {
  alt: string
  className?: string
  fill?: string
}

const CheckmarkIcon: React.FC<CheckmarkIconProps> = ({
  alt,
  className,
  fill = '#fff'
}) => {
  // prettier-ignore
  const staticImg = useMemo(() =>  
    encodeURIComponent(
      renderToStaticMarkup(
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="13" height="13" rx="1"/>
          <path d="M9.83281 3L6.16719 7.83281L4 5.66719L3 6.66719L6.33281 10L11 4L9.83281 3Z" fill={fill}/>
        </svg>
      )
    )
  , [fill])

  return <img alt={alt} className={className} src={`data:image/svg+xml,${staticImg}`} />
}

CheckmarkIcon.displayName = 'CheckmarkIcon'
export default CheckmarkIcon
