import acoPredict from './acoPredict'
import common from './common'
import components from './components'
import connector from './connector'
import dataAdapter from './dataAdapter'
import dataLoad from './dataLoad'
import deployment from './deployment'
import feature from './feature'
import model from './model'
import modelRun from './modelRun'
import modelValidation from './modelValidation'
import patientHealthForecast from './patientHealthForecast'
import personStore from './personStore'
import population from './population'
import populationStep from './populationStep'
import prediction from './prediction'
import predictor from './predictor'
import program from './program'
import providerCostDashboard from './providerCostDashboard'
import savedQuery from './savedQuery'
import terminology from './terminology'
import userManagement from './userManagement'

export default {
  acoPredict,
  common,
  connector,
  personStore,
  dataAdapter,
  dataLoad,
  deployment,
  feature,
  population,
  populationStep,
  predictor,
  program,
  model,
  modelValidation,
  modelRun,
  prediction,
  providerCostDashboard,
  patientHealthForecast,
  savedQuery,
  terminology,
  userManagement,
  components
}
