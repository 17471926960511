import { AxiosError } from 'axios'
import { get } from 'lodash'

import varna from 'utils/varna'

export interface ErrorDetails {
  code: number
  detail: string
  message: string
}

const getErrorDetails = (
  err: Error | AxiosError | ErrorDetails | unknown
): ErrorDetails => {
  // If an error has already been converted to ErrorDetails, don't convert it again.
  if (err && typeof err === 'object' && 'detail' in err) return err as ErrorDetails
  if (err && typeof err === 'string')
    return { code: 400, detail: err, message: err } as ErrorDetails

  const code: number = get(err, 'response.status', 0)
  const message: string =
    get(err, 'response.data.name') ||
    get(err, 'response.data') ||
    get(err, 'response.statusText') ||
    get(err, 'message', '')
  const detail: string = get(err, 'response.data.detail', '') || message

  if (detail.startsWith('JsResultException')) {
    varna.error('Bad API Object', err as Error)

    return {
      code,
      detail: 'An internal error has occurred. Please try again later.',
      message: 'An internal error has occurred. Please try again later.'
    }
  }

  if (code >= 500) {
    return {
      code,
      detail: 'A system error has occurred. Please try again later.',
      message: 'A system error has occurred. Please try again later.'
    }
  }

  return {
    code,
    detail,
    message
  }
}

export default getErrorDetails
