const resolveType = (str): any => {
  return !isNaN(str)
    ? Number(str) === Infinity
      ? str
      : Number(str)
    : str.toLowerCase() === 'true'
    ? true
    : str.toLowerCase() === 'false'
    ? false
    : str
}

export default resolveType
