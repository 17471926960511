import React, { useEffect, useRef } from 'react'
import { createState, useState } from '@hookstate/core'

import Modal from 'components/Modal'

import SetLocation from './SetLocation'

type PromptChecker = (
  nextLocation: string,
  action: 'pop' | 'push' | 'replace',
  data?: object,
  params?: object
) => boolean

const promptState = createState(false)
let shouldPreventNavigation: PromptChecker = () => false

// Callback for other services to check if they need to cancel navigation
export function canNavigate({ path: nextLocation, eventType, data, params }) {
  const action = eventType.toLowerCase().replace('state', '')
  const preventNavigation = shouldPreventNavigation(nextLocation, action, data, params)

  if (preventNavigation) {
    promptState.set(true)
  }

  return !preventNavigation
}

export interface NavigationPromptProps {
  when?: PromptChecker
  message: string
}

// Prompt Element to be added to any page to prevent navigation
// Modeled after React Router Prompt
const NavigationPrompt: React.FC<NavigationPromptProps> = ({
  when = () => true,
  message
}) => {
  useEffect(() => {
    return () => {
      shouldPreventNavigation = () => false
    }
  }, [])

  const setLocation = SetLocation()
  const navHistory = useRef({
    path: document.location.pathname,
    replace: false,
    data: {},
    params: {}
  })

  const state = useState(promptState)
  const showPrompt = state.get()

  // Link when prop to global variable for use in callback
  shouldPreventNavigation = (nextLocation, action, data = {}, params = {}) => {
    // Keep track of where the user was headed. If they click continue,
    // we need to fire the navigation for them.
    navHistory.current = {
      path: nextLocation,
      replace: action === 'replace',
      data,
      params
    }

    return when(nextLocation, action, data, params)
  }

  if (!showPrompt) return null

  return (
    <Modal
      title="Are you sure you want to leave this page?"
      visible
      autoFocus
      closeOnMaskClick
      scrollBehavior="outside"
      onClose={() => state.set(false)}
      actions={[
        {
          text: 'Stay on Page',
          type: 'link',
          onClick: (e) => state.set(false)
        },
        {
          text: 'Leave Page',
          type: 'primary',
          onClick: () => {
            const { path, replace, data, params } = navHistory.current
            shouldPreventNavigation = () => false
            setLocation(path, { replace, data, params })
            state.set(false)
          }
        }
      ]}
    >
      {message}
    </Modal>
  )
}

export default NavigationPrompt
