import { useMemo } from 'react'
import { createState, useState } from '@hookstate/core'
import { takeRight } from 'lodash'

import { setFeatureFlagPersonStore } from 'state/FeatureFlagState'
import { saveCurrentUrl } from 'providers/AuthProvider/auth0-helpers'

import { resolveType, unproxy } from 'utils/helpers'

let currentPath = ''
let currentSearch = ''
let currentHash = ''
let additionalData = {}

export interface INavigationState {
  adapterGroup: undefined | string
  adapterId: undefined | string
  app:
    | undefined
    | 'platform'
    | 'forecasts'
    | 'user-management'
    | 'team-dashboard'
    | 'aco-predict'
    | 'cost-dashboard'
  codeId: undefined | string
  codeSystemId: undefined | string
  connectorId?: string
  configurationId?: string
  dataDictionaryTableId: undefined | string
  deploymentId: undefined | string
  dfQueryId: undefined | string
  entityId: undefined | string
  entityName: undefined | string
  factorId: undefined | string
  factorName: undefined | string
  featureGroup: undefined | string
  featureId: undefined | string
  forecastId: undefined | string
  hash: undefined | string
  history: string[]
  messageId: undefined | string
  modelId: undefined | string
  page: undefined | string
  params: Dictionary<any>
  patientListName: undefined | string
  personId: undefined | string
  phfType: undefined | 'trial' | 'authorized'
  populationId: undefined | string
  predictorId: undefined | string
  predictorTab: undefined | string
  primaryNav: undefined | string
  programId: undefined | string
  programTab: undefined | 'monitor' | 'impact'
  psId: undefined | string
  queryRunId: undefined | string
  reportDefinitionId: undefined | string
  reportId: undefined | string
  resultsTab?: DeploymentRunType
  runId: undefined | string
  savedQueryId: undefined | string
  secondaryNav: undefined | string
  table: undefined | string
  transactionId: undefined | string
  userId: undefined | string
}

const newBlankStateObj = () => ({
  adapterGroup: undefined,
  adapterId: undefined,
  app: undefined,
  codeId: undefined,
  codeSystemId: undefined,
  connector: undefined,
  configurationId: undefined,
  dataDictionaryTableId: undefined,
  deploymentId: undefined,
  dfQueryId: undefined,
  entityId: undefined,
  entityName: undefined, // Name of entity type, such as 'memberMonths'
  factorId: undefined, // A unique path for factor of specific predictor and model
  factorName: undefined, // Name of factor
  featureGroup: undefined,
  featureId: undefined,
  forecastId: undefined,
  hash: undefined,
  history: [],
  messageId: undefined,
  modelId: undefined,
  page: undefined,
  params: {},
  patientListName: undefined,
  personId: undefined,
  phfType: undefined,
  populationId: undefined,
  predictorId: undefined,
  predictorTab: undefined,
  primaryNav: undefined,
  programId: undefined,
  programTab: undefined,
  psId: undefined,
  queryRunId: undefined,
  reportDefinitionId: undefined,
  reportId: undefined,
  runId: undefined,
  savedQueryId: undefined,
  secondaryNav: undefined,
  transactionId: undefined,
  table: undefined,
  userId: undefined
})

const initialState = createState({ ...newBlankStateObj() })

const stateWrapper = (state): INavigationState =>
  unproxy(
    Object.keys(initialState).reduce(
      (a, b) => ({ ...a, [b]: state[b].get() }),
      initialState
    )
  )

const NavigationState = () => {
  const state = useState(initialState)
  return useMemo(() => stateWrapper(state), [state])
}
const NavigationAccess = () => stateWrapper(initialState)
export { NavigationState as default, NavigationAccess, onNavUpdate, injectNavigationData }

function injectNavigationData(data = {}) {
  additionalData = data
}

function onNavUpdate() {
  // Only update values if pathname has changed
  if (
    currentPath !== window.location.pathname ||
    currentSearch !== window.location.search ||
    currentHash !== window.location.hash
  ) {
    currentPath = window.location.pathname
    currentSearch = window.location.search
    currentHash = window.location.hash

    const state: INavigationState = {
      ...newBlankStateObj(),
      history: [
        ...takeRight(initialState.history.get(), 9),
        `${currentPath}${currentSearch}`
      ],
      ...additionalData
    }

    const searchStr = currentSearch.substring(1)
    state.params = searchStr
      .split('&')
      .filter((x) => x !== '')
      .map((str) => str.split('='))
      .reduce((a, b) => ({ ...a, [b[0]]: resolveType(decodeURIComponent(b[1])) }), {})
    const paths = currentPath.split('/').filter((x) => x !== '')
    let nextValue = () => paths.shift()
    // App Name
    const app = nextValue() as INavigationState['app']
    state.app = app

    // Person Store
    state.psId = nextValue()

    // Primary Nav
    const primary = nextValue()
    if (app === 'forecasts') state.page = primary
    if (primary === 'dashboard') state.page = primary
    else state.primaryNav = primary

    // Secondary Nav
    const secondary = nextValue()
    if (app === 'forecasts') state.phfType = secondary as INavigationState['phfType']
    if (primary === 'person') state.personId = secondary
    if (secondary === 'invite') state.page = secondary
    else if (primary === 'users') state.userId = secondary
    else if (primary === 'reports') state.reportId = secondary
    else if (primary === 'patient-lists')
      state.patientListName = secondary ? decodeURIComponent(secondary) : undefined
    else if (primary === 'programs') state.programId = secondary
    else if (secondary === 'create') state.page = secondary
    else if (secondary === 'edit') state.page = secondary
    else state.secondaryNav = secondary

    // Tertiary Nav
    const tertiary = nextValue()
    if (tertiary === 'create') state.page = tertiary
    else if (tertiary === 'edit') state.page = tertiary
    else if (tertiary === 'configure') state.page = tertiary
    else if (app === 'forecasts') state.forecastId = tertiary
    else if (tertiary === 'group-settings') state.page = tertiary
    else if (secondary === 'entities')
      state.entityName = tertiary ? decodeURI(tertiary) : tertiary
    else if (secondary === 'adapters') state.adapterId = tertiary
    else if (secondary === 'features') state.featureId = tertiary
    else if (secondary === 'queue-messages') state.messageId = tertiary
    else if (secondary === 'populations') state.populationId = tertiary
    else if (secondary === 'queries') state.page = tertiary
    else if (secondary === 'predictors') state.predictorId = tertiary
    else if (secondary === 'report-definitions') state.reportDefinitionId = tertiary
    else if (secondary === 'monitoring') state.page = tertiary
    else if (secondary === 'deployments') state.deploymentId = tertiary
    else if (secondary === 'live-performance') state.predictorId = tertiary
    else if (secondary === 'legacy-live-performance') state.predictorId = tertiary
    else if (secondary === 'data-dictionary') state.dataDictionaryTableId = tertiary
    else if (secondary === 'connectors') state.connectorId = tertiary
    else if (secondary === 'programs') state.programId = tertiary
    else if (secondary === 'results')
      state.resultsTab = tertiary as INavigationState['resultsTab']

    // Selected object
    const quaternary = nextValue()
    if (quaternary === 'edit') state.page = quaternary
    else if (primary === 'person') state.predictorId = quaternary
    else if (tertiary === 'table') state.table = quaternary
    else if (secondary === 'predictors') state.predictorTab = quaternary
    else if (tertiary === 'saved-queries') state.savedQueryId = quaternary
    else if (secondary === 'connectors') state.page = quaternary
    else if (secondary === 'programs')
      state.programTab = quaternary as INavigationState['programTab']
    else if (tertiary === 'run') state.queryRunId = quaternary
    else if (tertiary === 'transactions') state.transactionId = quaternary
    else if (secondary === 'terminologies') {
      if (tertiary === 'search-codes') state.page = tertiary
      else {
        state.codeSystemId = tertiary
        state.codeId = quaternary
          ? decodeURI([quaternary, ...paths].join('/'))
          : undefined
      }
    }

    const quinary = nextValue()
    // PHF Report Details
    if (quaternary === 'entity') state.entityId = quinary
    // Selected Model
    if (quinary && ['edit', 'create'].includes(quinary)) state.page = quinary
    else if (secondary === 'predictors') state.modelId = quinary

    // Model sub-pages
    const senary = nextValue()
    if (senary && ['edit', 'cross-validation', 'bias-and-fairness'].includes(senary))
      state.page = senary
    else if (senary === 'factors') {
      state.page = undefined
      state.factorId = paths.join('/')
    }
    // Selected Factors or Predictions
    const septenary = nextValue()
    if (senary === 'factors') {
      state.page = undefined
      state.factorName = septenary
    } else if (senary === 'predictions') state.runId = septenary

    // Predictions sub-pages
    const octonary = nextValue()
    if (!state.page) state.page = octonary
    // Prediction obfuscated entity ID
    const nonary = nextValue()
    if (octonary === 'entity' && quaternary === 'models') state.entityId = nonary

    // Hash
    state.hash = currentHash
    additionalData = {}

    if (state.psId) setFeatureFlagPersonStore(state.psId)
    saveCurrentUrl()
    initialState.merge(state as Partial<any>)
  }
}

/*
platform/blue-health/data-sources/upload/
platform/blue-health/data-sources/upload-history/
platform/blue-health/data-sources/data-dictionary/
platform/blue-health/data-sources/data-dictionary/tables/claims
platform/blue-health/data-sources/terminologies/
platform/blue-health/data-sources/terminologies/LOINC/
platform/blue-health/data-sources/terminologies/LOINC/a1b2c3
platform/blue-health/data-sources/terminologies/search-codes

platform/blue-health/data-prep/entities/
platform/blue-health/data-prep/entities/create
platform/blue-health/data-prep/entities/a1b2c3
platform/blue-health/data-prep/entities/a1b2c3/edit
platform/blue-health/data-prep/adapters/
platform/blue-health/data-prep/adapters/create
platform/blue-health/data-prep/adapters/a1b2c3
platform/blue-health/data-prep/adapters/a1b2c3/edit
platform/blue-health/data-prep/features/
platform/blue-health/data-prep/features/create
platform/blue-health/data-prep/features/a1b2c3
platform/blue-health/data-prep/features/a1b2c3/edit
platform/blue-health/data-prep/populations/
platform/blue-health/data-prep/populations/create
platform/blue-health/data-prep/populations/a1b2c3
platform/blue-health/data-prep/populations/a1b2c3/edit
platform/blue-health/data-prep/quality-control/

platform/blue-health/automl/predictors/
platform/blue-health/automl/predictors/create
platform/blue-health/automl/predictors/a1b2c3
platform/blue-health/automl/predictors/a1b2c3/edit
platform/blue-health/automl/predictors/a1b2c3/models
platform/blue-health/automl/predictors/a1b2c3/models/a1b2c3/
platform/blue-health/automl/predictors/a1b2c3/models/a1b2c3/edit
platform/blue-health/automl/predictors/a1b2c3/models/a1b2c3/factors/inpatient_days
platform/blue-health/automl/predictors/a1b2c3/models/a1b2c3/predictions/<run_id>
platform/blue-health/automl/predictors/a1b2c3/models/a1b2c3/predictions/<run_id>/entity/a1b2c3
platform/blue-health/automl/predictors/a1b2c3/model-runs

platform/blue-health/evaluate/programs/
platform/blue-health/evaluate/programs/create
platform/blue-health/evaluate/programs/a1b2c3/edit
platform/blue-health/evaluate/programs/a1b2c3/monitor
platform/blue-health/evaluate/programs/a1b2c3/impact

platform/blue-health/ml-ops/deployments/
platform/blue-health/ml-ops/deployments/create
platform/blue-health/ml-ops/deployments/a1b2c3
platform/blue-health/ml-ops/deployments/a1b2c3/edit
platform/blue-health/ml-ops/report-definitions/
platform/blue-health/ml-ops/report-definitions/create
platform/blue-health/ml-ops/report-definitions/a1b2c3
platform/blue-health/ml-ops/report-definitions/a1b2c3/edit
platform/blue-health/ml-ops/monitoring/data
platform/blue-health/ml-ops/monitoring/models
platform/blue-health/ml-ops/monitoring/reports

forecasts/blue-health/search
forecasts/blue-health/search?entityId=a1b2c3
forecasts/blue-health/search/<phf_id>
forecasts/blue-health/search/<phf_id>?entityId=a1b2c3
forecasts/blue-health/report/a1b2c3/entity/a1b2c3
forecasts/blue-health/configurations/
forecasts/blue-health/configurations/create
forecasts/blue-health/configurations/a1b2c3
forecasts/blue-health/configurations/a1b2c3/edit

user-management/blue-health/users
user-management/blue-health/users/a1b3c3
user-management/blue-health/users/a1b3c3/edit
user-management/blue-health/users/invite

platform/blue-health/data-prep/queries/ (redirects to query-builder)
platform/blue-health/data-prep/queries/create
platform/blue-health/data-prep/queries/create?template=a1b2c3
platform/blue-health/data-prep/queries/create?name=a1b2c3
platform/blue-health/data-prep/queries/saved-queries
platform/blue-health/data-prep/queries/saved-queries/a1b2c3
platform/blue-health/data-prep/queries/saved-queries/a1b2c3/edit
platform/blue-health/data-prep/queries/runs
platform/blue-health/data-prep/queries/run/a1b2c3
*/
