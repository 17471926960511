import React from 'react'
import { size } from 'lodash'

import { AlertOptions } from '../../Alert'
import { AlertPopupType } from '../AlertPopup'
import AlertSingle from '../AlertSingle/AlertSingle'
import './AlertCombo.scss'

export type AlertComboProps = {
  centered?: boolean
  combos: AlertOptions['combos']
  primaryType: AlertPopupType
}

const AlertCombo: React.FC<AlertComboProps> = ({ combos, centered }) => {
  if (!size(combos)) throw Error("AlertCombo.tsx requires 'combos'")

  return (
    <div className="alert-popup__combo">
      {combos?.map(({ type, message, moreInfo }, i) => (
        <AlertSingle
          key={i}
          centered={centered}
          message={message}
          moreInfo={moreInfo}
          type={type}
        />
      ))}
    </div>
  )
}

AlertCombo.displayName = 'AlertCombo'
export default AlertCombo
