import React from 'react'
import classnames from 'classnames'
import { colorToHsl, isColor } from 'colors-convert'
import { noop } from 'lodash'

import TruncateText from 'components/TruncateText'

import CloseTagIcon from './CloseTagIcon'
import './Tag.scss'

export interface TagProps {
  closable?: boolean
  color?: string
  maxWidth?: string | number
  name: string
  id: string
  style?: React.CSSProperties
  onClick?: (e: MouseEvent) => void
  onClose?: (e: MouseEvent) => void
}

const Tag: React.FC<TagProps> = ({
  closable = false,
  color = '#eee',
  maxWidth,
  name = '',
  id = '',
  style,
  onClick,
  onClose = noop
}) => {
  const hslColor: any = isColor(color) ? colorToHsl(color) : { l: 0 }

  const handleKeyPress = (e) => {
    if (onClick && e.key === 'Enter') onClick(e)
  }

  return (
    <div
      key={id}
      tabIndex={onClick ? 0 : -1}
      className={classnames('tag_v3', { clickable: onClick })}
      onKeyDown={handleKeyPress}
      onClick={onClick || noop}
      style={{
        maxWidth,
        color:
          hslColor.l < 50 || (hslColor.l < 80 && Math.abs(hslColor.h - 45) > 5)
            ? '#fff'
            : '#000',
        backgroundColor: color,
        ...style
      }}
    >
      <TruncateText>{name}</TruncateText>
      {closable && <CloseTagIcon onClick={onClose} />}
    </div>
  )
}

Tag.displayName = 'Tag'
export default Tag
