// @ts-nocheck
import { localStorage, sessionStorage } from 'utils/storage'

// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

const isE2ETest = navigator.userAgent.includes('HeadlessChrome')
const TWO_WEEKS = 14 * 24 * 60 * 60
const APP_LAST_UPDATE_KEY = 'sw_update_s'

export default function register(config) {
  if (
    import.meta.env.MODE === 'production' &&
    'serviceWorker' in navigator &&
    !isE2ETest
  ) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(import.meta.env.VITE_APP_URL, window.location)
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if VITE_APP_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
      return
    }

    window.addEventListener('load', () => {
      const swUrl = `${import.meta.env.VITE_APP_URL}/service-worker.js`

      if (isLocalhost) {
        // This is running on localhost. Lets check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config)
      } else {
        // Is not local host. Just register service worker
        registerValidSW(swUrl, config)
      }
    })
  }
}

function registerValidSW(swUrl, config) {
  if (navigator.serviceWorker.register) {
    navigator.serviceWorker
      .register(swUrl)
      .then((registration) => {
        const lastAppUpdate = localStorage.getItem(APP_LAST_UPDATE_KEY)
        const currentTime = new Date().getTime()

        if (lastAppUpdate && currentTime - lastAppUpdate > TWO_WEEKS) {
          localStorage.setItem(APP_LAST_UPDATE_KEY, currentTime)

          // Clear file caches for app
          caches.keys().then((keys) => {
            keys.forEach((key) => caches.delete(key))
          })

          // Unregister service worker to give slot to new service worker
          navigator.serviceWorker.ready.then((registration) => {
            registration.unregister()
          })

          // Reload page to start downloading new app
          window.location.reload()
        }

        registration.onupdatefound = () => {
          const installingWorker = registration.installing || {}

          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                sessionStorage.setItem('appUpdateReady', true)
                // At this point, the updated precached content has been fetched,
                // but the previous service worker will still serve the older
                // content until all client tabs are closed.

                // Execute callback
                if (config && config.onUpdate) {
                  config.onUpdate(registration)
                }
              } else {
                // At this point, everything has been precached.
                // It's the perfect time to display a
                // "Content is cached for offline use." message.

                if (config && config.onSuccess) {
                  config.onSuccess(registration)
                }
              }
            }
          }
        }
      })
      .catch((error) => {
        console.error('Error during service worker registration:', error)
      })
  }
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' }
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type')
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload()
          })
        })
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config)
      }
    })
    .catch(() => {
      // No internet connection found. App is running in offline mode.
    })
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister()
      })
      .catch((error) => {
        console.error(error.message)
      })
  }
}

export function loadWaitingServiceWorker() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      const waitingWorker = registration.waiting || {}

      localStorage.setItem(APP_LAST_UPDATE_KEY, new Date().getTime())
      sessionStorage.setItem('appUpdateReady', false)
      if (waitingWorker.state === 'installed') {
        waitingWorker.postMessage({ type: 'SKIP_WAITING' })
        setTimeout(() => {
          window.location.reload()
        })
      } else {
        registration.update()
      }
    })
  }
}
