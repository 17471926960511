import React, { ReactNode, useEffect, useState } from 'react'
import { get, noop, size } from 'lodash'

import NavigationState from 'state/NavigationState'
import PopulationState from 'state/PopulationState'
import { Link } from 'providers/Router'

import Pill from 'components/Pill'
import SkeletonLoader from 'components/SkeletonLoader'
import Tooltip from 'components/Tooltip'
import { describeStep } from 'utils/helpers'
import { Underlined } from 'icons'

import './DisplayPopulation.scss'

type PopulationSelectionStep = {
  _derivedType: string
  popId?: { id: string; majorVersion?: number; minorVersion?: number }
  percent?: number
}

export interface DisplayPopulationProps {
  psId?: string
  steps: PopulationSelectionStep[]
  _mockedServices?: {
    NavigationState?: () => { psId: string }
    PopulationState?: () => {
      fetched: boolean
      fetching: boolean
      fetchPopulations: () => any
      findPopulation: ({ popId: { id: string } }) => any
      fetchPopulation: ({ popId: { id: string } }) => any
    }
  }
  children?: ReactNode
}

const DisplayPopulation: React.FC<DisplayPopulationProps> = ({
  psId: psIdProp,
  steps = [],
  _mockedServices = {},
  children
}) => {
  const { psId: psIdState }: any = (_mockedServices.NavigationState || NavigationState)()

  const psId = psIdProp ?? psIdState

  const numSteps = size(steps)
  const step1 = steps[0]
  const step2 = steps[1]

  let coverText: React.ReactNode = 'Custom Population'

  // any given label always takes priority over the generated one
  if (children) {
    coverText = children
  }
  // Subset population with one Limit Percent or Exclude Steps
  else if (
    numSteps === 2 &&
    step1._derivedType === 'SubsetPop' &&
    ['LimitPercent', 'ExcludeSteps'].includes(step2._derivedType)
  ) {
    coverText = (
      <>
        <SubsetPopName
          psId={psId}
          popId={step1.popId}
          _mockedServices={_mockedServices}
        />
        {`(${
          step2._derivedType === 'LimitPercent' ? 'Limit Percent' : 'Exclude Subset'
        })`}
      </>
    )
  }

  // Entire Population
  if (numSteps === 0) {
    return (
      <div className="display-population">
        {children ? children : 'Entire Population'}
      </div>
    )
  }

  // Entire population with limit percent
  if (numSteps === 1 && step1._derivedType === 'LimitPercent') {
    const percent = get(step1, 'percent', 0) * 100
    return <div className="display-population">Entire Population&nbsp;({percent}%)</div>
  }

  // Subset population
  if (numSteps === 1 && step1._derivedType === 'SubsetPop') {
    return (
      <div className="display-population">
        <SubsetPopName
          psId={psId}
          popId={step1.popId}
          _mockedServices={_mockedServices}
        />
      </div>
    )
  }

  // Population with hover text
  return (
    <div className="display-population custom">
      <Tooltip
        mouseEnterDelay={0.2}
        maxWidth="80vw"
        placement="top-end"
        offsetY={4}
        overlay={
          <div className="display-population__popover">
            {steps.map((step, i) =>
              step._derivedType === 'SubsetPop' ? (
                <SubsetPopName
                  key={`popStep-${i + 1}`}
                  psId={psId}
                  stepNum={i + 1}
                  popId={step.popId}
                  _mockedServices={_mockedServices}
                />
              ) : (
                <div key={`popStep-${i + 1}`}>
                  <span>
                    Step {i + 1}.&nbsp;
                    {describeStep(step, { includeType: true })}
                  </span>
                </div>
              )
            )}
          </div>
        }
      >
        <span>
          <Underlined>{coverText}</Underlined>
        </span>
      </Tooltip>
    </div>
  )
}

function SubsetPopName({ psId, popId, stepNum = 0, _mockedServices }) {
  const { psId: psIdState }: any = (_mockedServices.NavigationState || NavigationState)()

  // population state caching is limited to the currently viewed person store.
  // for example: on a team dashboard page, there isn't really one selected person store so caching won't matter
  const cachingAvailable = !!(psIdState === psId)

  const populationState = (_mockedServices.PopulationState || PopulationState)()

  const [population, setPopulation] = useState<Population | undefined>()
  const [latestPopulation, setLatestPopulation] = useState<Population | undefined>()

  const handleGetFromCache = () => {
    // ready to set from cache
    if (populationState?.fetchedPopulations) {
      const latestPopulationCache = populationState.findPopulation({
        id: get(popId, 'id')
      })

      // if the latest version is not the selected version then the selected version cannot be retrieved from cache and needs to be fetched manually,
      // only the latest version of the population will be in cache
      if (latestPopulationCache?.version?.major !== popId?.majorVersion) {
        populationState.fetchPopulation({ popId }).then(setPopulation)
      } else {
        setPopulation(latestPopulationCache)
      }
      setLatestPopulation(latestPopulationCache)
    }
    // need to fetch before setting from cache
    else if (!populationState?.fetchingPopulations) {
      populationState.fetchPopulations()
    }
    // otherwise noop if it's already fetching
  }

  const handleGetFromServer = () => {
    // this should not run if the population is already set
    if (!population) {
      // selected population, we don't know if this is the latest one
      populationState.fetchPopulation({ popId, psId }).then(setPopulation)
      // latest population
      populationState
        .fetchPopulation({ popId: { id: get(popId, 'id') }, psId })
        .then(setLatestPopulation)
    }
  }

  useEffect(() => {
    if (cachingAvailable) {
      handleGetFromCache()
    } else {
      handleGetFromServer()
    }
  }, [populationState?.fetchedPopulations, populationState?.fetchingPopulations])

  const isOutdated = popId.majorVersion
    ? get(latestPopulation, 'version.major', 0) > popId.majorVersion
    : false

  return (
    <>
      {!population ? (
        <SkeletonLoader />
      ) : (
        <div className="display-population__subset-pop">
          {!!stepNum && <span>Step {stepNum}. Subset Population - </span>}
          <Link
            href={`/platform/${psId}/data-prep/populations/${popId.id}`}
            onClick={noop}
          >
            {population.name}
          </Link>{' '}
          {population.isArchived && <Pill color="lightGray" label="Archived" />}
          {isOutdated && <Pill color="yellow" label="Outdated" />}
        </div>
      )}
    </>
  )
}

DisplayPopulation.displayName = 'DisplayPopulation'
export default DisplayPopulation
