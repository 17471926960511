import React, { useMemo } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import { noop } from 'lodash'

export interface NotApplicableProps {
  alt: string
  id?: string
  className?: string
  fill?: string
  onClick?: () => void
}

const NotApplicable: React.FC<NotApplicableProps> = ({
  alt,
  id,
  fill = '#BAC3C4',
  className,
  onClick = noop
}) => {
  const markup = useMemo(
    () =>
      encodeURIComponent(
        renderToStaticMarkup(
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.99962 0.0273438C3.59558 0.0273438 0.0253906 3.56704 0.0253906 7.93347C0.0253906 12.2999 3.59558 15.8396 7.99962 15.8396C12.4037 15.8396 15.9738 12.2999 15.9738 7.93347C15.9738 3.56704 12.4037 0.0273438 7.99962 0.0273438ZM12.1017 8.73168H3.89749V7.13525H12.1017V8.73168Z"
              fill={fill}
            />
          </svg>
        )
      ),
    [fill]
  )

  return (
    <img
      alt={alt}
      id={id}
      className={classnames('icon-code', className)}
      onClick={onClick}
      src={`data:image/svg+xml,${markup}`}
    />
  )
}

NotApplicable.displayName = 'NotApplicable'
export default NotApplicable
