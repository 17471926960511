import useLocation from './useLocation'

// Wouter exports the current path with the setLocation hook.
// This removes the path for quicker development

const SetLocation = ({ base = '' } = {}) => {
  // eslint-disable-next-line
  const [path, setLocation] = useLocation({ base })
  return setLocation
}

export default SetLocation
