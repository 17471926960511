import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import Spinner from 'components/Spinner'
import { Logo } from 'icons'

import './AuthLoading.scss'

export default function AuthLoading({ loading }) {
  return (
    <AnimatePresence>
      {loading && (
        <div className="auth-loading flex-center">
          <motion.div
            key="auth-loader"
            initial={{ opacity: 0, height: 50, scale: 0.7 }}
            animate={{ opacity: 1, height: 526, top: 0, scale: 1 }}
            exit={{ opacity: 0, top: 200, scale: 0.7 }}
            transition={{ duration: 0.6, ease: 'easeIn' }}
            className="auth-loading__card align-center"
          >
            <Logo alt="ClosedLoop logo" />
            <Spinner size={60} />
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}
