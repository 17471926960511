import { useMemo } from 'react'
import { createState, Downgraded, useState } from '@hookstate/core'
import { last } from 'lodash'

import { pullLocalState } from 'state/UserPreferencesState'

import jwtDecode from 'utils/helpers/jwtDecode'
import { mapValues } from 'utils/helpers'
import { localStorage as ls } from 'utils/storage'

export type Permission =
  | 'cl:all:admin'
  | 'cl:all:cross_org'
  | 'cl:all:basic_read'
  | 'cl:all:basic_write'
  | 'cl:platform:read_internals'
  | 'cl:platform:operator'
  | 'ext:all:owner'
  | 'ext:platform:basic_read'
  | 'ext:platform:basic_write'
  | 'ext:platform:admin'
  | 'ext:platform:read_internals'
  | 'ext:platform:write_internals'
  | 'ext:platform:manage_acls'
  | 'ext:platform:operator'
  | 'ext:platform:upload_data'
  | 'ext:platform:deployer'
  | 'ext:phf:basic_read'
  | 'ext:phf:basic_write'
  | 'ext:phf:admin'
  | 'ext:eval:basic_read'
  | 'ext:eval:basic_write'
  | 'ext:eval:admin'
  | 'ext:acosb:basic_read'
  | 'ext:acosb:basic_write'
  | 'ext:acosb:admin'
  | 'ext:cost_dashboard:basic_read'
  | 'ext:ssum:basic_read'
  | 'ext:ssum:basic_write'
  | 'ext:ssum:admin'

// prettier-ignore
const FALLBACK_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhZTE0MiJ9.Zq6NXmsZnlUTNbs_lT5ZPJawO_omXabd0o_GgadU8eU'
const decodedToken = jwtDecode(ls.getItem('access_token') || FALLBACK_TOKEN)

const userSchema = {
  avatar: '',
  email: '',
  issuedAt: '',
  locale: 'en',
  name: '',
  nickname: '',
  developerType: '',
  organization: '',
  permissions: [],
  subject: ''
}

const initialState = createState(userSchema).attach(Downgraded)
let AuthService

const stateWrapper = (state) => {
  const wrapper = {
    get user() {
      return state.get()
    },
    get uniqueId() {
      const id = this.user.subject || decodedToken.sub
      return id.replace('auth0|', '').replace('google-oauth2|', '')
    },
    hasPermission(requestedPermission: Permission, { exactMatch = false } = {}) {
      const userPermissions = new Set(state.permissions.get())
      /* 
        For more information on how permission hierarchy works, you can read the PEP on permissions at
        https://closedloop.atlassian.net/wiki/spaces/HEAL/pages/2942205957/PEP-159+Permission+and+Role+Updates
      */

      if (!exactMatch) {
        const externalRequest = requestedPermission.startsWith('ext:')
        const requestedApp = requestedPermission.split(':')[1]

        // If internal admin, let them admin
        if (userPermissions.has('cl:all:admin')) return true

        // If external owner, let them do anything
        if (externalRequest && userPermissions.has('ext:all:owner')) return true
        if (externalRequest && userPermissions.has(`ext:${requestedApp}:admin`))
          return true

        // If user has cl:all permission and requested the ext: permission, they have permission
        const rootPermission = last(requestedPermission.split(':'))
        if (externalRequest && userPermissions.has('cl:all:' + rootPermission))
          return true
      }

      return userPermissions.has(requestedPermission)
    },
    setUser(user: any = {}) {
      const userName = [user.given_name, user.family_name].filter(Boolean).join(' ')

      state.set({
        avatar: user.picture,
        email: user.email,
        issuedAt: user.iat,
        locale: user.locale,
        name: userName,
        nickname: user.nickname,
        developerType: user['https://closedloop.ai/developerType'],
        organization: user['https://closedloop.ai/organization'],
        permissions: user['https://closedloop.ai/permissions'],
        subject: user.sub
      })

      pullLocalState()
    },
    removeUser() {
      state.set(userSchema)
    },
    logout(
      {
        reason = 'manual'
      }: {
        reason: 'expired' | 'manual' | 'error' | 'unauthorized'
      } = { reason: 'manual' }
    ) {
      // prettier-ignore
      AuthService.logout({ reason })
    },
    setAuthService(authService) {
      AuthService = authService
    }
  }
  return mapValues(wrapper, (f) =>
    typeof f === 'function' ? f.bind(wrapper) : f
  ) as typeof wrapper
}

const AuthorizationState = () => {
  const state = useState(initialState)
  return useMemo(() => stateWrapper(state), [state])
}
const AuthorizationAccess = () => stateWrapper(initialState)
export { AuthorizationState as default, AuthorizationAccess }
