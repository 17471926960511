import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import PropTypes from 'prop-types'

export default class Check extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(color = '#232829') {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 6.5L6.04839 12.763L17.0125 1.01575" stroke={color} strokeWidth="1.2"/>
        </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        className={this.props.className}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
