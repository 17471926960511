import React, { useMemo } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

export interface IndeterminateIconProps {
  alt: string
  className?: string
  fill?: string
}

const IndeterminateIcon: React.FC<IndeterminateIconProps> = ({
  alt,
  className,
  fill = '#fff'
}) => {
  // prettier-ignore
  const staticImg = useMemo(() =>  
    encodeURIComponent(
      renderToStaticMarkup(
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="13" height="13" rx="1" />
          <rect x="2.5" y="5.5" width="8" height="1.5" fill={fill}/>
        </svg>
      )
    )
  , [fill])

  return <img alt={alt} className={className} src={`data:image/svg+xml,${staticImg}`} />
}

IndeterminateIcon.displayName = 'IndeterminateIcon'
export default IndeterminateIcon
