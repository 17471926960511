import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export default class OvalCheckProfessional extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(color = '#2592DA') {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M10.3327 0.592407L12.558 2.61603L15.5242 2.11673L16.302 5.02212L19.0673 6.20557L18.1512 9.07097L19.8371 11.5611L17.5174 13.4759L17.5894 16.4829L14.6032 16.839L13.0381 19.4075L10.3327 18.0929L7.62814 19.4075L6.06301 16.839L3.07598 16.4829L3.1488 13.4759L0.829102 11.5611L2.51505 9.07097L1.59887 6.20557L4.36424 5.02212L5.14202 2.11673L8.10824 2.61603L10.3327 0.592407ZM5.69013 11.337L8.29788 13.9448C8.60995 14.2569 9.11726 14.2569 9.42932 13.9448L14.9753 8.39803C15.2882 8.08596 15.2882 7.57865 14.9753 7.26659C14.6632 6.95452 14.1567 6.95452 13.8438 7.26659L8.8636 12.2468L6.82235 10.2056C6.50949 9.89355 6.003 9.89355 5.69013 10.2056C5.37807 10.5177 5.37807 11.025 5.69013 11.337Z" fill={color}/>
        </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        id={this.props.id}
        className={classnames('oval-check-icon', this.props.className)}
        style={this.props.style}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
