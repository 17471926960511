import a from 'indefinite'

// Can be accessed at locale.t('userManagement.<key>')

type DeactivateDetails = {
  name: string
  action: 'reactivate' | 'deactivate'
}

export default {
  inline: {
    deactivateModalTitle: ({ name, action }: DeactivateDetails) =>
      `Are you sure you want to ${action} ${name}?`,
    deactivateModalWarning:
      'This user will no longer have access to ClosedLoop products. If you need to reinstate access for this user later, you can reactivate this user.',
    reactivateModalWarning:
      'This user will regain access to ClosedLoop products. To remove access, you can deactivate this user.',
    deactivateSuccessMessage: ({ name, action }: DeactivateDetails) =>
      `${name} has been ${action}d.`,
    resetPasswordTitle: `Are you sure you want to reset this user’s password?`,
    resetPasswordWarning: `The user will be unable to log in to ClosedLoop products until they create a new password. An email will be sent to the user with instructions on how to reset their password.`,
    resetPasswordSuccess: ({ email }) => `Password reset has been sent to ${email}.`,
    resetPasswordFail: 'Password reset has failed.'
  },
  invite: {
    success: ({ email }) => `Invitation has been successfully sent to ${email}`,
    error: 'User information could not be saved.'
  },
  edit: {
    success: `User's information has been updated. User role changes may require up to a minute to take effect.`,
    error: `User's information could not be saved.`
  },
  roles: {
    description: ({ appName }) => `Assign the user ${a(appName)} role.`,
    placeholder: ({ appName }) =>
      `Customer Owners are granted ${appName || 'app'} access by default.`
  }
}
