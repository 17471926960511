/**
 * Creates an object with the same keys as `object` and values generated
 * by running each own enumerable string keyed property of `object` thru
 * `iteratee`. The iteratee is invoked with three arguments:
 * (value, key, object).
 *
 * @param {Object} object The object to iterate over.
 * @param {Function} iteratee The function invoked per iteration.
 * @returns {Object} Returns the new mapped object.
 *
 * **Note:** Used as a replacement for lodash's _.mapValues when Typescript support is needed.
 */

const mapValues = (data: {}, callback: Function) => {
  if (Array.isArray(data))
    throw new TypeError('mapValues() expects an object as the first parameter')

  return Object.fromEntries(Object.entries(data).map(([k, v]) => [k, callback(v)]))
}

export default mapValues
