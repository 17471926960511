import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export default class OldTrash extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(color = '#8E8E8E') {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
          <path fill={color} fillRule="evenodd" d="M12.385 4.5H1.615a.55.55 0 0 1-.538-.563c0-.31.241-.562.538-.562h10.77c.297 0 .538.252.538.563a.55.55 0 0 1-.538.562zm-.539 11.25c0 .621-.482 1.125-1.077 1.125H3.231c-.595 0-1.077-.504-1.077-1.125V5.625h9.692V15.75zM5.385 1.687a.55.55 0 0 1 .538-.562h2.154a.55.55 0 0 1 .538.563v.562h-3.23v-.563zm7.538.563h-3.23V1.125C9.692.504 9.21 0 8.614 0h-3.23C4.79 0 4.308.504 4.308 1.125V2.25H1.077C.482 2.25 0 2.754 0 3.375V4.5c0 .621.482 1.125 1.077 1.125V15.75c0 1.243.964 2.25 2.154 2.25h7.538c1.19 0 2.154-1.007 2.154-2.25V5.625C13.518 5.625 14 5.121 14 4.5V3.375c0-.621-.482-1.125-1.077-1.125zM7 15.75a.55.55 0 0 0 .538-.563v-6.75A.551.551 0 0 0 7 7.875a.551.551 0 0 0-.538.563v6.75A.55.55 0 0 0 7 15.75zm-2.692 0a.55.55 0 0 0 .538-.563v-6.75a.551.551 0 0 0-.538-.562.551.551 0 0 0-.539.563v6.75a.55.55 0 0 0 .539.562zm5.384 0a.55.55 0 0 0 .539-.563v-6.75a.551.551 0 0 0-.539-.562.551.551 0 0 0-.538.563v6.75a.55.55 0 0 0 .538.562z"/>
        </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        id={this.props.id}
        className={classnames('icon-old-trash', this.props.className)}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
