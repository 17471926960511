import React, { useMemo } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import { noop } from 'lodash'

export interface PencilProps {
  alt: string
  id?: string
  className?: string
  fill?: string
  onClick?: () => void
}

const Pencil: React.FC<PencilProps> = ({
  alt,
  id,
  fill = '#232829',
  className,
  onClick = noop
}) => {
  const markup = useMemo(
    () =>
      encodeURIComponent(
        renderToStaticMarkup(
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
            <path
              fill={fill}
              fillRule="nonzero"
              d="M13.054 1.288a3.023 3.023 0 0 1-.002 4.27l-.005.006a.337.337 0 0 1-.036.026.299.299 0 0 1-.038.048l-.943.942-6.464 6.456a.304.304 0 0 1-.411.016l-3.945.411-.008.001h-.024a.305.305 0 0 1-.228-.105.307.307 0 0 1-.071-.194v-.033l.411-3.944a.304.304 0 0 1 .016-.411l6.456-6.465.943-.942a.299.299 0 0 1 .048-.038.337.337 0 0 1 .026-.036l.005-.006a3.023 3.023 0 0 1 4.27-.002Zm-1.84 5.117L7.902 3.09l-5.604 5.61.96.29c.093.03.165.101.194.194l.387 1.281 1.284.39a.294.294 0 0 1 .194.194l.29.96 5.607-5.605ZM3.32 10.793l-.388-1.281-.938-.283-.235 2.26 1.064 1.057 2.255-.233-.284-.94-1.28-.386a.294.294 0 0 1-.194-.194Zm9.095-8.903a2.345 2.345 0 0 0-3.174-.123l3.297 3.297a2.344 2.344 0 0 0-.123-3.174Zm-4.095.784 3.311 3.31.497-.495-3.31-3.314-.498.499Z"
            />
          </svg>
        )
      ),
    [fill]
  )

  return (
    <img
      alt={alt}
      id={id}
      className={classnames('pencil', className)}
      onClick={onClick}
      src={`data:image/svg+xml,${markup}`}
    />
  )
}

Pencil.displayName = 'Pencil'
export default Pencil
