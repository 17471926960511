/* Defaults are set for dev and test environments.
 * In production environments, these values must be overridden.
 *
 * To override you can put values in a .env file, or you
 * can set environment variables.  Environemnt variables
 * take preference.
 */

function deployVar(envName, devDefault) {
  const envValue = import.meta.env[envName]
  if (import.meta.env.NODE_ENV === 'production') {
    if (!envValue) {
      throw new Error(`Missing production value for deployment variable '${envName}'`)
    }
    return envValue
  } else {
    return envValue || devDefault
  }
}

const AppUrl = deployVar('VITE_APP_URL', 'http://localhost:3000')

const AppAPIUrl = deployVar('VITE_APP_API_SERVER', 'https://dev.closedloop.ai/api/v1/')

const Auth0APIAudience = deployVar(
  'VITE_APP_AUTH0_API_AUDIENCE',
  'https://demo.closedloop.ai:10001'
)

const Auth0ClientId = deployVar(
  'VITE_APP_AUTH0_CLIENT_ID',
  '4OI3DFDrDsAdcqdI64njNkdkoAE5As1y'
)

const Auth0Domain = deployVar('VITE_APP_AUTH0_DOMAIN', 'auth.closedloop.ai')

const Auth0DomainV2 = deployVar('VITE_APP_AUTH0_DOMAIN_V2', 'auth-dev.closedloop.ai')
const Auth0APIAudienceV2 = deployVar(
  'VITE_APP_AUTH0_API_AUDIENCE_V2',
  'https://dev.closedloop.ai'
)
const Auth0ClientIdV2 = deployVar(
  'VITE_APP_AUTH0_CLIENT_ID_V2',
  'z0Oj4g9F0avaDNMrY0vuR01t292uCsfv'
)

const BuildNumber = deployVar('VITE_APP_BUILD_NUMBER', '?')

const IdleTimeout = parseInt(deployVar('VITE_APP_IDLE_TIMEOUT', '604800'), 10)

const AppEnv = deployVar('VITE_APP_ENV', 'master')

const MixpanelToken = deployVar(
  'VITE_APP_MIXPANEL_TOKEN',
  'f2404eaf7d49d8a462cfab53ff6c04aa'
)

const LaunchDarklyKey = deployVar(
  'VITE_APP_LAUNCH_DARKLY_KEY',
  '634f14b010a3d912f661ce0e'
)

const DatadogToken = 'pubed9dc2a3f695e0572cfd1562459fda04'

export {
  AppUrl,
  AppAPIUrl,
  AppEnv,
  Auth0APIAudience,
  Auth0ClientId,
  Auth0Domain,
  Auth0APIAudienceV2,
  Auth0ClientIdV2,
  Auth0DomainV2,
  BuildNumber,
  IdleTimeout,
  MixpanelToken,
  LaunchDarklyKey,
  DatadogToken
}
