import React from 'react'
import classnames from 'classnames'

import './Spinner.scss'
import { RouteComponentProps } from 'wouter'

export interface SpinnerProps {
  className?: string
  id?: string
  inline?: boolean
  invert?: boolean
  ref?:
    | (React.RefObject<HTMLButtonElement> & string)
    | (React.RefObject<HTMLAnchorElement> & string)
  size?: number
  style?: React.CSSProperties
}

const Spinner: React.FC<SpinnerProps> = ({
  id,
  ref,
  size = 24,
  invert = false,
  inline = false,
  style,
  className
}) => {
  return (
    <svg
      id={id}
      className={classnames('spinner_v3', className)}
      focusable="false"
      height={size}
      width={size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      style={{ ...{ display: inline ? 'inline' : 'block' }, ...style }}
    >
      <circle cx="8" cy="8" r="7" style={{ stroke: invert ? '#FFFFFF' : '#2E2F30' }} />
    </svg>
  )
}

Spinner.displayName = 'Spinner'
export default Spinner
