import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'
import { round } from 'lodash'
import queryString from 'query-string'

import { AuthorizationAccess } from 'state/AuthorizationState'

import varna from 'utils/varna'

import { AppAPIUrl } from 'v1/Constants.js'
import { showErrorsInConsole } from 'v1/providers/FeatureToggles'

/**
  ***Custom Instance***
  This prevents duplicate calls from happening.
  If an identical call is made while the last is still pending, hook into that call instead of making a new one
*/
type HTTPVerb = 'get' | 'head' | 'post' | 'put' | 'patch' | 'delete'

type RequestConfig =
  | (AxiosRequestConfig & {
      disconnected?: boolean
      retry?: number
      retryOnDisconnect?: boolean
    })
  | undefined

type ApiCall = {
  method: HTTPVerb
  url: string
  data: string
  config: string
  promise: Promise<any>
}

class API {
  private _initted: boolean = false
  private _activeCalls: (ApiCall | null)[] = []
  private _initialWaitingCalls: ApiCall[] = []
  // private _testCounter: number = 1

  public instance: AxiosInstance | null = null
  public interceptors: { request: any; response: any } | null = null

  // API is intialized after Auth, see AuthService
  _init = () => {
    axios.defaults.headers['Cache-Control'] = 'no-store'
    axios.defaults.headers['Expires'] = '0'

    this.instance = axios.create({
      baseURL: AppAPIUrl
    })
    this.interceptors = this.instance.interceptors

    // To send arrays as params, we need to format it differently than axios does by default.
    // Axios by default will send []foo=1&[]foo=2, but our api expects foo=1&foo=2
    this.instance.defaults.paramsSerializer = (params) =>
      queryString.stringify(params, { skipNull: true })

    this.interceptors.request.use(
      (config) => {
        config.headers['Request-Start-Time'] = Date.now()
        config.headers['Authorization'] = `Bearer ${Cookies.get('token')}`
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )

    this.interceptors.response.use(
      (res: any) => {
        // Track the total duration for the api call
        const startTime = res.config.headers['Request-Start-Time']
        const duration = round((Date.now() - startTime) / 1000, 1)
        res.headers['duration'] = duration

        // When mocking responses for testing, I have found this to be easier than any mocking tool.

        // Example:
        // if (res.config.url.includes('factorDrillDown') && this._testCounter > 0) {
        //   this._testCounter--
        //   res.status = 202
        //   res.data = null
        // }

        return res
      },
      (err: any) => {
        if (
          err.response?.status === 401 &&
          err.response?.data.detail !== 'Email has not been verified'
        ) {
          AuthorizationAccess().logout({ reason: 'unauthorized' })
        }

        // If user is a closedloop user, log failed call details to console for quick debugging.
        const { user } = AuthorizationAccess()
        if (showErrorsInConsole || user?.organization === 'closedloop.ai')
          logErrorDetails(err)

        // Send error details to mixpanel and varna
        const detail = err.response?.data?.detail || ''
        const statusCode = err.response?.status || 0
        const message =
          statusCode >= 500
            ? '500 Error'
            : err.message === 'Network Error'
            ? 'Network Error'
            : ''

        if (
          message &&
          !detail.includes(
            'are incompatible.  Tried to convert from one to the other.'
          ) &&
          statusCode !== 504
        ) {
          const request = err.request.ea || {}
          if (!detail.includes('Timeout after 8 minutes') && statusCode < 500)
            varna.warn(message, {
              request: request.status_code === 0 ? err : request
            })
        }

        return Promise.reject(err)
      }
    )

    this._initted = true

    //Call everything waiting for API to initialize
    Promise.all(this._initialWaitingCalls)
  }

  _makeCall = async (
    method: HTTPVerb,
    url: string,
    data: any = {},
    config: RequestConfig = { retryOnDisconnect: true }
  ) => {
    // If axios hasn't finished setting up, let's hold the calls for later
    if (!this._initted) {
      const queuedCall = new Promise((resolve, reject) =>
        this._makeCall(method, url, data, config)
      )
      this._initialWaitingCalls.push(queuedCall)
      return queuedCall
    }

    // This looks for identical API calls and returns early if it finds one currently running
    const formData: any[] = []
    let formDataHasFile = false
    if (data?.constructor?.name === 'FormData') {
      for (var f of data) {
        if (f[1] instanceof File) formDataHasFile = true
        formData.push({ [f[0]]: f[1] })
      }
    }
    const strData = JSON.stringify(
      data?.constructor?.name === 'FormData' ? formData : data
    )
    const strConfig = JSON.stringify(config)
    for (let call of this._activeCalls) {
      if (
        !formDataHasFile &&
        call &&
        call.method === method &&
        call.url === url &&
        call.data === strData &&
        call.config === strConfig
      ) {
        try {
          const status = await Promise.race([call.promise, 'pending'])
          if (status === 'pending') return call.promise
        } catch (e) {
          // Do nothing
        }
      }
    }

    // THIS is where the actual call is made
    const newCall = {
      method,
      url,
      data: strData,
      config: strConfig,
      promise: this.instance
        ? this.instance[method](url, data || config, config)
        : Promise.resolve()
    }

    // Clear calls whether they succeed or fail, so things don't pile up in memory
    const index = this._activeCalls.push(newCall) - 1
    return newCall.promise
      .then((res) => {
        const {
          status,
          config
        }: { status: number; config: AxiosRequestConfig & { retry?: number } } = res
        const retry = config?.retry || 0
        if (status === 202 && retry > 0)
          return this._makeCall(method, url, data, { ...config, retry: retry - 1 })

        return res
      })
      .catch(async (res) => {
        // Some users' VPN will randomly disconnect which causes the call to fail.
        // If the user's connection disconnects, this will try the call one more time in a few seconds.
        if (
          res.message === 'Network Error' &&
          !config?.disconnected &&
          config.retryOnDisconnect
        ) {
          await new Promise((resolve) => setTimeout(resolve, 2000)) // Wait two seconds
          return this._makeCall(method, url, data, {
            ...config,
            disconnected: true
          })
        }

        return Promise.reject(res)
      })
      .finally(() => {
        this._activeCalls[index] = null
      })
  }

  get = (url, config = {}) => this._makeCall('get', url, null, config)
  head = (url, config = {}) => this._makeCall('head', url, null, config)
  post = (url, data = {}, config: RequestConfig = {}) =>
    this._makeCall('post', url, data, config)
  put = (url, data = {}, config = {}) => this._makeCall('put', url, data, config)
  patch = (url, data = {}, config = {}) => this._makeCall('patch', url, data, config)
  delete = (url, config = {}) => this._makeCall('delete', url, null, config)
  getRecentCalls = () => this._activeCalls
}

// Helper Functions
function logErrorDetails(err) {
  const detail = err.response?.data?.detail || ''
  const baseUrl = err.response?.config?.baseURL || ''
  const endpoint = err.response?.config?.url.replace(baseUrl, '/')
  const status = err.response?.status
  const isError = !(err.config.validateStatus?.(status) ?? false)

  if (window.console && isError) {
    const print = console

    print.groupCollapsed('Failed Call')
    print.log('Status:', status)
    print.log('Endpoint:', endpoint)
    print.log(`Error: %c"${detail}"`, 'color: red;')
    print.groupEnd()
  }
}

//To initialize the API, the AuthService provider needs to access the API instance directly as it is not a functional React component
const APIInstance = new API()
export default APIInstance
