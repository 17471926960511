import React from 'react'

// Can be accessed at locale.t('population.<key>')

export default {
  tooltip: {
    populationStepsAsOfDate: (
      <span>
        The <b>as of date</b> used to compute the size of the population in the selection
        steps funnel.
      </span>
    )
  }
}
