import React, { useState } from 'react'
import classnames from 'classnames'

import Button from 'components/Button'
import { OldOvalInfo2, OvalCheck, OvalErrorX, OvalWarning } from 'icons'

import { AlertOptions } from '../../Alert'
import { AlertPopupType } from '../AlertPopup'
import './AlertSingle.scss'

export type AlertSingleProps = {
  centered?: boolean
  message: React.ReactNode
  moreInfo?: AlertOptions['moreInfo']
  type: AlertPopupType
}

const AlertSingle: React.FC<AlertSingleProps> = ({
  centered,
  message,
  type,
  moreInfo
}) => {
  const [showInfo, setShowInfo] = useState(false)

  return (
    <div className="alert-popup__single">
      <div className="flex">
        <div className="alert-popup__icon">
          {type === 'success' && <OvalCheck alt="Success." />}
          {type === 'error' && <OvalErrorX alt="Error." />}
          {type === 'info' && <OldOvalInfo2 alt="Helpful Information." />}
          {type === 'warning' && <OvalWarning alt="Warning." />}
        </div>
        <div
          className={classnames('alert-popup__message', { centered })}
          aria-describedby={type}
        >
          {message}
        </div>
      </div>
      {moreInfo && (
        <div className="alert-popup__more-info">
          <Button
            className="alert-popup__more-info-button"
            type="clear"
            aria-label="Show more alert info"
            onClick={() => setShowInfo(!showInfo)}
          >
            {showInfo ? 'Hide info' : 'More info'}
          </Button>
          {showInfo && (
            <div className="alert-popup__more-info-body">
              {moreInfo.map(({ title, subTitle }, i) => (
                <div key={i} className="alert-popup__more-info-row">
                  <div className="title">{title}</div>
                  <div className="sub-title">{subTitle}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

AlertSingle.displayName = 'AlertSingle'
export default AlertSingle
