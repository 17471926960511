/* 
  Varna (Swedish for warn/alert)
  Error/Info logging for SPA 
*/

import { datadogRum as datadogClient } from '@datadog/browser-rum'
import { set } from 'lodash'
import Rollbar from 'rollbar'

import { FeatureFlagAccess } from 'state/FeatureFlagState'

import { phiCheck, print } from 'utils/helpers'

import { AppEnv, BuildNumber, DatadogToken } from 'v1/Constants'

let datadog: typeof datadogClient
let rollbar: Rollbar
const environment = (() => {
  switch (window.location.origin) {
    case 'https://apps.closedloop.ai':
      return 'production'
    case 'https://gcp.closedloop.ai':
      return 'production'
    case 'https://internal.closedloop.ai':
      return 'internal'
    case 'https://dev.closedloop.ai':
      return 'development'
    default:
      return 'local'
  }
})()

const ignoredMessages = [
  'axios/lib/core/createError in createError',
  'ResizeObserver loop completed with undelivered notifications.',
  "Cannot read property 'focus' of null",
  'ResizeObserver loop limit exceeded',
  'Request failed with status code 404',
  'Request failed with status code 403',
  'Request failed with status code 401',
  'Request failed with status code 400'
]

const varna = {
  init: () => {
    // Datadog
    if (environment !== 'local') {
      datadog = datadogClient
      datadog.init({
        applicationId: 'fac9c1fa-8138-443e-b4ab-6e8a7848659b',
        clientToken: DatadogToken,
        site: 'datadoghq.com',
        service: 'ui-platform',
        env: environment,
        version: `ui-${AppEnv}-${BuildNumber || 1}`,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        beforeSend: (event) => {
          const isNetworkCall = (event.resource as any)?.type === 'xhr'
          const url = (event.resource as any)?.url as string

          // Ignore xhr calls to launchdarkly and mixpanel
          if (
            isNetworkCall &&
            (url.includes('launchdarkly.com') || url.includes('mixpanel.com'))
          ) {
            return false
          }

          // Don't send specific auth0 library errors
          const auth0Errors = ['Login required']
          if (
            auth0Errors.some((authError) =>
              (event.error as any)?.message?.includes(authError)
            )
          ) {
            return false
          }

          // Ignore any messages listed above
          if (ignoredMessages.includes((event.error as any)?.message)) {
            return false
          }
        }
      })
    }

    // Rollbar
    rollbar = new Rollbar({
      accessToken: '779d8c0e618346ec8fbf17817df8e3b3',
      captureUncaught: true,
      captureUnhandledRejections: true,
      environment,
      ignoredMessages,
      codeVersion: 'ui@' + (BuildNumber || 1),
      scrubTelemetryInputs: true,
      autoInstrument: {
        networkRequestBody: false,
        networkResponseBody: false,
        log: false
      },
      enabled: environment !== 'local',
      filterTelemetry: (e) => {
        const isNetworkCall =
          e.type === 'network' && (e.body.subtype === 'xhr' || e.body.subtype === 'fetch')
        const url = e.body.url as string

        if (
          (isNetworkCall && url.includes('launchdarkly.com')) ||
          (isNetworkCall && url.includes('mixpanel.com'))
        ) {
          return true
        }

        return false
      },
      transform: (data: any) => {
        set(data, 'client.javascript.plugins', [])
        return phiCheck.obscureData(data)
      },
      payload: {
        client: {
          javascript: {
            source_map_enabled: true,
            codeVersion: 'ui@' + (BuildNumber || 1),
            guess_uncaught_frames: true
          }
        }
      }
    })
  },
  setUser: ({ email }: { email: string }) => {
    // Datadog
    datadog?.setUser({ id: email, email })

    // Rollbar
    rollbar?.configure({
      payload: {
        person: { id: email, email }
      }
    })
  },
  info: (message: string, data: Error | object = {}) => {
    // Datadog
    datadog?.addError(message, data)

    // Rollbar
    if (FeatureFlagAccess().sendErrorsToRollbar) {
      rollbar?.info(message, data)
    }
  },
  error: (message: string, data: Error | object = {}) => {
    // Datadog
    datadog?.addError(message, data)

    // Rollbar
    if (FeatureFlagAccess().sendErrorsToRollbar) {
      rollbar?.error(message, data)
    }
  },
  warn: (message: string, data: Error | object = {}) => {
    // Datadog
    datadog?.addError(message, data)

    // Rollbar
    if (FeatureFlagAccess().sendErrorsToRollbar) {
      rollbar?.warn(message, data)
    }
  }
}

// Print Build Number to console
if (environment !== 'local') {
  setTimeout(() => {
    print(
      `%cBuild Number: ${BuildNumber}`,
      'border: 1px solid #888; font-weight: bold; padding: 4px 8px;'
    )
  }, 1000)
}

export default varna
