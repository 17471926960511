import { useMemo } from 'react'
import { createState, Downgraded, useState } from '@hookstate/core'
import { find, get, set, uniqBy } from 'lodash'

import { NavigationAccess } from 'state/NavigationState'
import API from 'providers/API'

import { mapValues } from 'utils/helpers'

// hookState isn't synchronous so we'll point to a global obj for instant truth
const fetchState = {}

const initialState = createState({
  featureTemplates: {},
  fetchState: {}
}).attach(Downgraded)

const stateWrapper = (state) => {
  const wrapper = {
    get fetchedFeatureTemplates() {
      const { psId } = NavigationAccess()
      return !!get(fetchState, `${psId}.fetched`)
    },
    get fetchingFeatureTemplates() {
      const { psId } = NavigationAccess()
      return !!get(fetchState, `${psId}.fetching`)
    },
    get featureTemplates() {
      const { psId } = NavigationAccess()
      const featureTemplates = state.featureTemplates.get()

      if (!psId || !featureTemplates || !featureTemplates[psId]) return []
      return featureTemplates[psId]
    },
    findFeatureTemplate(templateParams: Partial<TrackedFeatureTemplate>) {
      if (typeof templateParams !== 'object') {
        throw new Error('Feature Template State: Params should be an object')
      }

      return find(this.featureTemplates, templateParams)
    },
    async fetchFeatureTemplates({
      psId = NavigationAccess().psId,
      params = {}
    }: { psId?: string; params?: { includeAdHoc?: boolean } } = {}) {
      if (!psId)
        return Promise.reject('Feature Template State: Person Store ID was not specified')

      const cacheFeatureTemplates = !params.includeAdHoc

      try {
        if (cacheFeatureTemplates) {
          set(fetchState, `${psId}.fetching`, true)
          state.fetchState.set(fetchState)
        }

        const res = await API.get(`/content/featureTemplates`, {
          params: { ...params, psId }
        })
        const featureTemplates = enhanceFeatureTemplates(res.data)

        if (cacheFeatureTemplates) {
          set(fetchState, `${psId}.fetched`, true)
          set(fetchState, `${psId}.fetching`, false)
          state.fetchState.set(fetchState)
          state.featureTemplates.merge({ [psId]: featureTemplates })
        }

        return featureTemplates
      } catch (err) {
        if (cacheFeatureTemplates) {
          set(fetchState, `${psId}.failedToFetch`, true)
          set(fetchState, `${psId}.fetching`, false)
          state.fetchState.set(fetchState)
        }
        return Promise.reject(err)
      }
    }
  }
  return mapValues(wrapper, (f) =>
    typeof f === 'function' ? f.bind(wrapper) : f
  ) as typeof wrapper
}

const FeatureTemplateState = () => {
  const state = useState(initialState)
  return useMemo(() => stateWrapper(state), [state])
}
const FeatureTemplateAccess = () => stateWrapper(initialState)
export { FeatureTemplateState as default, FeatureTemplateAccess }

function enhanceFeatureTemplates(templates: TrackedFeatureTemplate[]) {
  return templates.map(({ tags = [], generators = [], ...template }) => {
    const enhancedTags = uniqBy(
      tags.map((tag) => ({
        ...tag,
        id: tag.name,
        standardTag: true
      })),
      'id'
    )

    const enhancedDetails = {
      markdown: get(template, 'details.markdown', '').replace(/\n+$/, ''),
      text: get(template, 'details.text', '').replace(/\n+$/, '')
    }

    const enhancedGenerators = generators.map((gen) => {
      if (gen._derivedType === 'FilterGeneratorTemplateOntology') {
        const selector = (gen.ontologySelector || '').split('/')
        gen.source = gen.source || selector[0]
        gen.level = Number(gen.level || selector[1] || 1)
      }
      return gen
    })

    return {
      ...template,
      tags: enhancedTags,
      details: enhancedDetails,
      generators: enhancedGenerators
    }
  })
}
