import { Component } from 'react'
import PropTypes from 'prop-types'

const ZOOM_PERCENT_CHANGE = 0.01

/* 
  This class may be destroyed and recreated which means you run the risk of attaching
  event listeners multiple times. We store the callback in eventListeners which allows
  us to remove the event if it exists on the element and we reattach it.
*/
const eventListeners = {}

export default class Browser extends Component {
  constructor(props) {
    super(props)

    this.onClickCallbacks = []
    this.resizeCallbacks = []
    this.zoomCallbacks = []
    this.allChangesCallbacks = []
    this.scrollCallbacks = []
    this.visibilityChangeCallbacks = []

    this.windowSize = {
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight
    }

    eventListeners.onClick = this.onBodyClick
    eventListeners.keydown = this.onKeydown
    eventListeners.resize = this.onResizeEvent

    this.attachEventListener(document.body, 'click', eventListeners.onClick)
    this.attachEventListener(window, 'keydown', eventListeners.keydown)
    this.attachEventListener(window, 'resize', eventListeners.resize)
  }

  componentDidMount() {
    this.setupVisibilityChange()
  }

  onBodyClick = (e) => {
    this.onClickCallbacks.forEach((x) => x(e))
  }

  onKeydown = (e) => {
    if (
      e.target === document.body &&
      (e.keyCode === 32 || // Spacebar
        e.keyCode === 38 || // up
        e.keyCode === 40 || // down
        e.keyCode === 33 || // page up
        e.keyCode === 34) // page down
    ) {
      this.scrollCallbacks.forEach((x) => x(e))
    }
  }

  onResizeEvent = () => {
    // Check for resizing
    if (
      window.innerWidth !== this.windowSize.innerWidth ||
      window.innerHeight !== this.windowSize.innerHeight
    ) {
      // update object with current window properties
      this.windowSize.innerWidth = window.innerWidth
      this.windowSize.innerHeight = window.innerHeight

      this.resizeCallbacks.forEach((x) => x())
      this.allChangesCallbacks.forEach((x) => x())
    } else if (
      window.innerWidth + window.innerWidth * ZOOM_PERCENT_CHANGE <
        this.windowSize.innerWidth ||
      window.innerWidth - window.innerWidth * ZOOM_PERCENT_CHANGE >
        this.windowSize.innerWidth
    ) {
      // Check for zooming
      this.windowSize.innerWidth = window.innerWidth
      this.zoomCallbacks.forEach((x) => x())
      this.allChangesCallbacks.forEach((x) => x())
    }
  }

  setupVisibilityChange = () => {
    let hidden, visibilityChange
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden'
      visibilityChange = 'visibilitychange'
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden'
      visibilityChange = 'msvisibilitychange'
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden'
      visibilityChange = 'webkitvisibilitychange'
    }

    eventListeners.visibilityChange = (obj) => {
      const visible = obj.type ? !document[hidden] : obj

      this.visibilityChangeCallbacks.forEach((cb) => {
        if (typeof cb === 'function') {
          cb(visible)
        }
      })
    }

    this.attachEventListener(document, visibilityChange, eventListeners.visibilityChange)

    if (!eventListeners.blurFocus) {
      eventListeners.blurFocus = (e) =>
        eventListeners.visibilityChange(e.type === 'focus')
    }

    this.attachEventListener(window, 'focus', eventListeners.blurFocus)
    this.attachEventListener(window, 'blur', eventListeners.blurFocus)
  }

  attachEventListener = (element, event, callback) => {
    element.removeEventListener(event, callback, false)
    element.addEventListener(event, callback, false)
  }

  buildEvent = (key) => {
    return async (callback) => {
      if (callback && typeof callback === 'function') {
        this[key].push(callback)
      }
    }
  }

  buildRemoveEvent = (key) => {
    return async (callback) => {
      this[key] = this[key].filter((cb) => cb.toString() !== callback.toString())
    }
  }

  detectBrowser = () => {
    const browsers = {
      edge: /Edge\/([0-9._]+)/,
      yandexbrowser: /YaBrowser\/([0-9._]+)/,
      vivaldi: /Vivaldi\/([0-9.]+)/,
      kakaotalk: /KAKAOTALK\s([0-9.]+)/,
      samsung: /SamsungBrowser\/([0-9.]+)/,
      chrome: /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9.]+)(:?\s|$)/,
      firefox: /Firefox\/([0-9.]+)(?:\s|$)/,
      opera: /Opera\/([0-9.]+)(?:\s|$)/,
      ie: /Trident\/7\.0.*rv:([0-9.]+).*\).*Gecko$/,
      ie6: /MSIE\s([0-9.]+);.*Trident\/[4-7].0/,
      ie7: /MSIE\s(7\.0)/,
      bb10: /BB10;\sTouch.*Version\/([0-9.]+)/,
      android: /Android\s([0-9.]+)/,
      ios: /Version\/([0-9._]+).*Mobile.*Safari.*/,
      safari: /Version\/([0-9._]+).*Safari/,
      'ios-webview': /AppleWebKit\/([0-9.]+).*Mobile/
    }

    for (let key in browsers) {
      const regex = browsers[key]
      const match = regex.exec(navigator.userAgent)
      if (match) {
        return key
      }
    }
  }

  onClick = this.buildEvent('onClickCallbacks')
  onChange = this.buildEvent('allChangesCallbacks')
  onResize = this.buildEvent('resizeCallbacks')
  onZoom = this.buildEvent('zoomCallbacks')
  onScroll = this.buildEvent('scrollCallbacks')
  onVisibilityChange = this.buildEvent('visibilityChangeCallbacks')
  removeOnClick = this.buildRemoveEvent('onClickCallbacks')
  removeOnChange = this.buildRemoveEvent('allChangesCallbacks')
  removeOnResize = this.buildRemoveEvent('resizeCallbacks')
  removeOnZoom = this.buildRemoveEvent('zoomCallbacks')
  removeOnScroll = this.buildRemoveEvent('scrollCallbacks')
  removeOnVisibilityChange = this.buildRemoveEvent('visibilityChangeCallbacks')
  isFirefox = () => this.detectBrowser() === 'firefox'
  isChrome = () => this.detectBrowser() === 'chrome'
  isSafari = () => this.detectBrowser() === 'safari'
  isIE = () => this.detectBrowser() === 'ie'

  getChildContext() {
    return {
      browser: {
        onClick: this.onClick,
        onChange: this.onChange,
        onResize: this.onResize,
        onZoom: this.onZoom,
        onScroll: this.onScroll,
        onVisibilityChange: this.onVisibilityChange,
        removeOnClick: this.removeOnClick,
        removeOnChange: this.removeOnChange,
        removeOnResize: this.removeOnResize,
        removeOnZoom: this.removeOnZoom,
        removeOnScroll: this.removeOnScroll,
        removeOnVisibilityChange: this.removeOnVisibilityChange,
        detectBrowser: this.detectBrowser,
        isFirefox: this.isFirefox,
        isChrome: this.isChrome,
        isSafari: this.isSafari,
        isIE: this.isIE
      }
    }
  }

  render() {
    return this.props.children
  }
}

Browser.propTypes = {
  children: PropTypes.any
}

Browser.childContextTypes = {
  browser: PropTypes.object
}
