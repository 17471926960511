import { useEffect, useRef, useState } from 'react'
import { createState, useHookstate } from '@hookstate/core'
import { set } from 'lodash'

import NavigationState from 'state/NavigationState'
import UserPreferencesState from 'state/UserPreferencesState'
import { SetLocation } from 'providers/Router'

import {
  filtersToStatements,
  filterStringToStatements,
  sortsToStatements,
  sortStringToStatements,
  statementsToFilters,
  statementsToFilterString,
  statementsToSorts,
  statementsToSortString,
  statementsToTablesFilters,
  statementsToTablesSorts
} from 'utils/helpers/queryParamTableControls'

// filter syntax: powerbi/sales?filter=Store/Territory eq ('NC', 'SC') and Store/Chain eq ('Fashions Direct')
// or: Store none
// sort syntax: location/resource?sort=TableName/ColumnName asc and TableName/ColumnName desc
// or: TableName none

const CONTROLS_UPDATE_DEBOUNCE_DURATION = 1000

type UseQueryParamTableControlsProps = {
  tableName?: string
  preferenceStatePath?: string
  defaultSort?: Dictionary<SortOrder>
  defaultPageSize?: number
}

type UseQueryParamTableControlsResult = {
  filters: Dictionary<any[]>
  changeFilters: (newFilters: any) => void
  resetFilters: () => void
  sorts: Dictionary<string>
  changeSorts: (newSorts: Dictionary<string>) => void
  saveToPreferences: () => void
  pageIndex: number
  changePageIndex: (newPageIndex: number) => void
  pageSize: number
  changePageSize: (newPageSize: number) => void
}

type FilterUpdate = {
  tableName: string
  changes: Dictionary<string[]>
}

type SortUpdate = {
  tableName: string
  changes: Dictionary<string>
}

type ControlsUpdateBufferState = {
  filterChanges: FilterUpdate[]
  sortChanges: SortUpdate[]
}

const state = createState<ControlsUpdateBufferState>({
  filterChanges: [],
  sortChanges: []
})

const useQueryParamTableControls = ({
  tableName,
  preferenceStatePath,
  defaultSort = {},
  defaultPageSize
}: UseQueryParamTableControlsProps): UseQueryParamTableControlsResult => {
  const stateInstance = useHookstate(state)
  const { params } = NavigationState()
  const [filters, setFilters] = useState<Dictionary<any[]>>({})
  const [sorts, setSorts] = useState<Dictionary<string>>(defaultSort)
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(defaultPageSize ?? 5)
  const previousFilterString = useRef('')
  const previousSortString = useRef('')
  const previousPageIndexString = useRef('')
  const previousPageSizeString = useRef('')
  const setLocation = SetLocation()
  const { getPreference, setPreference } = UserPreferencesState()

  // should initialize to the filters in preference state or the table default sort key
  useEffect(() => {
    if (tableName && !params.filter && !params.sort) {
      if (preferenceStatePath) {
        const prefs = getPreference(preferenceStatePath)

        const hasFilters = !!(
          prefs?.[tableName]?.filters && Object.keys(prefs[tableName].filters).length
        )
        const hasSorts = !!(
          prefs?.[tableName]?.sorts && Object.keys(prefs[tableName].sorts).length
        )

        if (hasFilters) {
          changeFilters(prefs[tableName].filters, true)
        }
        if (hasSorts) {
          changeSorts(prefs[tableName].sorts, true)
        }
        if (hasFilters || hasSorts) return
      }
    }
  }, [params.filter, params.sort])

  // synchronize the url query params with the filters object
  useEffect(() => {
    if (tableName && params.filter !== previousFilterString.current) {
      previousFilterString.current = params.filter

      if (!params.filter) {
        setFilters({})
        return
      }

      const filterString = params.filter

      const statements = filterStringToStatements(filterString)

      const newFilters = statementsToFilters(statements, tableName)

      setFilters(newFilters)
    }
  }, [params.filter, tableName])

  useEffect(() => {
    if (tableName && (params.sort ?? '') !== previousSortString.current) {
      previousSortString.current = params.sort

      if (!params.sort) {
        setSorts(defaultSort)
        return
      }

      const sortString = params.sort

      const statements = sortStringToStatements(sortString)

      const newSorts = statementsToSorts(statements, tableName, defaultSort)

      setSorts(newSorts)
    }
  }, [params.sort, tableName])

  useEffect(() => {
    if (tableName && params.pageIndex !== previousPageIndexString.current) {
      previousPageIndexString.current = params.pageIndex

      if (!params.pageIndex) {
        setPageIndex(0)
        return
      }

      const tablePageIndex = (params.pageIndex as string).match(
        new RegExp(`${tableName} \\d+`, 'g')
      )?.[0]

      if (!tablePageIndex) {
        setPageIndex(0)
        return
      }

      const asNumber = Number(tablePageIndex.match(/\d+$/g)?.[0])

      if (asNumber === pageIndex) return

      setPageIndex(asNumber)
    }
  }, [params.pageIndex, tableName])

  useEffect(() => {
    if (tableName && params.pageSize !== previousPageSizeString.current) {
      previousPageSizeString.current = params.pageSize

      if (!params.pageSize) {
        setPageSize(defaultPageSize ?? 5)
        return
      }

      const tablePageSize = (params.pageSize as string).match(
        new RegExp(`${tableName} \\d+`, 'g')
      )?.[0]

      if (!tablePageSize) {
        setPageSize(defaultPageSize ?? 5)
        return
      }

      const asNumber = Number(tablePageSize.match(/\d+$/g)?.[0])

      if (asNumber === pageSize) return

      setPageSize(asNumber)
    }
  }, [params.pageSize, tableName])

  const changeFilters = (newFilters: Dictionary<any[]>, debounce: boolean = false) => {
    // filter changes and sort changes are handled all together to avoid conflicting browser history updates
    changeFiltersAndSorts(newFilters, undefined, debounce)
  }

  const changeSorts = (newSorts: Dictionary<string>, debounce: boolean = false) => {
    // filter changes and sort changes are handled all together to avoid conflicting browser history updates
    changeFiltersAndSorts(undefined, newSorts, debounce)
  }

  const changeFiltersAndSorts = (
    newFilters?: Dictionary<any[]>,
    newSorts?: Dictionary<string>,
    debounce?: boolean
  ) => {
    if (tableName) {
      if (newFilters)
        stateInstance.filterChanges.merge([{ tableName, changes: newFilters }])
      if (newSorts) stateInstance.sortChanges.merge([{ tableName, changes: newSorts }])

      setTimeout(
        (originalSortChangesCount?: number, originalFilterChangesCount?: number) => {
          if (
            originalSortChangesCount === stateInstance.sortChanges.get()?.length &&
            originalFilterChangesCount === stateInstance.filterChanges.get()?.length
          ) {
            const newControlsParams = mergeControlsChangesToParamStrings(
              params,
              stateInstance.filterChanges.get(),
              stateInstance.sortChanges.get()
            )

            setLocation(window.location.origin + window.location.pathname, {
              params: {
                ...params,
                ...newControlsParams
              },
              replace: true
            })

            stateInstance.batch((s) => {
              s.filterChanges.set([])
              s.sortChanges.set([])
            })
          }
        },
        debounce ? CONTROLS_UPDATE_DEBOUNCE_DURATION : 5,
        stateInstance.sortChanges.get()?.length,
        stateInstance.filterChanges.get()?.length
      )
    }
  }

  const resetFilters = () => {
    changeFiltersAndSorts({}, {})
  }

  const changePageIndex = (newPageIndex: number) => {
    if (tableName) {
      const newPageIndexString = params.pageIndex
        ? [
            ...params.pageIndex
              .split(' and ')
              .filter((s) => !s.includes(`${tableName} `)),
            `${tableName} ${newPageIndex}`
          ].join(' and ')
        : `${tableName} ${newPageIndex}`

      setLocation(window.location.origin + window.location.pathname, {
        params: {
          ...params,
          pageIndex: newPageIndexString
        },
        replace: true
      })
    }
  }

  const changePageSize = (newPageSize: number) => {
    if (tableName) {
      const newPageSizeString = params.pageSize
        ? [
            ...params.pageSize.split(' and ').filter((s) => !s.includes(`${tableName} `)),
            `${tableName} ${newPageSize}`
          ].join(' and ')
        : `${tableName} ${newPageSize}`

      const newPageIndexString = params.pageIndex
        ? [
            ...params.pageIndex
              .split(' and ')
              .filter((s) => !s.includes(`${tableName} `)),
            `${tableName} 0`
          ].join(' and ')
        : `${tableName} 0`

      setLocation(window.location.origin + window.location.pathname, {
        params: {
          ...params,
          pageSize: newPageSizeString,
          pageIndex: newPageIndexString
        },
        replace: true
      })
    }
  }

  const saveToPreferences = () => {
    if (preferenceStatePath && tableName) {
      const pathElements = preferenceStatePath.split('.')

      const stateProperty = pathElements[0]

      const prefStateCopy = JSON.parse(JSON.stringify(getPreference(stateProperty)))

      set(
        prefStateCopy,
        `${
          pathElements.length > 1 ? `${pathElements.slice(1).join('.')}.` : ''
        }${tableName}`,
        { filters, sorts }
      )

      setPreference(stateProperty, prefStateCopy)
    }
  }

  return {
    filters,
    changeFilters,
    resetFilters,
    sorts,
    changeSorts,
    saveToPreferences,
    pageIndex,
    changePageIndex,
    pageSize,
    changePageSize
  }
}

export default useQueryParamTableControls

const mergeControlsChangesToParamStrings = (
  currentParams: Dictionary<string>,
  filterChanges?: FilterUpdate[],
  sortChanges?: SortUpdate[]
): { filter?: string; sort?: string } => {
  const currentFilters = statementsToTablesFilters(
    filterStringToStatements(currentParams.filter)
  )

  const currentSorts = statementsToTablesSorts(sortStringToStatements(currentParams.sort))

  const latestFilterChanges: Dictionary<Dictionary<string[]>> = {}

  const latestSortChanges: Dictionary<Dictionary<string>> = {}

  filterChanges?.forEach((f) => {
    if (Object.entries(f.changes).length === 0) set(latestFilterChanges, f.tableName, {})
    else {
      Object.entries(f.changes).forEach(([columnName, filterValues]) => {
        set(latestFilterChanges, [f.tableName, columnName], filterValues)
      })
    }
  })

  sortChanges?.forEach((f) => {
    if (Object.entries(f.changes).length === 0) set(latestSortChanges, f.tableName, {})
    else {
      Object.entries(f.changes).forEach(([columnName, sortValue]) => {
        set(latestSortChanges, [f.tableName, columnName], sortValue)
      })
    }
  })

  const newFilters = { ...currentFilters, ...latestFilterChanges }

  const newSorts = { ...currentSorts, ...latestSortChanges }

  const newFilterString = statementsToFilterString(
    Object.entries(newFilters).flatMap(([tableName, filters]) =>
      filtersToStatements(filters, tableName)
    )
  )

  const newSortString = statementsToSortString(
    Object.entries(newSorts).flatMap(([tableName, sorts]) =>
      sortsToStatements(sorts, tableName)
    )
  )

  return {
    ...(newFilterString ? { filter: newFilterString } : {}),
    ...(newSortString ? { sort: newSortString } : {})
  }
}
