import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import PropTypes from 'prop-types'

export default class BackArrow extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(color = '#6D6D6D') {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
      <svg width="11px" height="17px" viewBox="0 0 11 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
            <g transform="translate(-355.000000, -372.000000)" fillRule="nonzero" stroke={color} strokeWidth="2">
                <g transform="translate(288.000000, 73.000000)">
                    <g transform="translate(26.000000, 272.000000)">
                        <g transform="translate(0.000000, 19.000000)">
                            <polyline points="50.0403036 9.38512074 43 16.4254243 50.0403036 23.465728"></polyline>
                        </g>
                    </g>
                </g>
            </g>
        </g>
        </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        id={this.props.id}
        className={this.props.className}
        style={this.props.style}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
