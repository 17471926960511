import { get } from 'lodash'

const Print = (...args) => {
  try {
    if (window.console) {
      const print = get(console, 'log')
      print(...args)
    }
  } catch (e) {
    // Do nothing
  }
}

export default Print
