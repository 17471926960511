import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import PropTypes from 'prop-types'

export default class Reorder extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(color = '#D4D4D4') {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
        <svg width="22px" height="14px" viewBox="0 0 22 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <desc>Created with Sketch.</desc>
          <defs></defs>
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <path fill={color} d="M21,12 L1,12 C0.448,12 0,12.448 0,13 C0,13.553 0.448,14 1,14 L21,14 C21.552,14 22,13.553 22,13 C22,12.448 21.552,12 21,12 L21,12 Z M21,6 L1,6 C0.448,6 0,6.448 0,7 C0,7.553 0.448,8 1,8 L21,8 C21.552,8 22,7.553 22,7 C22,6.448 21.552,6 21,6 L21,6 Z M1,2 L21,2 C21.552,2 22,1.553 22,1 C22,0.448 21.552,0 21,0 L1,0 C0.448,0 0,0.448 0,1 C0,1.553 0.448,2 1,2 L1,2 Z"></path>
          </g>
      </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        className={this.props.className}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
