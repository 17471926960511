import { createState, useState } from '@hookstate/core'
import { get, isEqual, isNil } from 'lodash'

import { AuthorizationAccess } from 'state/AuthorizationState'

import { getRotatedCookie, setRotatedCookie, unproxy } from 'utils/helpers'
import { localStorage } from 'utils/storage'

import { AppEnv } from 'v1/Constants'

type IUserPreferences = {
  dataAdapterSettings: { tableSettings?: {} }
  deploymentAccuracySettings: {}
  entitySettings: {}
  evaluateSettings: { dismissedAlerts: {} }
  featureDriftSettings: {}
  featureSettings: { tableSettings: {} }
  lastVisitedPage: undefined
  modelRunSettings: { tableSettings: {} }
  modelSettings: {}
  phfSettings: {}
  populationSettings: {}
  predictorSettings: { tableSettings?: {} }
  userManagementSettings: { tableSettings: {} }
  queryRunSettings: { tableSettings: {} }
  querySettings: {}
  selectedPersonStore: string
  viewingOrganization: undefined
  teamDashboardSettings: {
    modelRunSettings?: { activeRunsSettings?: {}; completedRunsSettings?: {} }
    queueMessageSettings: {}
  }
  deploymentFormSettings: {
    skipNoTriggersModal?: boolean
    skipQueryChangeModal?: boolean
  }
  deploymentViewSettings: {
    skipDeployModal?: boolean
    skipRestoreModal?: boolean
    skipArchiveModal?: boolean
  }
  connectorViewSettings: {
    skipArchiveModal?: boolean
  }
  monitoringSettings?: { deploymentRunSettings?: {} }
  byPersonStore: {}
  acoPredictSettings: { tableSettings?: Record<string, { exclusionFilters: string[] }> }
  providerCostDashboard: { excludedKpis?: string[] }
}

const initialState: IUserPreferences = {
  dataAdapterSettings: { tableSettings: {} },
  deploymentAccuracySettings: {},
  entitySettings: {},
  evaluateSettings: { dismissedAlerts: {} },
  featureDriftSettings: {},
  featureSettings: { tableSettings: {} },
  lastVisitedPage: undefined,
  modelRunSettings: { tableSettings: {} },
  modelSettings: {},
  phfSettings: {},
  populationSettings: {},
  predictorSettings: {},
  userManagementSettings: { tableSettings: {} },
  queryRunSettings: { tableSettings: {} },
  querySettings: {},
  selectedPersonStore: '',
  viewingOrganization: undefined,
  teamDashboardSettings: {
    modelRunSettings: { activeRunsSettings: {}, completedRunsSettings: {} },
    queueMessageSettings: {}
  },
  deploymentViewSettings: {},
  deploymentFormSettings: {},
  connectorViewSettings: {},
  monitoringSettings: { deploymentRunSettings: {} },

  // This cookie contains all preferences that are both user and personStore specific (it's keys are personStore IDs)
  // Current structure: byPersonStore: { [psId]: { predictorsTableSettings: {} } }
  byPersonStore: {},
  // Current structure: acoPredictSettings: { tableSettings: { [patientListName]: { exclusionFilters: string[] }}}
  acoPredictSettings: { tableSettings: {} },
  providerCostDashboard: { excludedKpis: [] }
}
// All user preference settings except byPersonStore are user-specific and stored as browser cookies.
const UserPreferences = createState(initialState)

const getStateFromCookies = () => {
  const settings = {}

  // Extract settings if they exist.
  Object.keys(UserPreferences).forEach((key) => {
    const setting = getRotatedCookie(key)
    if (!isNil(setting)) settings[key] = setting
  })

  return settings
}

const pullLocalState = () => {
  const { uniqueId } = AuthorizationAccess()
  const migrated = getRotatedCookie('migrated')
  const keyPrefix = `cl:${AppEnv}:${uniqueId}:`

  // If this is the first time, migrate the user over to using localStorage
  // TODO: Remove migration at the end of 2023
  if (!migrated) {
    const cookieState = getStateFromCookies()
    for (let key in cookieState) {
      localStorage.setItem(keyPrefix + key, cookieState[key], { encode: true })
      setRotatedCookie(key) // Delete cookie
    }
    setRotatedCookie('migrated', 'true')
  }

  // Extract settings if they exist.
  Object.keys(UserPreferences).forEach((key) => {
    const setting = localStorage.getItem(keyPrefix + key, { decode: true })
    if (!isNil(setting)) UserPreferences[key].set(setting)
  })
}

const UserPreferenceWatcher = Symbol('UserPreferenceWatcher')
function MyStateWatchPlugin() {
  return {
    id: UserPreferenceWatcher,
    init: () => {
      return {
        onSet: (data) => {
          if (!isEqual(data.previous, data.value)) {
            const { uniqueId } = AuthorizationAccess()
            const keyPrefix = `cl:${AppEnv}:${uniqueId}:`

            // A single cookie is used per top-level setting currently
            const topLevelSettingKey = data.path[0]
            localStorage.setItem(
              keyPrefix + topLevelSettingKey,
              data.state[topLevelSettingKey],
              { encode: true }
            )
          }
        }
      }
    }
  }
}

const wrapper = (
  state
): typeof UserPreferences & {
  getPreference: (key: string, defaultValue?: any) => any
  setPreference: (key: string, value: any) => void
} => {
  return {
    ...state,
    getPreference: (key, defaultValue) => {
      return unproxy(get(state, key)) ?? defaultValue
    },
    setPreference: (key, value) => {
      const keys = key.split('.').filter(Boolean)
      const lastKey = keys.pop()

      const parent = keys.reduce((loc, k) => {
        if (!loc[k].ornull) loc[k].set({})
        return loc[k]
      }, state)

      if (lastKey) parent[lastKey].set(value)
    }
  }
}

if (UserPreferences.attach) UserPreferences.attach(MyStateWatchPlugin)

const UserPreferencesState = () => wrapper(useState(UserPreferences))
const UserPreferencesAccess = () => wrapper(UserPreferences)
export { UserPreferencesState as default, UserPreferencesAccess, pullLocalState }
