import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import numeral from 'numeral'

import NavigationState from 'state/NavigationState'
import PopulationState from 'state/PopulationState'
import { Link } from 'providers/Router'

import DisplayPopulation from 'components/DisplayPopulation'
import Pill from 'components/Pill'
import StepTypes, {
  HiddenStepTypes
} from 'components/PopulationStepsBuilder/PopulationStepTypes'
import varna from 'utils/varna'

const PopulationInfo = ({ popId, type }): any => {
  const populationState = PopulationState()
  const { psId } = NavigationState()
  const [latestPop, setLatestPop] = useState<any>({})
  const [pop, setPop] = useState<any>({})

  useEffect(() => {
    if (popId && popId.id !== 'entire-population') {
      populationState.fetchPopulation({ popId }).then(setPop)
      const idOnly: any = { id: popId.id }
      populationState.fetchPopulation({ popId: idOnly }).then(setLatestPop)
    }
    // eslint-disable-next-line
  }, [popId])

  const outdated = get(latestPop, 'version.major') > get(pop, 'version.major')

  if (popId && popId.id === 'entire-population') {
    return <span>{type}Entire Population</span>
  }

  return pop.name ? (
    <div style={{ display: 'flex' }}>
      <DisplayPopulation steps={pop?.steps}>
        {type}
        <Link
          href={`/platform/${psId}/data-prep/populations/${pop?.id}?major=${pop?.version?.major}&minor=${pop?.version?.minor}`}
        >
          {pop.name} v{pop.version.major}.{pop.version.minor}
        </Link>{' '}
        &nbsp;
        {outdated && <Pill color="yellow" label="Outdated" />}
      </DisplayPopulation>
    </div>
  ) : (
    ''
  )
}

const describeStep = (step: any = {}, { includeType = false } = {}): any => {
  const type = step._derivedType
  const str =
    includeType && type && (StepTypes[type] || HiddenStepTypes[type])
      ? `${StepTypes[type] || HiddenStepTypes[type]} - `
      : ''

  if (type === 'Subset' && step.exclude === true) {
    return 'Exclude Subset - ' + step.clExpression
  }

  if (
    ['ExcludeSubset', 'Subset', 'IndexDate', 'TimeSeries', 'IndexTimestamp'].includes(
      type
    )
  ) {
    return str + step.clExpression
  }

  if (type === 'LimitPercent') {
    let params = ''
    if (step.timeSeries) params += 'Time Series, '
    if (step.seed) params += `Seed=${step.seed}, `
    if (step.entity) params += `Entity=[${step.entity}], `
    if (params) return str + params + `Percent=${step.percent * 100}`
    return str + params + `${step.percent * 100}%`
  }

  if (type === 'Limit') {
    let params = ''
    if (step.timeSeries) params += 'Time Series, '
    if (step.seed) params += `Seed=${step.seed}, `
    if (step.entity) params += `Entity=[${step.entity}], `
    if (params) params += 'Count='
    return str + params + `${numeral(step.count).format(',')}`
  }

  if (['SubsetPop', 'ExcludePop'].includes(type)) {
    return <PopulationInfo {...step} type={str} />
  }

  if (type === 'AsEntity') {
    return str + step.entity
  }

  if (type === 'IdList') {
    return str + (step.ids || []).join(', ')
  }

  if (type === 'UpdatedIdList') {
    return str + step.loadDataTransactionId
  }

  if (type === 'IndexDateFixed') {
    return str + step.date
  }

  if (type === 'RecentDataUpdate') {
    return str + step.loadTypeName
  }

  if (type === 'ExcludeSteps') {
    return (
      'Exclude Steps - [' +
      step.steps.map((s) => describeStep(s, { includeType: true })).join(', ') +
      ']'
    )
  }

  if (type) varna.warn(`Unsupported population type: ${type}`)
  return ''
}

export default describeStep
