import React from 'react'
import moment from 'moment'
import numeral from 'numeral'

import { Link } from 'providers/Router'

/*
  Can be accessed at: 

  const locale = useLocale()
  locale.t('program.<key>')
*/

export default {
  inline: {
    adapterMissingAlert: ({ psId }) => (
      <span>
        Before you can configure a program, we need to tell the system where to find your
        program data. <a href="mailto:support@closedloop.ai">Contact Support</a> for
        assistance in setting up the{' '}
        <Link
          href={`/platform/${psId}/data-prep/adapters/create?template=CLX_EvalProgram`}
        >
          CLX_EvalProgram
        </Link>{' '}
        data adapter.
      </span>
    ),
    programDescriptionDescript: 'Describe the goal and scope of the program.',
    programTagsDescript:
      'Tags allow you to categorize or add context to an intervention program.',
    programIdsDescript:
      'Select the Ids associated with the program that you are interested in evaluating and monitoring.',
    programEnrollmentJourneyDescript:
      'Sequentially map milestones indicating how patients considered for participation in the Program are flowing from identification to completion. Milestones are defined using statuses (such as, enrolled and lost to follow up) reported in your intervention program data. Green boxes represent milestones that indicate progress toward the desired outcome (e.g., graduation). One or more milestones must be marked as the end of a desired journey. To rearrange milestones, hover over the name and click the up or down arrow.',
    milestoneDeleteDisabled:
      'The root milestone cannot be deleted if it has more than one path',
    milestoneStatusDescript: 'Select the statuses that represents this milestone.',
    milestoneStatusError: ({ statuses }) =>
      statuses.length
        ? `The program(s) selected to do not include these statuses: ${statuses.join(
            ', '
          )}`
        : '',
    noSuccessfulJourney:
      'At least one milestone must be marked as the end of a desired journey. Select the milestone to mark, toggle the End of Desired Journey checkbox and save the milestone.',
    endOfSuccessfulJourney: 'End of Desired Journey',
    endOfSuccessfulJourneyDescript:
      'This milestone and the milestones that lead up to it will be displayed on the Program’s preview card on the home screen.',
    leftoverStatusModalTitle: 'Your analysis may not include all available statuses.',
    leftoverStatusModalBody: ({ statuses }) => (
      <div>
        The following statuses have not been mapped to an Enrollment Journey milestone:
        <strong>
          <div>{statuses.join(', ')}</div>
        </strong>
      </div>
    ),
    enrollmentJourneyViewTitle: 'Analysis of Episodes Initiated',
    enrollmentJourneyViewDescript:
      'Measure and identify bottlenecks in how effectively patients considered for participation in the intervention program are progressing from identification to program completion. To view participation progress for a specific referral source, select one of the "Show Journey For" options. The unit of analysis here is the episode, i.e., an instance of a patient being considered for participation in the intervention; a patient can have more than one episode.',
    episodeListViewTitle: 'List Preview',
    episodeListViewDescript: ({ milestone }) => (
      <span>
        Edit, filter, preview, and download a table of intervention program episodes for
        the {milestone ? <strong>{milestone}</strong> : 'selected'} milestone.
      </span>
    ),
    episodesMissingAlert: ({ count, psId, programId }) => (
      <span>
        {numeral(count).format('0,0')} episodes are hidden due to their status not being
        mapped to a journey milestone. To map these statuses to a milestone,{' '}
        <Link
          style={{ display: 'inline' }}
          href={`/platform/${psId}/evaluate/programs/${programId}/edit`}
        >
          edit
        </Link>{' '}
        the enrollment journey.
      </span>
    ),
    conflictingEpisodesAlert: ({ count, psId, programId }) => (
      <span>
        {numeral(count).format('0,0')} episodes have conflicting status entries and cannot
        be mapped to a journey milestone. Please{' '}
        <Link style={{ display: 'inline' }} href="mailto:support@closedloop.ai">
          Contact ClosedLoop Support
        </Link>{' '}
        for assistance.
      </span>
    ),
    programStaleMessage: ({ isStale, createdAt }) =>
      `Intervention program data is ${
        isStale ? `outdated. Last computed ${moment(createdAt).fromNow()}` : 'up to date'
      }`,
    archiveProgramMessage: `You can view all archived intervention programs by selecting "Show Archived" on the evaluate page. You can unarchive any intervention program by clicking into that program’s page and then selecting "Unarchive."`,
    unarchiveProgramMessage:
      'This intervention program will be shown on the evaluate page and be automatically refreshed weekly.',
    invalidColumns: ({ columns }) => {
      const colString = 'column' + (columns.length > 1 ? 's' : '')
      const hasString = columns.length > 1 ? 'have' : 'has'

      return (
        <span>
          The {colString} {columns.map((name) => `"${name}"`).join(', ')} {hasString} an
          issue retrieving data and {hasString} been removed.{' '}
          <a
            className="inline underline"
            style={{ color: 'unset', fontWeight: 700 }}
            href="mailto:support@closedloop.ai"
          >
            Contact support
          </a>{' '}
          to discuss options to resolve if you would like to see this data in your table
          view.
        </span>
      )
    }
  }
}
