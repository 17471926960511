import React, { useMemo } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import { noop } from 'lodash'

export interface OvalCopyProps {
  alt: string
  id?: string
  className?: string
  fill?: string
  onClick?: () => void
}

const OvalCopy: React.FC<OvalCopyProps> = ({
  alt,
  id,
  fill = '#232829',
  className,
  onClick = noop
}) => {
  const markup = useMemo(
    () =>
      encodeURIComponent(
        renderToStaticMarkup(
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="10" cy="10" r="10" fill="#DFDFDF" />
            <rect
              x="5.5"
              y="6.96484"
              width="6.05833"
              height="8.53333"
              rx="0.966667"
              stroke={fill}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.50024 6H14.0002C14.0002 6 14.2435 6 14.2435 6.4051V13.0667C14.2435 13.4717 14.0002 13.5 14.0002 13.5H12.7003V14.5333H13.9836C14.6924 14.5333 15.267 13.8767 15.267 13.0667V6.46667C15.267 5.65665 14.6924 5 13.9836 5H9.49197C8.9275 5 8.44815 5.41649 8.27637 5.99524H8.6384H9.00044C9.00024 6 9.30293 6 9.50024 6Z"
              fill={fill}
            />
          </svg>
        )
      ),
    [fill]
  )

  return (
    <img
      alt={alt}
      id={id}
      className={classnames('oval-copy-icon', className)}
      onClick={onClick}
      src={`data:image/svg+xml,${markup}`}
    />
  )
}

OvalCopy.displayName = 'OvalCopy'
export default OvalCopy
