import React, { useRef, useState } from 'react'
import classnames from 'classnames'
import { get } from 'lodash'

import Tooltip from 'components/Tooltip'

import './TruncateText.scss'

export interface TruncateTextProps {
  className?: string
  children: string | string[] | React.ReactNode
  delay?: number
  lines?: number
  style?: React.CSSProperties
  visible?: boolean
}

const TruncateText: React.FC<TruncateTextProps> = ({
  className,
  children,
  delay = 0.6,
  lines,
  style,
  visible = false
}) => {
  const textElementRef = useRef<HTMLDivElement>(null)
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <Tooltip
      className={classnames('truncate-text_v3__portal', className)}
      maxWidth={500}
      minWidth={Math.min(textElementRef.current?.offsetWidth ?? 0, 500)}
      visible={visible || showTooltip}
      onVisibleChange={(bool) => {
        if (isTruncated(textElementRef)) setShowTooltip(bool)
      }}
      overlay={children}
      placement="bottom-start"
      mouseEnterDelay={delay}
    >
      <div
        ref={textElementRef}
        className={classnames('truncate-text_v3', className, {
          'multi-line': !!lines
        })}
        style={{ ...style, WebkitLineClamp: lines }}
      >
        {children}
      </div>
    </Tooltip>
  )
}

function isTruncated(childElement) {
  const el = childElement.current
  if (!el) return

  const c = el.cloneNode(true)
  const styles = window.getComputedStyle(el)
  c.style.cssText = styles.cssText
  const isMultiline = !!c.classList.contains('multi-line')

  if (isMultiline) {
    c.style['-webkit-line-clamp'] = 80
    c.style.width = el.offsetWidth + 'px'
  } else {
    c.style.display = 'inline-block'
    c.style.width = 'auto'
  }
  c.style.visibility = 'hidden'
  document.body.appendChild(c)

  const truncated = isMultiline
    ? c.offsetHeight > el.clientHeight
    : c.offsetWidth > el.clientWidth
  c.remove()
  return truncated
}

TruncateText.displayName = 'TruncateText'
export default TruncateText
