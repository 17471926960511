import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export default class OvalCode extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(color = '#EEEEEE') {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
        <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg">
          <g transform="translate(.5 .792)" fill="none" fillRule="evenodd">
            <circle fill={color} cx="8.962" cy="8.962" r="8.962"/>
            <path fill="#4A4A4A" fillRule="nonzero" d="M8.24 11.42v1.488a.1.1 0 01-.172.07L4.34 9.198a.231.231 0 01-.002-.315l3.729-3.935a.1.1 0 01.172.069v1.635a.2.2 0 01-.055.139l-2.16 2.245 2.16 2.246a.2.2 0 01.055.138zm1.445 0v1.488a.1.1 0 00.17.07l3.729-3.78a.231.231 0 00.002-.315L9.857 4.948a.1.1 0 00-.172.069v1.636c0 .05.02.1.055.137l2.142 2.251-2.142 2.24a.2.2 0 00-.055.139z"/>
          </g>
        </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        className={classnames('oval-code', this.props.className)}
        style={this.props.style}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
