import React from 'react'

// Can be accessed at locale.t('patientHealthForecast.<key>')

export default {
  inline: {
    entityName: ({ entityName }) =>
      `${entityName}${entityName.match(/(_id|_ID|Id|ID)$/) ? '' : ' ID'}`,
    forecasts: 'Patient Health Forecasts',
    configurations: 'Patient Health Forecast Configurations',
    archiveConfiguration:
      'Patient Health Forecasts based on this predictor are no longer available once you archive this configuration. You can unarchive this configuration at any time.',
    unarchiveConfiguration:
      'This configuration is available to users as soon as you unarchive this configuration.',
    restoreConfiguration:
      'The Patient Health Forecast configuration settings are applied to PHFs immediately and alter PHF displays for other users. You can restore this version at any time.',
    selectEntityCharacteristics:
      'Select up to 11 patient characteristics to include in the Patient Health Forecast.',
    selectEntityCharacteristicsError: ({ numberSelected }) => (
      <>
        You have selected {numberSelected}
        <span>/</span>11 patient characteristics.
      </>
    ),
    cohortAbout:
      'Individuals in the Patient Health Forecast population are segmented into cohorts based on their prediction percentile.',
    cohortInstructions:
      'Define names and prediction percentile ranges for each cohort below.',
    cohortPercentileDomainError: 'Prediction percentile must be a value from 0-99.',
    cohortPercentileOrderError: ({ previousName }) =>
      `Prediction percentile must be below the ${previousName} prediction threshold.`,
    searchIndexComputing: ({ fieldNames }) =>
      `Search fields for ${fieldNames.join(
        ', '
      )} are being built. Come back later to use.`,
    noSearchIndex:
      'No search fields have been configured. Contact ClosedLoop support for assistance.',
    noFeaturesSelected: 'No features are included in this PHF configuration.'
  },
  tooltip: {
    forecasts: (
      <span>
        A <b>Patient Health Forecast (PHF)</b> is a prediction report for an individual in
        a given population. An individual can have multiple PHFs, one for each unique
        outcome predicted. Your administrator has configured the prediction percentile
        boundaries for each PHF, which place this individual within a configured cohort
        (for example, “High Risk”) for each PHF.
      </span>
    ),
    configurations: (
      <span>
        <b>Patient Health Forecast Configurations</b> must be available to have Patient
        Health Forecasts. PHFs are prediction reports for individuals in a given
        population.
      </span>
    ),
    percentChangeInPrediction: (
      <span>
        The PHF calculates the <b>percent change in prediction</b> by comparing the
        individual’s most recent prediction percentile to the most recent prediction that
        occurred at least three months prior to the first prediction.
      </span>
    )
  }
}
