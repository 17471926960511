import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import PropTypes from 'prop-types'

export default class Plus extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(color = '#CBCDD1') {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.75 5.25V0H5.25V5.25H0V6.75H5.25V12H6.75V6.75H12V5.25H6.75Z" fill={color}/>
        </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        className={this.props.className}
        style={this.props.style}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
