import React from 'react'
import classnames from 'classnames'

import './SkeletonLoader.scss'

export interface SkeletonLoaderProps {
  minWidth?: number | string
  minHeight?: number | string
  width?: number | string
  height?: number | string
  inline?: boolean
  style?: React.CSSProperties
}

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({
  minWidth,
  minHeight,
  width,
  height,
  inline,
  style
}) => {
  return (
    <div
      className={classnames('skeleton-loader_v3', { inline })}
      style={{
        minWidth: minWidth || width || 100,
        minHeight: minHeight || height || 16,
        width,
        height,
        ...style
      }}
    />
  )
}

SkeletonLoader.displayName = 'SkeletonLoader'
export default SkeletonLoader
