import React, { Fragment, useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import FocusLock, { AutoFocusInside } from 'react-focus-lock'
import { RemoveScroll } from 'react-remove-scroll'
import classnames from 'classnames'
import { noop } from 'lodash'

import Blanket from 'components/Blanket'
import Portal from 'components/Portal'

import ModalBody from './ModalBody'
import ModalFooter, { ActionProps } from './ModalFooter'
import ModalHeader from './ModalHeader'
import './Modal.scss'

type statusValues = 'entering' | 'entered' | 'exiting' | 'exited'
export type WidthNames = 'small' | 'medium' | 'large' | 'x-large' | 'xx-large' | 'full'
enum Widths {
  small = 400,
  medium = 600,
  large = 800,
  'x-large' = 1000,
  'xx-large' = 1200,
  full = '100vw'
}

export interface ModalProps {
  children?: React.ReactNode
  className?: string
  id?: string
  /**
    Buttons to render in the footer
  */
  actions?: Array<ActionProps>
  autoFocus?: boolean
  /**
    Object containing header, footer, body and container components. Components here will be used instead of the defaults.
    - Header: container for the title
    - Footer: container for the actions
    - Body: container for the content. Component ref must return a HTMLElement. See Custom example.
    - Container: wrapper around Header, Body and Footer components.
  */
  components?: {
    Header?: React.ReactNode | (() => React.JSX.Element)
    Footer?: React.ReactNode | (() => React.JSX.Element)
    Body?: React.ReactNode | (() => React.JSX.Element)
    Container?: React.ReactNode | (() => React.JSX.Element)
  }

  zIndex?: number
  /**
    Where scroll behaviour should originate. When `inside` scroll only occurs
    on the modal body. When `outside` the entire modal will scroll within the viewport.
  */
  scrollBehavior?: 'inside' | 'outside'
  closeOnEscapePress?: boolean
  closeOnMaskClick?: boolean
  /**
    Don't display mask behind modal
   */
  mask?: boolean
  title?: React.ReactNode
  visible?: boolean
  width?: number | string | WidthNames
  onClose?: (action?: { action: 'cancel' | undefined }) => void
  onOpen?: () => void
}

const Modal: React.FC<ModalProps> & {
  create: (props: ModalProps) => {
    container: HTMLDivElement
    closeModal: () => void
  }
} = ({
  children,
  className,
  id,
  actions = [],
  autoFocus = false,
  components = {},
  scrollBehavior = 'inside',
  closeOnEscapePress = true,
  closeOnMaskClick = true,
  mask = true,
  title,
  visible,
  width = 'large',
  zIndex = 500,
  onClose = noop,
  onOpen = noop
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const [modalStatus, setModalStatus] = useState<statusValues>('exited')
  const { Container, Header, Body, Footer } = components

  // Keep modal active until it fades out
  useEffect(() => {
    if (isVisible !== visible) {
      // Fade Out
      if (!visible) {
        setModalStatus('exiting')
        setTimeout(() => {
          setIsVisible(false)
        }, 200)
      }
      // Fade In
      else {
        setIsVisible(true)
        setModalStatus('entering')
      }
    } else {
      setTimeout(() => {
        setModalStatus(isVisible ? 'entered' : 'exited')
        if (isVisible) onOpen()
      }, 200)
    }
  }, [visible, isVisible, onOpen])

  useEffect(() => {
    if (closeOnEscapePress) {
      const handleKeyDown = (e) => {
        if (e.code === 'Escape') {
          onClose({ action: 'cancel' })
        }
      }

      window.addEventListener('keydown', handleKeyDown)

      return () => {
        window.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [onClose, closeOnEscapePress])

  const onMaskClick = () => {
    if (closeOnMaskClick) onClose({ action: 'cancel' })
  }

  if (!isVisible) return null

  return (
    <RemoveScroll>
      <Portal zIndex={zIndex}>
        <div id={id} className={classnames('modal_v3', className, modalStatus)}>
          <FocusLock disabled={!isVisible} autoFocus={!!autoFocus}>
            {mask && (
              <Blanket
                isTinted={['entered'].includes(modalStatus)}
                onBlanketClicked={onMaskClick}
              />
            )}
            <div
              className={classnames('modal_v3__dialog-container', scrollBehavior)}
              style={{ width: Widths[width] || width }}
            >
              <div
                className={classnames('modal_v3__dialog', {
                  'custom-container': !!Container
                })}
              >
                <AutoFocusInside>
                  {/* use custom container if available and ignore Header, Body, and Footer */}
                  {Container ? (
                    callable(Container)
                  ) : (
                    <Fragment>
                      {/* use custom header if available */}
                      {Header ? (
                        callable(Header)
                      ) : (
                        <ModalHeader data-autofocus title={title} />
                      )}

                      {/* use custom body if available */}
                      {Body ? callable(Body) : <ModalBody>{children}</ModalBody>}

                      {/* use custom footer if available */}
                      {Footer ? callable(Footer) : <ModalFooter actions={actions} />}
                    </Fragment>
                  )}
                </AutoFocusInside>
              </div>
            </div>
          </FocusLock>
        </div>
      </Portal>
    </RemoveScroll>
  )
}

// This will allow you to create a modal in a JS flow
// To have an action close the modal, set its onClick to 'close'
Modal.create = ({ onClose = noop, ...props }: ModalProps = { visible: true }) => {
  // Create container
  const app = document.getElementById('app')
  const modalContainer = document.createElement('div')
  const root = createRoot(modalContainer)
  app?.appendChild(modalContainer)

  // Hook into onClose to unmount modal and delete container div
  const onCloseFn: ModalProps['onClose'] = (action = { action: undefined }) => {
    action ? onClose(action) : onClose()
    root.unmount()
    modalContainer.remove()
  }

  // Close the Modal on any action taken
  ;(props.actions || []).forEach((action: any) => {
    const onClick = action.onClick || noop
    action.onClick = () => {
      onClick()
      onCloseFn()
    }
  })

  root.render(<Modal {...props} visible onClose={onCloseFn} />)

  return {
    container: modalContainer,
    closeModal: onCloseFn
  }
}
export const create = Modal.create

Modal.displayName = 'Modal'
export default Modal

function callable(Node) {
  return typeof Node === 'function' ? <Node /> : Node
}
