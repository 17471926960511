import React from 'react'

// Can be accessed at locale.t('dataLoad.<key>')

export default {
  tooltip: {
    uploadEvents: (
      <span>
        A list of past data uploads where data was sent to ClosedLoop from the customer.
      </span>
    )
  }
}
