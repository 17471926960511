// This can imported as expected into .ts and .tsx files because they transpile into real JS
// To use these enums as types in declaration files (.d.ts), you will need to use "import * as enums from './enums'" and then declare a module "declare module 'something'". Types from these files will no longer be global and need to be imported when used.

export enum ModelStage {
  Train = 'train',
  Test = 'test',
  Trial = 'trial',
  Staging = 'staging',
  Predict = 'predict',
  QC = 'qc'
}

export enum ModelRunStatus {
  PendingTraining = 'PENDING_TRAINING',
  PendingPreparingData = 'PENDING_PREPARING_DATA',
  PreparingData = 'PREPARING_DATA',
  Manual = 'MANUAL_RUN',
  ManualRunning = 'MANUAL_RUNNING',
  StoringResults = 'STORING_RESULTS',
  Submitted = 'SUBMITTED',
  BatchSubmitted = 'BATCH_SUBMITTED',
  BatchPending = 'BATCH_PENDING',
  BatchRunnable = 'BATCH_RUNNABLE',
  BatchStarting = 'BATCH_STARTING',
  BatchRunning = 'BATCH_RUNNING',
  BatchSucceeded = 'BATCH_SUCCEEDED',
  BatchFailed = 'BATCH_FAILED',
  Succeeded = 'SUCCEEDED',
  Failed = 'FAILED',
  QC = 'QC',
  ManualReview = 'MANUAL_REVIEW',
  Realtime = 'REALTIME'
}

export enum ValidIdFormat {
  EntityIds = 'entityIds',
  NoId = 'noId',
  Id = 'id',
  AutoId = 'autoId',
  CompoundIndex = 'compoundIndex'
}

export enum ValidQueryRunStatus {
  Requested = 'REQUESTED',
  InProgress = 'IN_PROGRESS',
  Succeeded = 'SUCCEEDED',
  Failed = 'FAILED'
}

export enum ValidQueryRunSource {
  UI = 'UI',
  ModelRun = 'modelRun',
  ReportRequest = 'reportRequest',
  DeploymentRun = 'deploymentRun',
  IndexedDataFrameRun = 'indexedDataFrame'
}

export enum ScheduleType {
  DailyAtHourAndMinute = 'DailyAtHourAndMinute',
  WeeklyOnDayAndHourAndMinute = 'WeeklyOnDayAndHourAndMinute',
  MonthlyOnDayAndHourAndMinute = 'MonthlyOnDayAndHourAndMinute',
  MonthlyOnDayOfWeekAndHourAndMinute = 'MonthlyOnDayOfWeekAndHourAndMinute',
  CronSchedule = 'CronSchedule'
}

export enum ConnectorType {
  ClosedLoopS3Connector = 'ClosedLoopS3Connector',
  CustomerS3Connector = 'CustomerS3Connector',
  CustomerBCDAConnector = 'CustomerBCDAConnector',
  CustomerAzureBlobConnector = 'CustomerAzureBlobConnector',
  //A null type to keep the UI running when there's not parity with the backend
  UnimplementedConnector = 'UnimplementedConnector'
}

export enum ConnectorInputType {
  ClosedLoopS3ConnectorInput = 'ClosedLoopS3ConnectorInput',
  CustomerS3ConnectorInput = 'CustomerS3ConnectorInput',
  CustomerBCDAConnectorInput = 'CustomerBCDAConnectorInput',
  CustomerAzureBlobConnectorInput = 'CustomerAzureBlobConnectorInput',
  //A null type to keep the UI running when there's not parity with the backend
  UnimplementedConnectorInput = 'UnimplementedConnectorInput'
}

export enum AuthenticationMethodType {
  ClosedLoopAWSStaticCredential = 'ClosedLoopAWSStaticCredential',
  ClosedLoopDelegatedIAMRole = 'ClosedLoopDelegatedIAMRole',
  CustomerAWSStaticCredential = 'CustomerAWSStaticCredential',
  CustomerDelegatedIAMRole = 'CustomerDelegatedIAMRole',
  CustomerBCDACredential = 'CustomerBCDACredential',
  CustomerSharedAccessSignature = 'CustomerSharedAccessSignature',
  //A null type to keep the UI running when there's not parity with the backend
  UnimplementedAuthenticationMethod = 'UnimplementedAuthenticationMethod'
}

export enum AuthenticationMethodInputType {
  ClosedLoopAWSStaticCredentialInput = 'ClosedLoopAWSStaticCredentialInput',
  ClosedLoopDelegatedIAMRoleInput = 'ClosedLoopDelegatedIAMRoleInput',
  CustomerAWSStaticCredentialInput = 'CustomerAWSStaticCredentialInput',
  CustomerDelegatedIAMRoleInput = 'CustomerDelegatedIAMRoleInput',
  CustomerBCDACredentialInput = 'CustomerBCDACredentialInput',
  CustomerSharedAccessSignatureInput = 'CustomerSharedAccessSignatureInput',
  //A null type to keep the UI running when there's not parity with the backend
  UnimplementedAuthenticationMethodInput = 'UnimplementedAuthenticationMethodInput'
}

//These are ephemeral. We will never receive these from the API, only send them, so we don't need a null case.
export enum CustomerAuthenticationTestInfoType {
  CustomerAWSStaticCredentialTestInfo = 'CustomerAWSStaticCredentialTestInfo',
  CustomerBCDACredentialTestInfo = 'CustomerBCDACredentialTestInfo',
  CustomerDelegatedIAMRoleTestInfo = 'CustomerDelegatedIAMRoleTestInfo',
  CustomerSharedAccessSignatureTestInfo = 'CustomerSharedAccessSignatureTestInfo'
}

export enum ConnectorBCDAUpdateType {
  FullInitial = 'full_initial',
  FullReload = 'full_reload',
  IncrementalScheduled = 'incremental_scheduled',
  IncrementalReactivation = 'incremental_reactivation',
  IncrementalManual = 'incremental_manual',
  PreadjudicatedOnly = 'preadjudicated_only'
}

export enum BCDAUpdateStatusValue {
  RunningBCDAJob = 'Running BCDA Job',
  DownloadingToS3 = 'Downloading BCDA Data to S3',
  IngestingFromS3 = 'Ingesting BCDA Data from S3 to tables',
  Failed = 'Failed',
  Finished = 'Finished',
  Canceled = 'Canceled',
  AuthenticationRejected = 'AuthenticationRejected'
}

export enum DetailsMode {
  Create,
  Edit,
  View
}

export enum DetailsStepType {
  FormInput,
  Results
}
