import React, { cloneElement, useCallback, useEffect, useRef, useState } from 'react'
import {
  flip,
  FloatingPortal,
  offset,
  Placement,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole
} from '@floating-ui/react'
import classnames from 'classnames'
import { noop } from 'lodash'

import TooltipPopup from './TooltipPopup'
import './Tooltip.scss'

type ActionType = 'hover' | 'click'
export type { Placement }

export interface TooltipProps {
  children: React.ReactElement
  className?: string
  disabled?: boolean
  invert?: boolean
  id?: string
  maxWidth?: string | number
  minWidth?: string | number
  mouseEnterDelay?: number
  mouseLeaveDelay?: number
  offsetX?: number
  offsetY?: number
  overlay: (() => React.ReactNode) | React.ReactNode
  overlayStyle?: React.CSSProperties
  placement?: Placement
  trigger?: ActionType | ActionType[]
  visible?: boolean
  useParentContainer?: boolean
  onVisibleChange?: undefined | ((visible: boolean) => void)
}

const Tooltip: React.FC<TooltipProps> = ({
  children,
  className,
  disabled = false,
  id,
  invert = false,
  maxWidth = 250,
  minWidth = 0,
  mouseEnterDelay = 0.2,
  mouseLeaveDelay = 0.1,
  offsetX = 4,
  offsetY = 0,
  overlay,
  overlayStyle = {},
  placement: placementProp = 'right',
  trigger = 'hover',
  useParentContainer,
  visible,
  onVisibleChange = noop
}) => {
  const [open, setOpen] = useState(false)
  const latestParentInfo = useRef('')
  const Parent = useParentContainer ? React.Fragment : FloatingPortal
  const triggers = [trigger].flat()

  // Floating UI does not use x and y offets, it uses mainAxis and crossAxis.
  // Here we decide mainAxis based on the placement of the tooltip.
  const mainAxis =
    placementProp.startsWith('bottom') || placementProp.startsWith('top') ? 'y' : 'x'
  const mainAxisOffset = mainAxis === 'y' ? offsetY : offsetX
  const crossAxisOffset = mainAxis === 'y' ? offsetX : offsetY

  // Setup floating UI
  const { x, y, strategy, context, refs, update } = useFloating({
    placement: placementProp as Placement,
    open: visible || open,
    onOpenChange(newState) {
      const currentState = visible ?? open
      if (newState !== currentState) {
        if (visible === undefined) setOpen(newState)
        onVisibleChange(newState)
      }
    },
    middleware: [
      offset({ mainAxis: mainAxisOffset, crossAxis: crossAxisOffset }),
      flip({ padding: 8 }),
      shift({ padding: 8 })
    ]
  })

  // Watch reference element for interactions
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      enabled: triggers.includes('hover'),
      delay: { open: mouseEnterDelay * 1000, close: mouseLeaveDelay * 1000 },
      handleClose: safePolygon({
        buffer: 50
      })
    }),
    useClick(context, { enabled: triggers.includes('click') }),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context, { referencePress: true })
  ])

  // If the children update, recalculate child size and placement and call
  // update if anything changed
  useEffect(() => {
    const parentEl = refs.reference.current
    if (parentEl && parentEl.getBoundingClientRect) {
      const { top, bottom, left, right } = parentEl.getBoundingClientRect()
      const newParentInfo = `${top}.${bottom}.${left}.${right}`
      if (latestParentInfo.current !== newParentInfo) {
        latestParentInfo.current = newParentInfo
        update()
      }
    }
  }, [children])

  // The reference element may have a ref already defined. We also need a ref
  // tracked in this file. This function will update both when the element changes.
  const updateRefs = useCallback(
    (node) => {
      if (children['ref']) children['ref'].current = node
      refs.setReference(node)
    },
    [refs.setReference]
  )

  // Calculate the space between the hover element and the top of the page.
  // Don't allow floading-ui to position the floater out of bounds
  let top = y ?? 0
  if (refs.reference.current) {
    const rect = refs.reference.current.getBoundingClientRect()
    const maxY = -(document.body.scrollHeight - rect.top + 72)
    top = Math.max(top, maxY)
  }

  return disabled ? (
    children
  ) : (
    <>
      {cloneElement(children, getReferenceProps({ ref: updateRefs, ...children.props }))}
      <Parent>
        {(open || visible) && (
          <div
            {...getFloatingProps({
              ref: refs.setFloating,
              className: classnames(className, 'tooltip_v3'),
              style: {
                position: strategy,
                top: top,
                left: x ?? 0
              }
            })}
          >
            <TooltipPopup
              id={id}
              overlay={overlay as React.ReactNode}
              overlayStyle={overlayStyle}
              invert={invert}
              maxWidth={maxWidth}
              minWidth={minWidth}
            />
          </div>
        )}
      </Parent>
    </>
  )
}

Tooltip.displayName = 'Tooltip'
export default Tooltip
