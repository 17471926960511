import React, { useRef, useState } from 'react'
import classnames from 'classnames'
import { noop } from 'lodash'
import mixpanel from 'utils/mixpanel'

import Tooltip from 'components/Tooltip'
import { uid } from 'utils/helpers'

import CheckmarkIcon from './CheckmarkIcon'
import IndeterminateIcon from './IndeterminateIcon'
import './Checkbox.scss'

export interface CheckboxProps {
  checked?: boolean | undefined
  className?: string
  defaultChecked?: boolean
  disabled?: boolean
  fill?: string
  checkmarkFill?: string
  fullWidth?: boolean
  id?: string
  indeterminate?: boolean
  label?: React.ReactNode
  onChange: (boolean, MouseEvent) => void
  trackingData?: Pick<ITrackerInfo, 'click_box' | 'page_name'>
  triState?: boolean
  tooltip?: React.ReactNode
  useInternalState?: boolean
  'aria-label'?: string
  'aria-describedby'?: string
}

const Checkbox: React.FC<CheckboxProps> = ({
  checked: outerChecked,
  className,
  defaultChecked = false,
  disabled = false,
  fill,
  checkmarkFill = '#fff',
  fullWidth = false,
  id,
  indeterminate = false,
  label = '',
  'aria-label': ariaLabel,
  'aria-describedby': ariaDescribedBy,
  onChange = noop,
  tooltip,
  triState = false,
  useInternalState = false,
  trackingData
}) => {
  const inputID = useRef(uid(6))
  const [innerChecked, setInnerChecked] = useState(defaultChecked && !indeterminate)

  // If checked is undefined, overwrite with defaultChecked
  if (outerChecked === undefined) outerChecked = defaultChecked && !indeterminate

  // Ignore parent's checked value if useInternalState is true
  const checked = useInternalState ? innerChecked : outerChecked

  const onUpdate = (e) => {
    e.preventDefault()
    if (disabled) return

    const bool = !checked
    if (bool && trackingData) mixpanel.track('CTA Clicked', trackingData)
    if (useInternalState) setInnerChecked(bool)

    onChange(bool, e)
  }

  return (
    <Tooltip disabled={!tooltip} placement="top-start" overlay={tooltip}>
      <div
        id={id}
        className={classnames('checkbox_v3', className, {
          checked,
          disabled,
          'full-width': fullWidth,
          'tri-state': triState || indeterminate
        })}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <label htmlFor={`checkbox_${inputID.current}`} onClick={onUpdate}>
          <span
            className="checkbox_v3__container"
            style={{
              backgroundColor: checked ? fill : '',
              borderColor: checked ? fill : ''
            }}
          >
            <input
              type="checkbox"
              checked={checked}
              id={`checkbox_${inputID.current}`}
              onChange={noop}
              disabled={disabled}
              aria-label={ariaLabel}
              aria-describedby={ariaDescribedBy}
            />
            <CheckmarkIcon
              className="checkbox_v3__checkmark"
              alt="Checked"
              fill={checkmarkFill}
            />
            <IndeterminateIcon
              className="checkbox_v3__indeterminate"
              alt="Some are selected"
              fill={checkmarkFill}
            />
          </span>
          <span className="checkbox_v3__label">{label}</span>
        </label>
      </div>
    </Tooltip>
  )
}

Checkbox.displayName = 'Checkbox'
export default Checkbox
