import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import PropTypes from 'prop-types'

export default class OldOvalInfo2 extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(color = '#EEEEEE', selected = false) {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
        <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-587.000000, -1083.000000)">
                  <g transform="translate(288.000000, 72.000000)">
                      <g transform="translate(27.000000, 273.000000)">
                          <g transform="translate(4.000000, 705.000000)">
                              <g transform="translate(268.500000, 33.792070)">
                                  <circle fill={selected ? '#5cb0dd' : color} cx="8.96199497" cy="8.96199497" r="8.96199497"></circle>
                                  <path d="M8.27280158,12.3666739 L9.53685214,12.3666739 L9.53685214,13.6878925 L8.27280158,13.6878925 L8.27280158,12.3666739 Z M6.13217324,7.27871156 C6.13217324,6.36401714 6.39048585,5.62824967 6.90711881,5.07138709 C7.42375177,4.51452451 8.13305278,4.2360974 9.03504312,4.2360974 C9.86927831,4.2360974 10.5372917,4.4742955 11.0391032,4.95069885 C11.5409147,5.4271022 11.7918167,6.03583067 11.7918167,6.77690255 C11.7918167,7.22578037 11.6997134,7.5899588 11.5155041,7.86944876 C11.3312948,8.14893872 10.9597058,8.55969811 10.4007259,9.10173925 C9.99419503,9.49556602 9.73058913,9.82904336 9.60990028,10.1021813 C9.48921144,10.3753192 9.42886792,10.778668 9.42886792,11.3122397 L8.29820963,11.3122397 C8.29820963,10.7066781 8.37019839,10.2186367 8.51417806,9.84810077 C8.65815774,9.47756483 8.97363789,9.05304288 9.46062798,8.57452219 L9.96878901,8.07271317 C10.1212381,7.92873349 10.2440424,7.77840403 10.3372058,7.62172026 C10.5065936,7.34646499 10.5912863,7.06062727 10.5912863,6.76419852 C10.5912863,6.34919827 10.4674233,5.98925448 10.2196935,5.68435633 C9.97196378,5.37945819 9.56226305,5.22701141 8.99057903,5.22701141 C8.28338473,5.22701141 7.79428463,5.48955865 7.52326406,6.014661 C7.37081499,6.30685506 7.28400501,6.72820103 7.26283153,7.27871156 L6.13217324,7.27871156 Z" fill={selected ? '#fff' : '#2A2A2A'}></path>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        className={this.props.className}
        style={this.props.style}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(
          this.props.fill,
          this.props.selected
        )}`}
      />
    )
  }
}
