import React from 'react'
import Apps from 'Apps'

import AuthProvider from 'providers/AuthProvider'
import Browser from 'providers/Browser'
import Router from 'providers/Router'

import ScrollTo from 'v1/providers/ScrollTo'
import 'utils/hideReactMessages'

export default () => (
  <AuthProvider>
    <Browser>
      <ScrollTo>
        <Router>
          <Apps />
        </Router>
      </ScrollTo>
    </Browser>
  </AuthProvider>
)
