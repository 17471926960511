import { useCallback, useEffect, useRef, useState } from 'react'

import {
  injectNavigationData,
  NavigationAccess,
  onNavUpdate
} from 'state/NavigationState'

import { dictionaryToEncodedSearchString } from 'utils/helpers'

import { canNavigate } from './NavigationPrompt'

enum HistoryEvent {
  popstate = 'popstate',
  pushState = 'pushState',
  replaceState = 'replaceState'
}
const events = Object.values(HistoryEvent)

type NavigateObjectType = {
  replace?: boolean
  data?: Dictionary<any>
  params?: Dictionary<any>
  fallbackTo?: string
}
type UseLocationType = [string, (to: any, navigateObject?: NavigateObjectType) => void]
interface PatchedEvent extends Event {
  arguments?: any
}

export default ({ base = '' } = {}): UseLocationType => {
  const [{ path, search }, update] = useState(() => ({
    path: currentPathname(base),
    search: window.location.search
  }))

  const prevHash = useRef(path + search)
  onNavUpdate()

  useEffect(() => {
    const checkForUpdates = () => {
      const pathname = currentPathname(base)
      const search = window.location.search
      const hash = pathname + search

      if (prevHash.current !== hash) {
        prevHash.current = hash
        update({ path: pathname, search })
      }
    }

    events.forEach((e) => window.addEventListener(e, checkForUpdates))
    checkForUpdates()

    return () => events.forEach((e) => window.removeEventListener(e, checkForUpdates))
  }, [base])

  const navigate = useCallback(
    (to, navObject: NavigateObjectType | undefined) => {
      let { replace = false, data, params, fallbackTo } = navObject ?? {}
      const goingBack = typeof to === 'number'

      if (goingBack) {
        const index = to
        const historyStack = NavigationAccess().history
        const historyIndex = historyStack.length - 1 + index
        const hasNoHistory = historyIndex < 0

        if (hasNoHistory && !fallbackTo) return

        to = hasNoHistory ? fallbackTo : historyStack[historyIndex]
        replace = true
        params = undefined
        data = undefined
      }

      const shouldUpdate = canNavigate({
        path: to,
        data,
        params,
        eventType: replace ? HistoryEvent.replaceState : HistoryEvent.pushState
      })

      const paramsString = dictionaryToEncodedSearchString(params)

      if (paramsString) {
        var hashmarkIndex = to.indexOf('#')
        if (hashmarkIndex !== -1) {
          to = to.slice(0, hashmarkIndex)
        }
        to += (to.indexOf('?') === -1 ? '?' : '&') + paramsString
      }

      if (shouldUpdate) {
        injectNavigationData(data)
        window.history[replace ? HistoryEvent.replaceState : HistoryEvent.pushState](
          null,
          '',
          base + to
        )
      }
    },
    // eslint-disable-next-line
    [base]
  )

  return [path, navigate]
}

if (typeof window.history !== 'undefined') {
  for (const type of [HistoryEvent.replaceState, HistoryEvent.pushState]) {
    const original = window.history[type]

    window.history[type] = function () {
      const result = original.apply(this, arguments)
      const event: PatchedEvent = new Event(type)
      event.arguments = arguments

      dispatchEvent(event)
      return result
    }
  }
}

const currentPathname = (base, path = window.location.pathname) =>
  path.slice(base.length) || '/'
