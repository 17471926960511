import React from 'react'

import { Link } from 'providers/Router'

/*
  Can be accessed at: 

  const locale = useLocale()
  locale.t('dataAdapter.<key>')
*/

export default {
  inline: {
    templateWizardCard: { description: 'Implement a ClosedLoop data adapter template' },
    customWizardCard: { description: 'Implement a custom data adapter' }
  },
  tooltip: {
    aggregation: (
      <span>
        The aggregation that will be applied to the combined results returned by the query
        to produce a final value. This will be applied to the selected field values for
        all rows for each entity. For more information on aggregations, see{' '}
        <Link
          href="https://apps.closedloop.ai/docs/cl-expressions/retrieve-expressions.html#agg"
          className="inline"
        >
          Retrieve&nbsp;Expressions documentation
        </Link>
        .
      </span>
    ),
    templateFieldsAllowedValues:
      'Indicates the data values required for accurate calculation of the feature templates contained in the Healthcare Feature Library.'
  }
}
