import React from 'react'

// Can be accessed at locale.t('predictor.<key>')

export default {
  tooltip: {
    about: (
      <span>
        A <b>predictor</b> defines the outcome to be predicted by associated models. A
        predictor can contain one or more models.
      </span>
    ),
    funnelAsOfDateView: (
      <span>
        The <b>As of Date</b> for the example population displayed in the selection steps
        funnel.
      </span>
    ),
    funnelAsOfDateEdit: (
      <span>
        The <b>As of Date</b> used to compute the size of the population in the selection
        steps funnel.
      </span>
    ),
    populationSplit: (
      <span>
        In this option, 80% of individuals in the population are included in the training
        model run, and 20% are included in the test model run.
        <br />
        <br />
        Rather than a fully random 80% / 20% split, this approach uses an intelligent
        grouped, stratified sampling approach. Stratified sampling ensures that the
        proportion of positive events is the same in the train and test set as it is in
        the overall sample. Grouped sampling splits the data based on the primary entity,
        regardless of the entity used for the population. Data for a single patient will
        never be split between the train and test groups, a prime cause of data leakage.
        As an example, in a readmissions model, if a patient has multiple admissions, all
        admissions for that patient will be grouped into either the test or the training
        set.
      </span>
    ),
    trainAsOfDate: (
      <span>
        Train/Test Population:
        <br />
        <br />
        The <b>As of Date</b> for the population used in train and test model runs.
        <br />
        <br />
        Training Population Only:
        <br />
        <br />
        The <b>As of Date</b> for the population used in the training model run.
        <br />
        <br />
        Test Population Only:
        <br />
        <br />
        The <b>As of Date</b> for the population used in the test model run. This must be
        the same as the As of Date used for the training model run.
      </span>
    ),
    clExpression: (
      <span>
        The <b>CL Expression</b> for the outcome.
      </span>
    ),
    type: (
      <span>
        Predictors are classified by the <b>type</b> of outcome variable. Predictors with
        binary outcome variables are classified as "Binary Classification" and predictors
        with continuous outcome variables are classified as "Regression."
      </span>
    ),
    defaultPopulation: (
      <span>
        The <b>default population for trial and predict model runs</b> is used by default
        for new trial and predict model runs that use this predictor.
      </span>
    ),
    usage: (
      <span>
        <b>Predictor usage</b> provides information about the predictor version and model
        used in an associated deployment.
      </span>
    )
  },
  inline: {
    defaultPopulationTitle:
      'Default Population for Trial, Staged, and Production Model Runs',
    defaultPopulationBody:
      'Define steps to select the default population. This population is used by default for all trial, staged, and production model runs for this predictor.'
  }
}
