const phiCheck = {
  urlContainsPHI,
  stringContainsPHI,
  objectContainsPHI,
  arrayContainsPHI,
  obscureData
}

function urlContainsPHI(url: string = window.location.href) {
  const a = window.document.createElement('a')
  a.href = url
  const { pathname } = a

  const hasPersonData = !!pathname.match(/\/person\/\w+$/)
  const hasPatientData = !!pathname.match(/\/patient\/\w+$/)
  const hasEntityData = !!pathname.match(/\/entity\/\w+$/)

  return hasPersonData || hasPatientData || hasEntityData
}

function stringContainsPHI(str: string) {
  // search for different format if url
  if (str.startsWith('http')) return urlContainsPHI(str)

  // Check for IdList from python code
  if (str.match(/IdList\(.+\)/)) return true

  // Check for example sql code that includes ids
  if (str.includes('where personId in (')) return true

  return false
}

function objectContainsPHI(obj: object) {
  for (let key of Object.keys(obj)) {
    const value = obj[key]

    // If value is an object, recursively check each of its keys
    if (value?.constructor === Object) {
      if (objectContainsPHI(value)) return true
    }

    // If value is an array, check each item
    if (Array.isArray(value)) {
      if (arrayContainsPHI(value)) return true
    }

    // If value is a string
    if (typeof value === 'string') {
      if (stringContainsPHI(value)) return true
    }

    // if dfQuery object
    if (key === 'predObjId') return true

    return false
  }
}

function arrayContainsPHI(arr: any[]) {
  for (let value of arr) {
    // If item is string
    if (typeof value === 'string') {
      if (stringContainsPHI(value)) return true
    }

    // If item is another array
    if (Array.isArray(value)) {
      if (arrayContainsPHI(value)) return true
    }

    // If item is an object, check the keys
    if (value?.constructor === Object) {
      if (objectContainsPHI(value)) return true
    }
  }

  return false
}

function obscureData(data: string | object | any[]) {
  // If data is a string
  if (typeof data === 'string') {
    // If data is a url
    if (data.startsWith('http')) {
      if (urlContainsPHI(data)) {
        return data
          .replace(/patient\/\w+/g, 'patient/*********')
          .replace(/person\/\w+/g, 'person/*********')
          .replace(/entity\/\w+/g, 'entity/*********')
      }
    }

    // clear any other text that contains PHI
    else if (stringContainsPHI(data)) {
      return '****redacted****'
    }
  }

  // If array, obscure each item in the array
  if (Array.isArray(data)) {
    return data.map(obscureData)
  }

  // If object, check keys and values for PHI
  if (data?.constructor === Object) {
    return Object.keys(data).reduce((prev, key) => {
      const res = { ...prev }

      if (key === 'predObjId') res[key] = '****redacted****'
      if (key === 'subtype' && data[key] && data[key] === 'input' && data['value']) {
        data['value'] = '****redacted****'
      }
      res[key] = obscureData(data[key])

      return res
    }, {})
  }

  return data
}

export default phiCheck
