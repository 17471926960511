import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export default class Cog extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill() {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
        <svg width="24px" height="23px" viewBox="0 0 24 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-1005.000000, -647.000000)" fill="#FF4E4E">
                  <g transform="translate(288.000000, 72.000000)">
                      <g transform="translate(32.000000, 257.000000)">
                          <g>
                              <g transform="translate(32.000000, 164.000000)">
                                  <g transform="translate(0.000000, 25.000000)">
                                      <g>
                                          <g transform="translate(620.000000, 3.000000)">
                                              <path d="M50.1569256,141.575886 C51.4949642,140.237848 52.3194123,138.386218 52.3194123,136.345371 C52.3194123,134.304525 51.4949642,132.452895 50.1569256,131.114857 C48.8188869,129.776818 46.9672576,128.95237 44.9264108,128.95237 C42.8855639,128.95237 41.0339347,129.776818 39.695896,131.114857 C38.3578574,132.452895 37.5334093,134.304525 37.5334093,136.345371 C37.5334093,138.386218 38.3578574,140.237848 39.695896,141.575886 C41.0339347,142.913925 42.8855639,143.738373 44.9264108,143.738373 C46.9672576,143.738373 48.8188869,142.913925 50.1569256,141.575886 Z M45.9265609,139.197151 C45.9265609,138.643014 45.480548,138.197001 44.9264108,138.197001 C44.3722736,138.197001 43.9127451,138.643014 43.9127451,139.197151 C43.9127451,139.751288 44.3722736,140.210817 44.9264108,140.210817 C45.480548,140.210817 45.9265609,139.751288 45.9265609,139.197151 Z M44.9264108,137.183335 C44.5614912,137.183335 44.2911803,136.885993 44.2506337,136.507558 L43.9127451,133.493592 C43.858683,132.966486 44.4128202,132.479926 44.9264108,132.479926 C45.4400014,132.479926 45.9941386,132.966486 45.9265609,133.493592 L45.6021879,136.507558 C45.5616413,136.885993 45.2913304,137.183335 44.9264108,137.183335 Z"></path>
                                          </g>
                                      </g>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
        </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        id={this.props.id}
        className={classnames('oval-error-icon', this.props.className)}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill()}`}
      />
    )
  }
}
