const ROTATION_AMOUNT = 2
const MAX_CHAR_TO_ROTATE = 126

/**
 * Obfuscate a given string with a rotation algorithm similar to rot13 cipher.
 * @param {String} str  string to be rotated
 * @param {Number} ra   rotation amount
 */

function rotateString(
  str: string = '',
  ra: number = ROTATION_AMOUNT,
  reverse: boolean = false
) {
  const chars = str.split('')

  for (let i = 0; i < chars.length; i++) {
    const c = chars[i].charCodeAt(0)

    if (c <= MAX_CHAR_TO_ROTATE) {
      const rotate = i % 2 ? ra + (reverse ? -1 : 1) : ra
      chars[i] = String.fromCharCode(
        (chars[i].charCodeAt(0) + rotate) % MAX_CHAR_TO_ROTATE
      )
    }
  }

  return chars.join('')
}

/**
 * De-obfuscate a given string with a rotation algorithm similar to rot13 cipher.
 * @param {String} str  string to be rotated
 * @param {Number} ra   rotation amount
 */

function unRotateString(str: string = '', ra: number = ROTATION_AMOUNT) {
  return rotateString(str, MAX_CHAR_TO_ROTATE - ra, true)
}

export { rotateString, unRotateString }
