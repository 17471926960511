/* 
  React shows a lot of warnings and errors for 3rd party dependencies that are in no rush to update their code.
  To hide some of the noise, we can hide a few messages here that are innocuous

  **** IMPORTANT ****
  With each hidden message, add why it should be hidden.
  This should not be used to procrastinate fixing bugs. This is only for messages that we can't fix on our own because it belongs to a dependency.
*/

const origConsoleWarn = window.console.warn
const origConsoleError = window.console.error

window.console.warn = (...args) => {
  const message = args[0]

  // colors-convert has deprecated a number of functions and even though we aren't using them, we receive a
  // warning about them every time we use the library. version 2.0 has yet to be released and they have not given an ETA
  if (
    message.includes(
      'has been deprecated since version 1.3.0 and will be deleted since version 2.0'
    )
  ) {
    return
  }

  origConsoleWarn(...args)
}

window.console.error = (...args) => {
  const message = args[0]

  // If two components are using the same HookState at the same time and the HookState updates, it upsets React that both components
  // try to update at the same time. Facebook's Recoil library is also facing this issue. I imagine they'll remove this warning from
  // future React versions since React is moving away from the "passsing changes down" pattern
  if (
    message ===
    'Warning: Cannot update a component (`%s`) while rendering a different component (`%s`). To locate the bad setState() call inside `%s`, follow the stack trace as described in https://reactjs.org/link/setstate-in-render%s'
  ) {
    return
  }

  origConsoleError(...args)
}
