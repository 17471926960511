import React from 'react'

// Can be accessed at locale.t('modelValidation.<key>')

export default {
  inline: {
    noModelsSelected: 'Please select a model to view validation statistics.'
  },
  tooltip: {
    about: (
      <span>
        <b>Validation</b> is the process of evaluating the accuracy and performance of
        machine learning models.
      </span>
    ),
    errorDistribution: (
      <span>
        Individual prediction errors for patients or entities in the model run population
        are calculated by the difference between actual and predicted values. The{' '}
        <b>error distribution</b> graph plots the distribution of these errors to show how
        likely each error difference is.
      </span>
    ),
    modelFeatureVersions: (
      <span>
        This column shows what version of each feature used in the model. Within the
        parentheses, the column header indicates the total number of features used in the
        model. The total number of features used in the model include single and generator
        features as well as features from reference data.
      </span>
    ),
    noModelRunAvailable: ({ stage = '' }) =>
      `This model does not have a ${stage === 'train' ? 'training' : stage} run.`,
    noModelRunAvailableAlert: ({ stage = '' }) =>
      `A model you previously selected does not have a ${
        stage === 'train' ? 'training' : stage
      } run. Validation statistics are unavailable for that model.`,
    modelRunHasFailed: ({ stage = '' }) =>
      `This model's latest ${
        stage === 'train' ? 'training' : stage
      } run has failed. Validation statistics are unavailable.`,
    modelRunIsProcessing: ({ stage = '' }) =>
      `${
        stage === 'train' ? 'Training' : 'Test'
      } run for this model is in progress. Validation statistics are not available yet.`,
    outcomeCaptureCurve: (
      <span>
        The <b>Outcome Capture Curve (OCC)</b> shows the percentage of a particular
        outcome captured correctly by the model among individuals who had a prediction
        value in the top N% percent of predictions for the population.
      </span>
    ),
    populationStratification: (
      <span>
        <b>Population stratification</b> displays the distribution of prediction values
        among the model run population.
      </span>
    ),
    positiveImpact: (
      <span>
        The percent of the sum of total positive Shapley values. This metric is shown for
        each of the 25 top contributing factors in this model. It only includes positive
        Shapley values, or factors that increase the prediction value.
        <br />
        <br />A{' '}
        <a
          style={{ display: 'inline' }}
          href="https://ieeexplore.ieee.org/document/9117109"
          rel="noopener noreferrer"
          target="_blank"
        >
          Shapley value
        </a>{' '}
        is the average marginal contribution to the model under all possible feature
        combinations.
      </span>
    ),
    PRCurve: (
      <span>
        The <b>Precision-Recall (PR) Curve</b> shows the tradeoff between precision and
        recall across different probability thresholds. Precision is the proportion of
        cases identified as positive that were actual positives. Recall is the proportion
        of actual positive cases identified correctly.
      </span>
    ),
    reachedMaximumModelsSelected: 'Only four models can be compared at a time',
    ROCCurve: (
      <span>
        The <b>Receiver Operating Characteristic (ROC)</b> curve summarizes the trade-off
        between the True Positive Rate and False Positive Rate at different probability
        thresholds. The <b>True Positive Rate</b>, or sensitivity, is the proportion of
        actual positives that were correctly identified as positive. The{' '}
        <b>False Positive Rate</b>, or specificity, is the proportion of actual negative
        cases that were incorrectly identified as positive. <br />
        <br />
        The dotted 45-degree line is the ‘flip a coin’ line which is akin to making a
        prediction by tossing of an unbiased coin.
      </span>
    ),
    topContributingFactors: (
      <span>
        The <b>top contributing factors</b> provide insight as to which features in the
        model contributed most to increased prediction values among the population.
      </span>
    )
  }
}
