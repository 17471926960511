import React from 'react'
import classnames from 'classnames'
import { noop } from 'lodash'

const Underlined: React.FC<Omit<IconProps, 'alt'> & { children: React.ReactNode }> = ({
  children,
  id,
  fill = '#919495',
  className,
  onClick = noop
}) => {
  return (
    <span
      id={id}
      className={classnames('underlined', className)}
      style={{
        textDecoration: `underline dotted ${fill}`,
        textUnderlineOffset: '25%',
        textDecorationThickness: '13%'
      }}
      onClick={onClick}
    >
      {children}
    </span>
  )
}

Underlined.displayName = 'Underlined'
export default Underlined
