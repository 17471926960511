import React from 'react'
import { createRoot } from 'react-dom/client'
import registerServiceWorker from 'serviceWorkerRegistration'
import mixpanel from 'utils/mixpanel'

import varna from 'utils/varna'

import Index from './index2'

varna.init()
mixpanel.init()
const root = createRoot(document.getElementById('root'))
root.render(<Index />)

registerServiceWorker()
