import React from 'react'

import { Link } from 'providers/Router'

import { Tag } from 'components/Tags'

// Can be accessed at locale.t('feature.<key>')

export default {
  inline: {
    joinReferenceDataDescription: `Features from reference data are used to create features for one or more columns in a reference data table.`,
    description: 'Brief description of the logic this feature implements.',
    includeByDefault: 'Include this feature in new models by default',
    featureNameConfiguration: `The string used to generate names for the features created by this generator.  These names will be used in contributing factors. For array generators, the index value is automatically appended to the end of the feature name configuration. For struct generators, the data adapter field name(s) included in the CL Expression is automatically appended to the end of the feature name configuration.`,
    featureDescriptionConfiguration: `The string used to generate descriptions for the features created by this generator.`,
    templateWizardCard: { description: 'Implement a ClosedLoop feature template' },
    customWizardCard: { description: 'Implement a custom feature' },
    bulkFeatureRecommendationTitle:
      'Do you want to implement the recommended versions of the features you selected?',
    bulkFeatureRecommendationOption:
      'Yes, implement only the recommended versions of the features selected',
    bulkFeatureRecommendationOptionDescription:
      'Based on the data you have available, we recommend only implementing the versions of the selected features that use a more precise calculation logic.',
    bulkFeatureAllOption: 'No, implement all feature versions selected',
    bulkFeatureAllOptionDescription:
      'These include both recommended and the less precise versions.',
    bulkFeatureCreateTitle: ({ count }) =>
      count > 1
        ? `Are you sure you want to implement these ${count} features?`
        : 'Are you sure you want to implement 1 feature?'
  },
  tooltip: {
    available: 'Feature template is ready to be implemented in this person store.',
    missingRequired: `This feature template uses a data adapter field that is not implemented in your person store. You can add the required field to your data adapter, or you can modify the CL Expression to use a different data adapter field while implementing this feature.`,
    implementedOutdated: `An outdated version of the feature template is currently implemented in the person store. ClosedLoop recommends reimplementing these features.`,
    implementedUpToDate: `Feature template is currently implemented in the person store and uses the latest version.`,
    includeByDefault: `Identifies features ClosedLoop recommends including in new models by default.`,
    disabledInfoModalContinue:
      'Because you have multiple features selected, you cannot continue to implement this particular feature. To implement features, close out of this window and then click continue in the Healthcare Feature Library.',
    templateModalDetails:
      'Detailed description of the logic implemented in the feature or other background information.',
    templateModalClExpression: 'The CL Expression for the feature.',
    templateModalType:
      'Indicates the type of feature. A Single feature generates one feature when implemented. A Generator feature generates multiple features when implemented, based on the filters selected. A feature from Reference Data may generate multiple features when implemented, based on the reference data table column(s) selected.',
    templateModalJoinTable:
      'The reference data table to join to the result of your CL Expression.',
    templateModalJoinColumn: 'The column in the reference data table to join against.',
    templateModalColumnsToInclude:
      'The column(s) in the reference data table which should be included. One feature will be created per column included.',
    templateModalStrategyToFillNullData:
      'The mechanism to fill columns defined in "Columns to Include" if values are null. "Fill Forward" will fill the null value with the next non-null value according to sort. "None" will leave the value as null.',
    templateModalGenerator:
      'A Generator feature generates multiple features when implemented, based on the filters selected.',
    templateModalNumberOfFeatures:
      'Indicates the number of features generated by this feature.',

    tagsFilter: (
      <>
        <p>
          ClosedLoop tags classify features by their content and the type of model they
          can be used for. For more information on tags, see our{' '}
          <span>
            <Link
              href="/docs/data-prep/cl-healthcare-feature-lib.html?hl=tags"
              style={{ display: 'inline' }}
            >
              documentation.
            </Link>
          </span>
        </p>
        <div>
          Tip: Building a new model for Emergency Room High Utilizers or Unplanned
          Hospitalization? Select{' '}
          <Tag name="ERPredictor" color="rgb(32, 125, 186)" style={{ margin: 0 }} /> or
          <Tag
            name="AdmissionPredictor"
            color="rgb(32, 125, 186)"
            style={{ margin: 0 }}
          />{' '}
          from the dropdown to quickly find the research-based features relevant to your
          model.
        </div>
      </>
    ),
    type: (
      <span>
        Indicates the <b>type</b> of feature. A Single feature generates one feature when
        implemented. A Generator feature generates multiple features when implemented,
        based on the filters selected. A feature from Reference Data may generate multiple
        features when implemented, based on the reference data table column(s) selected.
      </span>
    ),
    typeFeatureModal: (
      <span>
        Indicates the <b>type</b> of feature. A Single feature generates one feature when
        implemented. Generator features and features created from Reference Data are not
        supported at this time.
      </span>
    ),
    dataType: (
      <span>
        Indicates the <b>type</b> of data generated by the feature.
      </span>
    ),
    dataTypeMissing: 'Data type is not available for ad hoc features.',
    numberOfFeatures: (
      <span>
        Indicates the <b>number of features</b> generated by the feature. This number is
        always '1' if the feature is of type "single".
      </span>
    ),
    numberOfFeaturesMissing:
      'Number of features generated is not available for ad hoc Generator features.',
    clExpression: 'The CL Expression for the feature.',
    entity: 'The entity this feature is applied to.',
    joinTable: 'The reference data table to join to the result of your CL Expression.',
    joinColumn: 'The column in the reference data table to join against.',
    columnsToInclude:
      'The column(s) in the reference data table which should be included. One feature will be created per column included.',
    orderBy:
      'The columns which should be used to sort the results and resolve duplicate values.',
    fillStrategy: `The mechanism to fill columns defined in "Columns to Include" if values are null. "Fill Forward" will fill the null value with the next non-null value according to sort. "None" will leave the value as null.`,
    filterGenerator:
      'This generator creates multiple features per the filter conditions defined.',
    arrayGenerator: (
      <span>
        If the CL expression returns a list, this generator will create one feature per
        item in the list.
        <br />
        <br />
        Array generators can be used for natural language embeddings. In Natural Language
        Processing (NLP), an embedding is a numeric vector that is created from a piece of
        natural language text. Once the text is converted to a numeric vector through the
        embedding, it can be used just like any other numeric feature in a machine
        learning model.
      </span>
    ),
    structGenerator:
      'If the CL Expression returns a struct object, this generator will create one feature per field specified in the struct object.',
    versionMissing: 'Ad hoc features are not versioned.'
  }
}
