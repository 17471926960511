import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export default class Open extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(color = '#8E8E8E') {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
        <svg width="24" height="23" xmlns="http://www.w3.org/2000/svg">
          <g transform="translate(-1020 -318)" fill={color} fillRule="nonzero">
            <path d="M1026.4 322.3c-.4 0-.7.3-.7.7v11c0 .4.3.7.7.7h11c.4 0 .7-.3.7-.7v-11c0-.4-.3-.7-.7-.7h-11zm0-1.3h11a2 2 0 012 2v11a2 2 0 01-2 2h-11a2 2 0 01-2-2v-11c0-1.1.9-2 2-2zm4.8 9l-.6-.7a.3.3 0 010-.5l4.2-4.1h-2.4a.3.3 0 01-.3-.3v-.9c0-.2.1-.3.3-.3h4.4c.2 0 .4.2.4.4v4.5c0 .1-.1.3-.3.3h-.9a.3.3 0 01-.3-.3v-2.4l-4 4.2a.3.3 0 01-.5 0z"/>
          </g>
        </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        id={this.props.id}
        className={classnames('icon-open', this.props.className)}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
