import { AppEnv } from 'v1/Constants'

export const ONE_MINUTE = 60 * 1000
export const TWENTY_MINUTES = ONE_MINUTE * 20
export const THIRTY_MINUTES = ONE_MINUTE * 30
export const ONE_HOUR = THIRTY_MINUTES * 2
export const TWO_HOURS = ONE_HOUR * 2
export const ONE_DAY = ONE_HOUR * 24

export const REDIRECT_KEY = AppEnv + '_redirectUrlAfterLogin'
