import Cookies, { CookieAttributes } from 'js-cookie'
import { isNil } from 'lodash'

import { rotateString } from 'utils/helpers'
import varna from 'utils/varna'

import generateCookieKey from './generateCookieKey'

export function setRotatedCookie(
  key: string,
  value?: string | object,
  options?: CookieAttributes
) {
  const cookieKey = generateCookieKey(key)

  if (isNil(value)) {
    Cookies.remove(cookieKey)
  } else {
    const rotatedValue = rotateString(JSON.stringify(value))

    // Check and warn of cookie size if getting too big
    const cookieSize = encodeURI(rotatedValue).split(/%..|./).length - 1
    if (3000 < encodeURI(rotatedValue).split(/%..|./).length - 1) {
      varna.warn(
        `Warning: A user preferences cookie is approaching the maximum size of 4096 bytes, current size: ${cookieSize}`
      )
    }

    Cookies.set(cookieKey, rotatedValue, options)
  }
}
