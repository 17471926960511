class Storage {
  memory = {}
  storage = window.localStorage

  constructor(options: { type: 'local' | 'session' }) {
    if (options.type === 'session') {
      this.storage = window.sessionStorage
    }
  }

  isSupported() {
    try {
      const testKey = '__test_key__'
      this.storage.setItem(testKey, testKey)
      this.storage.removeItem(testKey)
      return true
    } catch (e) {
      return false
    }
  }

  getItem(key: string, options?: { decode?: boolean }) {
    let value

    if (this.isSupported()) {
      const data = this.storage.getItem(key)
      if (data) {
        try {
          value = JSON.parse(data)
        } catch (e) {
          value = data
        }
      }
    } else if (key in this.memory) {
      value = this.memory[key]
    }

    if (value) {
      if (value.expires) {
        if (value.expires > Date.now()) {
          return options?.decode ? decodeURIComponent(atob(value.value)) : value.value
        }
        this.removeItem(key)
        return null
      }

      if (options?.decode) {
        try {
          return JSON.parse(decodeURIComponent(atob(value)))
        } catch (e) {
          return atob(value)
        }
      }
      return value
    }

    return null
  }

  key(index: number) {
    if (this.isSupported()) {
      return this.storage.key(index)
    } else {
      return Object.keys(this.memory)[index] || null
    }
  }

  keys() {
    if (this.isSupported()) {
      return Object.keys(this.storage)
    } else {
      return Object.keys(this.memory)
    }
  }

  removeItem(key: string) {
    if (this.isSupported()) {
      this.storage.removeItem(key)
    } else {
      delete this.memory[key]
    }
  }

  // Expires is set to days to copy js-cookie
  setItem(
    key: string,
    value: string | object,
    options?: { expires?: number; encode?: boolean }
  ) {
    const valueAsString = typeof value === 'string' ? value : JSON.stringify(value)
    const valueToStore = options?.encode
      ? btoa(encodeURIComponent(valueAsString))
      : valueAsString

    const expiration = options?.expires
      ? Date.now() + options.expires * 24 * 60 * 60 * 100
      : undefined

    if (this.isSupported()) {
      const data = expiration
        ? { expires: expiration, value: valueToStore }
        : valueToStore
      this.storage.setItem(key, typeof data === 'string' ? data : JSON.stringify(data))
    } else {
      const memoryValue = options?.encode ? valueToStore : value
      this.memory[key] = expiration
        ? { expires: expiration, value: memoryValue }
        : memoryValue
    }
  }
}

export const localStorage = new Storage({ type: 'local' })
export const sessionStorage = new Storage({ type: 'session' })
