import React, { CSSProperties, useMemo } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import { noop } from 'lodash'

export interface CopyProps {
  alt: string
  id?: string
  className?: string
  fill?: string
  onClick?: () => void
}

const Copy: React.FC<CopyProps> = ({
  alt,
  id,
  fill = '#232829',
  className,
  onClick = noop
}) => {
  const markup = useMemo(
    () =>
      encodeURIComponent(
        renderToStaticMarkup(
          <svg
            width="18"
            height="18"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1"
              y="3.05859"
              width="8.64706"
              height="10.9412"
              rx="1.5"
              stroke={fill}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.2163 1.42493H12.8815C13.3284 1.42493 13.6908 1.83903 13.6908 2.34985V10.6742C13.6908 11.185 13.3284 11.5991 12.8815 11.5991H11.2629V12.524H12.8815C13.7754 12.524 14.5001 11.6958 14.5001 10.6742V2.34985C14.5001 1.32821 13.7754 0.5 12.8815 0.5H7.2163C6.50436 0.5 5.89978 1.0253 5.68311 1.75526H6.13973H6.59635C6.74481 1.5533 6.96744 1.42493 7.2163 1.42493Z"
              fill={fill}
            />
          </svg>
        )
      ),
    [fill]
  )

  return (
    <img
      alt={alt}
      id={id}
      className={classnames('copy-icon', className)}
      onClick={onClick}
      src={`data:image/svg+xml,${markup}`}
    />
  )
}

Copy.displayName = 'Copy'
export default Copy
