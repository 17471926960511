const isChildOfClass = (
  childNode: HTMLElement | EventTarget,
  parentClass: string
): boolean => {
  let el: any = childNode
  let childOfParent = false

  while (!childOfParent && el && el !== document.body) {
    childOfParent = el.classList.contains(parentClass)
    el = el.parentElement || document.body
  }
  return childOfParent
}

export default isChildOfClass
