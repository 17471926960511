import React, { useMemo } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import { noop } from 'lodash'

export interface DragHandleProps {
  alt: string
  id?: string
  className?: string
  onClick?: () => void
}

const DragHandle: React.FC<DragHandleProps> = ({
  alt,
  id,
  className,
  onClick = noop
}) => {
  const markup = useMemo(
    () =>
      encodeURIComponent(
        renderToStaticMarkup(
          <svg
            width="6"
            height="10"
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="1" cy="1" r="1" fill="#232829" />
            <circle cx="1" cy="5" r="1" fill="#232829" />
            <circle cx="5" cy="1" r="1" fill="#232829" />
            <circle cx="5" cy="5" r="1" fill="#232829" />
            <circle cx="1" cy="9" r="1" fill="#232829" />
            <circle cx="5" cy="9" r="1" fill="#232829" />
          </svg>
        )
      ),
    []
  )

  return (
    <img
      alt={alt}
      id={id}
      className={classnames('drag-handle-icon', className)}
      onClick={onClick}
      src={`data:image/svg+xml,${markup}`}
    />
  )
}

DragHandle.displayName = 'DragHandle'
export default DragHandle
