import React from 'react'

// Can be accessed at locale.t('prediction.<key>')

export default {
  tooltip: {
    percentile: (
      <span>
        The <b>Prediction Percentile</b> is the percentile of the prediction value based
        on the model run population.
      </span>
    ),
    prediction: (
      <span>
        A <b>prediction</b> is the raw prediction value output of the model. For binary
        classification models, the value represents the probability of the outcome; for
        regression models, it is the predicted outcome.
      </span>
    ),
    topIncreasingFactors:
      'The top three factors that contribute to an increase in the prediction value.',
    topDecreasingFactors:
      'The top three factors that contribute to an decrease in the prediction value',
    outcome: (
      <span>
        The actual <b>outcome</b> value observed in the train (or test) dataset. This
        depends on whether you are viewing a train or test run.
      </span>
    ),
    asOfDate: (
      <span>
        The <b>As of Date</b> for the prediction displayed.
      </span>
    ),
    normalizedScore: 'The normalized Shapley score for this factor.'
  }
}
