import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import PropTypes from 'prop-types'

export default class Cog extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(color = '#8E8E8E') {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <path fill={color} fillRule="evenodd" d="M15.801 12.68l-.562 1.04a.543.543 0 0 1-.768.22l-1.561-.963c-.792.954-1.864 1.638-3.08 1.902V16.2c0 .332-.251.6-.562.6H8.144c-.31 0-.561-.268-.561-.6v-1.32c-1.217-.265-2.288-.949-3.08-1.903l-1.562.962a.543.543 0 0 1-.768-.22l-.562-1.039a.622.622 0 0 1 .206-.819l1.567-.967A6.333 6.333 0 0 1 3.086 9c0-.664.112-1.297.298-1.894l-1.567-.967a.623.623 0 0 1-.206-.82l.562-1.039a.543.543 0 0 1 .768-.22l1.562.963c.792-.954 1.863-1.638 3.08-1.902V1.8c0-.332.25-.6.561-.6h1.124c.311 0 .562.268.562.6v1.32c1.216.265 2.288.949 3.08 1.903l1.561-.962a.543.543 0 0 1 .768.22l.562 1.039a.623.623 0 0 1-.206.82l-1.567.966c.186.597.298 1.23.298 1.894 0 .664-.112 1.298-.298 1.894l1.567.967a.622.622 0 0 1 .206.82zm.843-1.558l-1.316-.812c.074-.426.122-.86.122-1.31 0-.449-.048-.884-.122-1.31l1.316-.812c.538-.33.722-1.065.411-1.639l-1.123-2.078c-.31-.574-.998-.771-1.536-.44l-1.33.82a6.667 6.667 0 0 0-2.112-1.323V1.2c0-.662-.503-1.2-1.124-1.2H7.583C6.96 0 6.458.538 6.458 1.2v1.018a6.667 6.667 0 0 0-2.112 1.323l-1.33-.82c-.537-.331-1.225-.134-1.535.44L.357 5.239c-.31.574-.126 1.308.411 1.64l1.316.81A7.664 7.664 0 0 0 1.962 9c0 .45.049.884.122 1.31l-1.316.812c-.537.33-.721 1.065-.41 1.639l1.123 2.078c.31.574.998.771 1.535.44l1.33-.82a6.656 6.656 0 0 0 2.112 1.323V16.8c0 .662.503 1.2 1.125 1.2H9.83c.621 0 1.124-.538 1.124-1.2v-1.018a6.656 6.656 0 0 0 2.112-1.323l1.33.82c.538.331 1.226.134 1.536-.44l1.123-2.078c.31-.574.127-1.308-.41-1.64zM8.706 10.8c-.93 0-1.686-.806-1.686-1.8s.755-1.8 1.686-1.8c.931 0 1.686.806 1.686 1.8s-.755 1.8-1.686 1.8zm0-4.8c-1.552 0-2.81 1.343-2.81 3s1.258 3 2.81 3 2.81-1.343 2.81-3-1.258-3-2.81-3z"/>
        </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        id={this.props.id}
        className={this.props.className}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
