import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import PropTypes from 'prop-types'

export default class Logo extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    id: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(fill = '#207DBA') {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <defs/>
          <g fill="none" fillRule="evenodd">
            <path d="M17.15 11.98c.002.038.004.077.004.116a5.165 5.165 0 01-5.162 5.168 5.165 5.165 0 01-5.163-5.168 5.165 5.165 0 015.163-5.168h.017L12 .325h-.007C5.497.324.232 5.594.232 12.096c0 6.502 5.265 11.773 11.76 11.773 6.494 0 11.759-5.27 11.759-11.773l-.001-.117h-6.6z" fill={fill}/>
            <path d="M13.69 7.218a5.176 5.176 0 013.142 3.079h6.782A11.772 11.772 0 0013.69.448v6.77z" fill="#F7C31A"/>
          </g>
        </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        className={this.props.className}
        id={this.props.id}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
