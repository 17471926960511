import { set, size } from 'lodash'

import resolveType from '../resolveType'

export const paramStringToParams = (paramString?: string): any => {
  if (!paramString) return undefined

  return paramString
    .substring(1)
    .split('&')
    .filter((x) => x !== '')
    .map((str) => str.split('='))
    .reduce((a, b) => ({ ...a, [b[0]]: resolveType(decodeURI(b[1])) }), {})
}

export const filtersToParamString = (
  newFilters: Dictionary<any[]>,
  tableName: string,
  currentFilterString
): string | undefined => {
  const statementsForOtherTables = filterStringToStatements(currentFilterString).filter(
    (s) => !s.includes(`${tableName}/`)
  )

  const newStatements = filtersToStatements(newFilters, tableName)

  const filterString = statementsToFilterString([
    ...statementsForOtherTables,
    ...newStatements
  ])

  if (!filterString) {
    return undefined
  }

  return filterString
}

export const sortsToParamString = (
  newSorts: Dictionary<string>,
  tableName: string,
  currentSortString
): string | undefined => {
  const statementsForOtherTables = sortStringToStatements(currentSortString).filter(
    (s) => !s.includes(`${tableName}/`)
  )

  const newStatements = sortsToStatements(newSorts, tableName)

  const sortString = statementsToSortString([
    ...statementsForOtherTables,
    ...newStatements
  ])

  if (!sortString) {
    return undefined
  }

  return sortString
}

export const filterStringToStatements = (filterString?: string): string[] => {
  if (!filterString) return []

  // ') and A
  const delimiterExpression = /'\) and \w/g

  if (!/'\) and \w/g.test(filterString)) return [filterString]

  const results: string[] = []

  const delimiterIndexes: number[] = []

  let match

  do {
    match = delimiterExpression.exec(filterString)

    if (match) delimiterIndexes.push(match['index'])
  } while (match)

  // add a dummy item so that the loop runs one extra time
  delimiterIndexes.push(1)

  delimiterIndexes.forEach((di, index) => {
    // first item
    if (index === 0) {
      results.push(filterString.slice(0, di + 2))
    }
    // last item
    else if (index + 1 === delimiterIndexes.length) {
      results.push(
        filterString.slice(delimiterIndexes[index - 1] + 7, filterString.length)
      )
    }
    // middle item
    else {
      results.push(filterString.slice(di + 7, delimiterIndexes[index + 1] + 2))
    }
  })

  return results
}

export const filtersToStatements = (
  filters: Dictionary<any[]>,
  tableName: string
): string[] => {
  const statements = Object.entries(filters)
    .filter((e) => (e[1] as any[]).length)
    .map((e) => {
      return `${tableName}/${e[0]} eq (${(e[1] as any[])
        .map((item) => `'${item}'`)
        .join(', ')})`
    })

  if (statements.length === 0) return [`${tableName} none`]

  return statements
}

export const statementsToFilterString = (statements: string[]): string | undefined => {
  return statements.join(' and ')
}

export const statementsToFilters = (
  statements: string[],
  tableName: string
): Dictionary<any[]> => {
  const parsedFilters: any = {}

  statements.forEach((s) => {
    // if there's a statement in here that represents the table having no filters at all then just return empty object
    if (s.includes(`${tableName} none`)) return {}

    // the substring from string start up to the first forward slash
    const statementTableName = s.match(/^[^/]*/g)?.[0]

    if (statementTableName === tableName) {
      // the substring from the first forward slash
      const columnName = s.match(/(?!.*\/)(?=[^ ])([\w\d]*)/g)?.[0]

      const indexOfParanStart = s.indexOf('(')
      const indexOfParanEnd = s.search(/\)(?!.*\).*)/g)

      const valuesString = s.substring(indexOfParanStart + 1, indexOfParanEnd)
      let values = valuesString.split("', '")
      values = values.map((v) => v.match(/[^'].*[^']/g)?.[0] as string)

      if (columnName) parsedFilters[columnName] = values
    }
  })

  return parsedFilters
}

export const statementsToTablesFilters = (
  statements: string[]
): Dictionary<Dictionary<string[]>> => {
  const parsedFilters: any = {}

  statements.forEach((s) => {
    const hasNone = / none$/g.test(s)

    // the substring from string start up to the first forward slash
    const statementTableName = hasNone ? s.match(/^[^ ]*/g)?.[0] : s.match(/^[^/]*/g)?.[0]

    if (!hasNone) {
      // the substring from the first forward slash
      const columnName = s.match(/(?!.*\/)(?=[^ ])([\w\d]*)/g)?.[0]

      const indexOfParanStart = s.indexOf('(')
      const indexOfParanEnd = s.search(/\)(?!.*\).*)/g)

      const valuesString = s.substring(indexOfParanStart + 1, indexOfParanEnd)
      let values = valuesString.split("', '")
      values = values.map((v) => v.match(/[^'].*[^']/g)?.[0] as string)

      if (statementTableName && columnName)
        set(parsedFilters, [statementTableName, columnName], values)
    } else {
      if (statementTableName) parsedFilters[statementTableName] = {}
    }
  })

  return parsedFilters
}

export const sortStringToStatements = (sortString?: string): string[] => {
  if (!sortString) return []
  return sortString.split('and').map((s) => s.trim())
}

export const statementsToSorts = (
  statements: string[],
  tableName: string,
  defaultSort: Dictionary<string> = {}
): Dictionary<string> => {
  const parsedSorts: any = {}

  for (let s of statements) {
    // if there's a statement in here that represents the table having no sorts at all then just return empty object
    if (s.includes(`${tableName} none`)) return {}

    // the substring from string start up to the first forward slash
    const statementTableName = s.match(/^[^/]*/g)?.[0]

    if (statementTableName === tableName) {
      // the substring from the first forward slash
      const columnName = s.match(/(?!.*\/)(?=[^ ])([\w\d]*)/g)?.[0]

      const value = s.match(/[^ ][\w\d]*$/g)?.[0]

      if (columnName && value) parsedSorts[columnName] = value
    }
  }

  // If the statements do not contain sorting info for this table, return the default sort.
  return size(parsedSorts) ? parsedSorts : defaultSort
}

export const statementsToTablesSorts = (
  statements: string[]
): Dictionary<Dictionary<string>> => {
  const parsedSorts: any = {}

  statements.forEach((s) => {
    const nonePattern = / none$/g
    const hasNone = nonePattern.test(s)

    // the substring from string start up to the first forward slash or the first word
    const statementTableName = hasNone ? s.match(/^[^ ]*/g)?.[0] : s.match(/^[^/]*/g)?.[0]

    if (!hasNone) {
      // the substring from the first forward slash
      const columnName = s.match(/(?!.*\/)(?=[^ ])([\w\d]*)/g)?.[0]

      const value = s.match(/[^ ][\w\d]*$/g)?.[0]

      if (columnName && value && statementTableName)
        set(parsedSorts, [statementTableName, columnName], value)
    } else {
      if (statementTableName) parsedSorts[statementTableName] = {}
    }
  })

  return parsedSorts
}

export const sortsToStatements = (
  sorts: Dictionary<string>,
  tableName: string
): string[] => {
  const statements = Object.entries(sorts)
    .filter((e) => e[1])
    .map((e) => {
      return `${tableName}/${e[0]} ${e[1] as string}`
    })

  if (statements.length === 0) return [`${tableName} none`]

  return statements
}

export const statementsToSortString = (statements: string[]): string | undefined => {
  return statements.join(' and ')
}
