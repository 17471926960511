import React from 'react'

// Can be accessed at locale.t('savedQuery.<key>')

export default {
  tooltip: {
    savedQuery: (
      <span>
        A <b>saved query</b> is a predefined set of query parameters that can be run ad
        hoc, or used in a deployment result step to generate automated results.
      </span>
    ),
    available:
      'The feature(s) required to use this query template are implemented in your person store, but the template is not yet saved.',
    requiredFeatureMissing:
      'The feature(s) required to use this query template are not yet implemented in your person store. You must implement the missing features before you can use this query template; you can do so by selecting cancel below, then selecting Feature in the Create menu at the top of your screen. Alternatively, you can select Next and modify the query template to only use features in your person store before running or saving it.',
    saved: 'This query template is saved in your person store.'
  },
  inline: {
    missingFeatures:
      'This query template uses features which are not implemented in your person store. You must remove the features from Features Included to run or save the query.',
    hasBeenSaved: (
      <span>
        This query template has already been implemented as a saved query in your person
        store.
        <br />
        Click Save to implement this template again as an additional saved query.
        <br />
        To edit other saved queries that are based on this query template, navigate to
        Saved Queries.
      </span>
    )
  },
  formFields: {
    sortBy:
      'Define the features which should be used to sort the results. You may include multiple features to sort by, and they will be applied in that order. If you include a feature generator in the order by clause, you must specify the output of the feature name configuration. If you include a feature from reference data in the order by clause, you must specify the individual column name.',
    reorderFeatures: 'Drag and drop the rows to reorder features.',
    addAdhocFeature: 'Define ad hoc features to include in your query.'
  }
}
