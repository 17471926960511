import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export default class RetryOld extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(color = '#8E8E8E') {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
        <svg width="24px" height="23px" viewBox="4 0 20 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-1311.000000, -647.000000)" fill={color} fillRule="nonzero">
              <g transform="translate(288.000000, 72.000000)">
                <g transform="translate(32.000000, 257.000000)">
                  <g transform="translate(32.000000, 164.000000)">
                    <g transform="translate(0.000000, 25.000000)">
                      <g transform="translate(910.000000, 2.000000)">
                        <g transform="translate(53.000000, 130.000000)">
                          <path d="M5.89583333,3.39583333 C4.35416667,4.9375 3.85416667,7.16666667 4.41666667,9.125 L5.54166667,7.04166667 L7.02083333,7.83333333 L4.60416667,12.3541667 L0.104166667,9.9375 L0.895833333,8.45833333 L2.75,9.45833333 C2.08333333,6.95833333 2.72916667,4.16666667 4.6875,2.20833333 C7.1875,-0.291666667 11.0208333,-0.645833333 13.9166667,1.125 L12.6875,2.35416667 C10.5,1.20833333 7.72916667,1.5625 5.89583333,3.39583333 Z"></path>
                          <path d="M6.0625,13.8958333 L7.29166667,12.6666667 C9.47916667,13.8125 12.25,13.4583333 14.0833333,11.625 C15.625,10.0833333 16.125,7.85416667 15.5625,5.89583333 L14.4375,7.97916667 L12.9583333,7.1875 L15.375,2.6875 L19.875,5.10416667 L19.0833333,6.58333333 L17.2291667,5.58333333 C17.8958333,8.08333333 17.25,10.875 15.2916667,12.8333333 C12.7916667,15.2916667 8.95833333,15.6666667 6.0625,13.8958333 Z"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
      </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        id={this.props.id}
        className={classnames('retry-old-icon', this.props.className)}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
