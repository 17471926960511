import React, { useEffect, useRef } from 'react'
import { debounce, noop } from 'lodash'
import { loadWaitingServiceWorker } from 'serviceWorkerRegistration'
import { Link as WouterLink } from 'wouter'
import mixpanel from 'utils/mixpanel'

import { addParamsToUri } from 'utils/helpers'
import { phiCheck } from 'utils/helpers'
import { localStorage as ls, sessionStorage } from 'utils/storage'

import { AppUrl } from 'v1/Constants'
import useLocation from './useLocation'

// Get current date, compare day when user clicks link to know if we should check for a new app.
const getDay = () => new Date().getDate()
const getHour = () => new Date().getHours()
const getArc = () => Number(getHour() + String(Math.floor(new Date().getMinutes() / 0.6)))

let lastCheckDate = `${ls.getItem('lastUpdateCheck') || ''}`
if (!lastCheckDate) {
  const checkDate = `${getDay()}:${getHour()}:${getArc()}`
  ls.setItem('lastUpdateCheck', (lastCheckDate = checkDate))
}

let [lastDay, lastHour, lastArc] = lastCheckDate.split(':').map(Number)

// A page might have 10 links that will all check if there's an app update on create.
// We'll debounce the check so that it only fires once in a half second
const checkForAppUpdate = debounce(() => {
  const updateReady = sessionStorage.getItem('appUpdateReady')

  const newDay = Number(lastDay) !== getDay()
  const isMorning = getHour() < 14
  const hasBeen12minutes = !lastArc || getArc() - lastArc > 20

  if (newDay || (isMorning && hasBeen12minutes) || updateReady) {
    lastDay = getDay()
    lastHour = getHour()
    lastArc = getArc()
    lastCheckDate = `${lastDay}:${lastHour}:${lastArc}`
    ls.setItem('lastUpdateCheck', lastCheckDate)
    loadWaitingServiceWorker()
  }
}, 500)

interface LinkProps {
  children?: React.ReactNode
  className?: string
  onClick?: (event) => void
  params?: QueryParams
  id?: string
  href: string
  target?: '_blank' | '_self' | '_parent' | '_top'
  trackingData?: object
  style?: React.CSSProperties
}

const Link: React.FC<LinkProps> = ({
  onClick = noop,
  params,
  href: hrefProp,
  trackingData,
  target,
  ...props
}) => {
  const href = params ? addParamsToUri(hrefProp, params) : hrefProp
  const [location] = useLocation()
  const currentUrl = useRef(document.location.href)

  const isExternalOrDocs =
    href &&
    (href.startsWith('http') ||
      href.startsWith(`${AppUrl}/docs`) ||
      href.startsWith('/docs'))
  const targetWithDefault = target || (isExternalOrDocs ? '_blank' : '_self')

  // When the app route changes, keep track of the full href for mixpanel
  useEffect(() => {
    currentUrl.current = document.location.href
  }, [location])

  // If a link is clicked and there is a new version of the app,
  // use this opportunity to load the new version
  const onLinkClick = (event) => {
    mixpanel.track('CTA Clicked', {
      click_type: 'Link',
      click_text: phiCheck.obscureData(event?.currentTarget?.textContent),
      click_url: href,
      $current_url: currentUrl.current,
      ...trackingData
    })

    if (onClick && typeof onClick === 'function') {
      onClick(event)
    }

    if (href) checkForAppUpdate()
  }

  //Use native anchor tag to bypass client-side router
  if (isExternalOrDocs)
    return (
      <a onClick={onLinkClick} href={href} target={targetWithDefault} {...props}>
        {props.children}
      </a>
    )

  return (
    <WouterLink onClick={onLinkClick} href={href} target={targetWithDefault} {...props} />
  )
}

export default Link
