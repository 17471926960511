import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js'
import Cookies from 'js-cookie'

import { jwtDecode } from 'utils/helpers'
import { localStorage } from 'utils/storage'
import varna from 'utils/varna'

import { AppUrl, Auth0APIAudienceV2, Auth0ClientIdV2, Auth0DomainV2 } from 'v1/Constants'
import { getRedirectUrl, saveCurrentUrl, throwAuthError } from './auth0-helpers'
// import onWakeup from './onWakeup'
import TabSync from './TabSync'

const tabSync = new TabSync('authSync')

export default class AuthClient {
  private client: Auth0Client
  private initCallback: () => void | undefined

  constructor() {
    createAuth0Client({
      domain: Auth0DomainV2,
      clientId: Auth0ClientIdV2,
      useRefreshTokensFallback: true
    }).then((client) => {
      this.client = client
      this.initCallback?.()
    })

    tabSync.onMessage(async (msg) => {
      if (msg.startsWith('logout:')) {
        saveCurrentUrl()
        const reason = msg.split(':')[1]

        setTimeout(() => {
          document.location =
            AppUrl + `/auth/index.html?t=${reason}&l=${getRedirectUrl()}`
        }, Math.random() * 2000)
      }
    })

    // onWakeup(() => this.logout({ reason: 'idle' }))
  }

  login = async () => {
    try {
      const redirectUrl = getRedirectUrl()

      await this.client.loginWithRedirect({
        authorizationParams: {
          redirect_uri: AppUrl,
          audience: Auth0APIAudienceV2,
          scope: 'openid profile email api:full'
        },
        appState: { redirectUrl }
      })
    } catch (e: any) {
      const response = e.response?.data ?? e.response
      const err = response?.error_description ?? response?.error
      throwAuthError('Failed Login - ' + err)
    }
  }

  logout = async ({
    reason
  }: {
    reason: 'idle' | 'expired' | 'manual' | 'error' | 'unauthorized'
  }) => {
    saveCurrentUrl()

    Cookies.remove('token')
    Cookies.remove('idToken')

    // Message the other tabs that it's time to log out
    tabSync.message(`logout:${reason}`)

    try {
      // If the client hasn't intialized yet, we can manually clear credentials
      if (!this.client) return (document.location.pathname = '/logout')

      await this.client.logout({
        logoutParams: {
          returnTo: AppUrl + `/auth/index.html?t=${reason}&l=${getRedirectUrl()}`,
          client_id: Auth0ClientIdV2
        }
      })
    } catch (e: any) {
      const response = e.response?.data ?? e.response
      const err = response?.error_description ?? response?.error
      throwAuthError('Failed Logout - ' + err)
    }
  }

  requireAuth = async (fn) => {
    if (await this.isAuthenticated()) return fn()
    return this.login()
  }

  isAuthenticated = async () => {
    const token = Cookies.get('token')
    return !!token && (await this.client.isAuthenticated())
  }

  onInit = (callback) => {
    this.initCallback = callback
    if (this.client) callback()
  }

  getUser = async () => {
    const tokens = await this.client.getTokenSilently({
      authorizationParams: {
        redirect_uri: AppUrl,
        audience: Auth0APIAudienceV2,
        scope: 'openid profile email api:full'
      },
      detailedResponse: true
    })
    Cookies.set('token', tokens.access_token)
    Cookies.set('idToken', tokens.id_token)
    const user = jwtDecode(tokens.id_token)
    const token = jwtDecode(tokens.access_token)

    return { ...user, ...token }
  }

  getCachedUser = () => {
    const tokenRaw = Cookies.get('token')
    const idTokenRaw = Cookies.get('idToken')

    for (let key of localStorage.keys()) {
      if (key.startsWith('@@auth0spajs')) localStorage.removeItem(key)
    }

    if (!tokenRaw || !idTokenRaw) return

    const token = jwtDecode(tokenRaw)
    const user = jwtDecode(idTokenRaw)

    const tokenExpired = token.exp < Date.now() / 1000 - 60
    const differentAudience = !token.aud.includes(Auth0APIAudienceV2)

    if (tokenExpired || differentAudience) {
      Cookies.remove('token')
      Cookies.remove('idToken')
      return
    }

    return { ...user, ...token }
  }

  handleRedirect = async () => {
    try {
      const result = await this.client.handleRedirectCallback()
      setTimeout(() => tabSync.message('login'), 2000)
      return result.appState
    } catch (e: any) {
      const response = e.response?.data ?? e.response
      const err = response?.error_description ?? response?.error ?? JSON.stringify(e)
      this.logout({ reason: 'error' })
      throwAuthError('Failed redirect - ' + err)
    }
  }
}
