import React, { ReactNode } from 'react'
import classNames from 'classnames'

import './ExceptionPageLayout.scss'

type ExceptionPageLayoutProps = {
  children?: ReactNode
  className?: string
}

const ExceptionPageLayout = ({ children, className }: ExceptionPageLayoutProps) => {
  return <div className={classNames('exception-page', className)}>{children}</div>
}

export default ExceptionPageLayout
