import React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classNames from 'classnames'

export interface LogoProps {
  alt?: string
  id?: string
  className?: string
  onClick?: () => void
}

const Logo: React.FC<LogoProps> = ({ alt, id, className, onClick }) => {
  const markup = encodeURIComponent(
    renderToStaticMarkup(
      <svg
        width="113"
        height="24"
        viewBox="0 0 113 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M31.5738 17.3397C32.523 17.3401 33.4529 17.0655 34.2552 16.5478C35.0575 16.03 35.6992 15.2905 36.1057 14.4151L34.5071 13.4731L34.1429 13.2575C33.906 13.8867 33.4608 14.4114 32.8845 14.7406C32.3081 15.0698 31.637 15.1827 30.9876 15.0598C30.3382 14.9368 29.7515 14.5858 29.3292 14.0676C28.9069 13.5493 28.6757 12.8966 28.6757 12.2226C28.6757 11.5486 28.9069 10.8958 29.3292 10.3776C29.7515 9.85937 30.3382 9.50834 30.9876 9.38541C31.637 9.26248 32.3081 9.3754 32.8845 9.7046C33.4608 10.0338 33.906 10.5585 34.1429 11.1876L34.5071 10.974L36.1057 10.0319C35.7215 9.20424 35.1267 8.49696 34.3832 7.98387C33.6398 7.47079 32.7751 7.17072 31.8793 7.11496C30.9835 7.05921 30.0894 7.24982 29.2904 7.6669C28.4914 8.08398 27.8167 8.71225 27.3368 9.48615C26.8569 10.26 26.5893 11.1512 26.5619 12.0666C26.5345 12.9821 26.7485 13.8882 27.1813 14.6905C27.6141 15.4928 28.25 16.1618 29.0227 16.6278C29.7953 17.0938 30.6763 17.3396 31.5738 17.3397Z"
            fill="#1D7DBA"
          />
          <path d="M39.5933 7.32715H37.4502V17.121H39.5933V7.32715Z" fill="#1D7DBA" />
          <path
            d="M46.1481 9.82214C45.6584 9.6245 45.1361 9.52484 44.6095 9.52856C44.075 9.52267 43.5446 9.62497 43.0491 9.82957C42.5889 10.0203 42.1704 10.3026 41.8183 10.6601C41.4697 11.0187 41.1953 11.4453 41.0117 11.9143C40.8176 12.4171 40.7206 12.9534 40.7258 13.4937C40.7208 14.0252 40.8212 14.5523 41.0208 15.0433C41.2054 15.5038 41.4784 15.922 41.8237 16.2733C42.1785 16.6215 42.5966 16.8955 43.0546 17.0797C43.5499 17.2773 44.0778 17.3757 44.6095 17.3696C45.1362 17.3747 45.6588 17.275 46.1481 17.076C46.6057 16.8885 47.0217 16.6092 47.3717 16.2548C47.7201 15.897 47.996 15.4727 48.1837 15.0061C48.5771 14.0069 48.5771 12.8912 48.1837 11.892C47.9964 11.4258 47.7204 11.002 47.3717 10.6453C47.0222 10.2897 46.606 10.0097 46.1481 9.82214ZM46.2118 14.1904C46.1277 14.4111 46.0042 14.614 45.8477 14.7887C45.6957 14.9603 45.5094 15.0965 45.3014 15.1882C45.0865 15.283 44.8546 15.3311 44.6205 15.3294C44.3803 15.332 44.1423 15.284 43.9213 15.1882C43.709 15.0958 43.5179 14.9591 43.3605 14.7869C43.2015 14.6112 43.0778 14.4054 42.9963 14.1812C42.9083 13.9437 42.8638 13.6918 42.8652 13.4379C42.8643 13.1895 42.9113 12.9432 43.0036 12.7133C43.0893 12.4951 43.2127 12.2944 43.3677 12.1206C43.5228 11.9526 43.7083 11.817 43.914 11.7211C44.1341 11.6218 44.3725 11.5717 44.6132 11.5743C44.8456 11.5721 45.0756 11.6223 45.2869 11.7211C45.4927 11.8167 45.6782 11.9524 45.8331 12.1206C45.9893 12.2935 46.1128 12.4945 46.1973 12.7133C46.2909 12.9493 46.3379 13.2019 46.3356 13.4565C46.3394 13.7069 46.2974 13.9558 46.2118 14.1904Z"
            fill="#1D7DBA"
          />
          <path
            d="M52.6059 15.4471C52.4484 15.5568 52.2612 15.6134 52.0706 15.6087C51.5717 15.6087 51.2931 15.3839 51.2348 14.9342H49.1045C49.1358 15.2899 49.2335 15.6361 49.3922 15.9543C49.5421 16.2472 49.7476 16.5068 49.9967 16.718C50.2568 16.934 50.5546 17.0979 50.8743 17.2011C51.2327 17.3175 51.6071 17.3746 51.9832 17.3702C52.3838 17.3749 52.7824 17.3121 53.163 17.1843C53.5071 17.0679 53.8287 16.8912 54.1135 16.6622C54.3772 16.4468 54.5926 16.1763 54.7453 15.8688C54.8984 15.5621 54.9776 15.2227 54.9765 14.8785C54.9834 14.4705 54.8671 14.0703 54.6433 13.7321C54.5494 13.6004 54.446 13.4762 54.3338 13.3604C54.2211 13.2492 54.0914 13.1575 53.9496 13.0892C53.7604 12.9967 53.5651 12.9178 53.3651 12.8532C53.1284 12.7752 52.825 12.6823 52.4547 12.5745C52.152 12.507 51.8634 12.3849 51.6026 12.214C51.5268 12.1666 51.4634 12.1011 51.418 12.0231C51.3727 11.945 51.3467 11.8569 51.3422 11.7662C51.3418 11.6987 51.3583 11.6322 51.3902 11.573C51.422 11.5138 51.4682 11.4639 51.5243 11.4281C51.6706 11.3341 51.8415 11.2881 52.0141 11.2961C52.3868 11.2961 52.5925 11.4776 52.6314 11.8406H54.7326C54.7159 11.5169 54.6359 11.1999 54.4974 10.9083C54.3589 10.6166 54.1646 10.3562 53.9259 10.1423C53.4064 9.71356 52.7508 9.49409 52.0833 9.5254C51.6976 9.5214 51.314 9.58422 50.949 9.71121C50.6153 9.82695 50.3036 10.0004 50.0276 10.224C49.7707 10.433 49.5608 10.696 49.4122 10.9951C49.2663 11.2887 49.1908 11.6135 49.1919 11.9427C49.1917 12.1757 49.2305 12.4069 49.3066 12.6265C49.3801 12.8412 49.4878 13.0421 49.6252 13.2211C49.7625 13.3997 49.9259 13.5557 50.1096 13.6838C50.2959 13.8154 50.5037 13.9123 50.7232 13.9699L51.9977 14.3062C52.5567 14.4641 52.8353 14.7075 52.8353 15.0494C52.8336 15.1302 52.8116 15.2092 52.7714 15.2788C52.7313 15.3484 52.6743 15.4064 52.6059 15.4471Z"
            fill="#1D7DBA"
          />
          <path
            d="M63.1648 14.1401C63.183 14.0119 63.1975 13.9116 63.2066 13.8391C63.2174 13.7392 63.2222 13.6387 63.2212 13.5381C63.2255 12.9953 63.1278 12.4567 62.9335 11.9513C62.7513 11.4789 62.4797 11.0477 62.1342 10.6823C61.7815 10.3138 61.3597 10.0216 60.8938 9.82339C60.428 9.62513 59.9278 9.5248 59.423 9.52841C58.9217 9.52638 58.4255 9.63145 57.9664 9.83685C57.5218 10.0352 57.1187 10.3191 56.7792 10.673C56.0726 11.4209 55.6805 12.4211 55.6868 13.4601C55.681 13.9734 55.7745 14.4828 55.962 14.9591C56.1494 15.4355 56.4272 15.8694 56.7792 16.236C57.1305 16.597 57.5488 16.8829 58.0096 17.0769C58.4704 17.2709 58.9646 17.3691 59.4631 17.3657C60.2089 17.3768 60.9383 17.1415 61.5424 16.695C62.175 16.2201 62.6681 15.5775 62.9681 14.8369H60.7832C60.7653 14.8553 60.7465 14.8727 60.7267 14.8889C60.7079 14.902 60.6928 14.92 60.683 14.941C60.5014 15.1311 60.2864 15.2848 60.0494 15.3943C59.8289 15.4785 59.5946 15.5182 59.3593 15.5114C58.967 15.5264 58.5829 15.3942 58.2796 15.1398C57.9914 14.8896 57.8109 14.534 57.777 14.1494L63.1648 14.1401ZM57.8244 12.6704C57.8893 12.2962 58.087 11.9595 58.3797 11.7246C58.6941 11.5055 59.0667 11.3902 59.4474 11.3941C59.8281 11.3981 60.1984 11.5213 60.5082 11.7469C60.7985 11.9769 60.996 12.3074 61.0636 12.676L57.8244 12.6704Z"
            fill="#1D7DBA"
          />
          <path
            d="M71.8551 17.1284V7.32715H69.7102V10.3892C69.4356 10.1139 69.1126 9.89385 68.758 9.74077C68.3814 9.5922 67.9799 9.52024 67.5763 9.52896C67.0874 9.52481 66.6031 9.6248 66.1542 9.82253C65.726 10.0124 65.3405 10.2901 65.0217 10.6382C64.6969 10.9994 64.4429 11.4207 64.2734 11.8794C64.0872 12.3763 63.9946 12.9045 64.0002 13.4365C63.9958 13.9774 64.0884 14.5145 64.2734 15.0214C64.4399 15.482 64.695 15.904 65.0235 16.2626C65.3473 16.6111 65.7377 16.8881 66.1706 17.0764C66.6313 17.2753 67.1271 17.3753 67.6273 17.37C68.0598 17.3831 68.4901 17.3013 68.8891 17.1303C69.2582 16.947 69.5814 16.6801 69.8341 16.3499V17.1284H71.8551ZM69.2787 14.7966C69.1056 14.9732 68.8988 15.1117 68.6711 15.2034C68.4434 15.2951 68.1997 15.3382 67.955 15.3298C67.7055 15.3324 67.4579 15.2844 67.2267 15.1886C67.0097 15.1012 66.8136 14.9675 66.6513 14.7966C66.4891 14.6234 66.3616 14.4195 66.2762 14.1964C66.183 13.9538 66.1367 13.6952 66.1397 13.4346C66.1383 13.1802 66.1846 12.928 66.2762 12.6914C66.3614 12.4685 66.4917 12.2666 66.6586 12.0987C66.8266 11.9332 67.0245 11.8026 67.2412 11.714C67.4765 11.6191 67.7275 11.5718 67.9805 11.5747C68.2299 11.5712 68.4775 11.6186 68.7088 11.714C69.1418 11.8976 69.4857 12.2491 69.6647 12.6914C69.801 13.044 69.8377 13.4286 69.7704 13.8014C69.7031 14.1743 69.5347 14.5203 69.2842 14.8003L69.2787 14.7966Z"
            fill="#1D7DBA"
          />
          <path
            d="M73.3945 7.32715V17.121H78.6748V14.9173H75.8417V7.32715H73.3945Z"
            fill="#4EA2D0"
          />
          <path
            d="M86.7263 15.0062C87.1196 14.007 87.1196 12.8913 86.7263 11.8921C86.5383 11.4262 86.2624 11.0026 85.9142 10.6453C85.5626 10.2889 85.1439 10.0089 84.6833 9.82219C84.1908 9.62339 83.6653 9.5237 83.1357 9.52862C82.5999 9.52203 82.0682 9.62436 81.5716 9.82963C81.1114 10.0204 80.693 10.3027 80.3408 10.6602C79.9915 11.0179 79.7176 11.4447 79.536 11.9144C79.3405 12.4168 79.2428 12.9532 79.2483 13.4937C79.2424 14.0254 79.3428 14.5526 79.5433 15.0434C79.7297 15.5041 80.006 15.9213 80.3553 16.2697C80.7101 16.6179 81.1283 16.8918 81.5862 17.0761C82.0828 17.2759 82.6128 17.3756 83.1466 17.3697C83.6739 17.3749 84.1971 17.2752 84.687 17.0761C85.1446 16.8886 85.5606 16.6093 85.9106 16.2548C86.2597 15.8969 86.5368 15.4727 86.7263 15.0062ZM84.7471 14.1905C84.6623 14.4108 84.5389 14.6136 84.3829 14.7888C84.231 14.9603 84.0447 15.0966 83.8367 15.1883C83.6212 15.2832 83.3886 15.3313 83.1539 15.3295C82.9149 15.3323 82.678 15.2842 82.4583 15.1883C82.2455 15.0959 82.0539 14.9592 81.8957 14.7869C81.7373 14.6108 81.6136 14.4051 81.5316 14.1812C81.445 13.9434 81.4012 13.6916 81.4023 13.438C81.4005 13.1894 81.4475 12.943 81.5407 12.7133C81.6257 12.4948 81.7491 12.294 81.9048 12.1206C82.0593 11.952 82.245 11.8163 82.4511 11.7211C82.6698 11.6216 82.9071 11.5716 83.1466 11.5743C83.3796 11.572 83.6103 11.6221 83.8221 11.7211C84.0275 11.8174 84.213 11.953 84.3683 12.1206C84.5235 12.2944 84.6469 12.4951 84.7325 12.7133C84.827 12.9491 84.874 13.2019 84.8709 13.4566C84.8747 13.7069 84.8327 13.9558 84.7471 14.1905Z"
            fill="#4EA2D0"
          />
          <path
            d="M88.9202 16.2737C89.275 16.6219 89.6931 16.8959 90.1511 17.0801C90.6478 17.2796 91.1777 17.3793 91.7115 17.3737C92.2388 17.379 92.762 17.2793 93.2519 17.0801C93.7094 16.8926 94.1255 16.6134 94.4754 16.2589C94.8233 15.9006 95.0991 15.4765 95.2875 15.0102C95.6809 14.011 95.6809 12.8954 95.2875 11.8961C95.0995 11.4302 94.8236 11.0066 94.4754 10.6494C94.1238 10.293 93.7051 10.013 93.2446 9.82625C92.7521 9.62744 92.2265 9.52775 91.6969 9.53267C91.1602 9.52461 90.6273 9.62567 90.1292 9.82996C89.669 10.0207 89.2505 10.303 88.8984 10.6605C88.5497 11.0187 88.2759 11.4454 88.0936 11.9147C87.8981 12.4171 87.8004 12.9535 87.8059 13.4941C87.8009 14.0256 87.9013 14.5527 88.1009 15.0437C88.289 15.5064 88.5678 15.925 88.9202 16.2737ZM90.0946 12.7137C90.1797 12.4952 90.3031 12.2943 90.4588 12.121C90.6136 11.9528 90.7992 11.8171 91.005 11.7215C91.2244 11.6218 91.4623 11.5718 91.7024 11.5747C91.9348 11.5725 92.1648 11.6226 92.3761 11.7215C92.5815 11.8178 92.7669 11.9534 92.9223 12.121C93.0774 12.2948 93.2007 12.4955 93.2865 12.7137C93.3801 12.9497 93.4271 13.2023 93.4248 13.4569C93.4274 13.7109 93.383 13.963 93.2937 14.2001C93.2089 14.4205 93.0855 14.6232 92.9296 14.7984C92.7776 14.97 92.5914 15.1062 92.3833 15.1979C92.1684 15.2927 91.9365 15.3408 91.7024 15.3391C91.4628 15.3421 91.2253 15.294 91.005 15.1979C90.7966 15.101 90.61 14.9612 90.457 14.7873C90.2974 14.612 90.1736 14.4061 90.0928 14.1816C90.0048 13.9441 89.9603 13.6922 89.9617 13.4383C89.9576 13.1889 90.0028 12.9412 90.0946 12.71V12.7137Z"
            fill="#4EA2D0"
          />
          <path
            d="M98.8692 16.4595C99.1314 16.7617 99.456 17.0009 99.8196 17.16C100.205 17.3126 100.616 17.3864 101.029 17.3773C101.506 17.3814 101.979 17.2813 102.416 17.0838C102.834 16.891 103.211 16.6157 103.525 16.2737C103.849 15.9188 104.101 15.502 104.266 15.0473C104.447 14.5569 104.538 14.0366 104.534 13.5126C104.538 12.9681 104.451 12.4268 104.275 11.9128C104.116 11.4469 103.868 11.0176 103.547 10.6493C103.237 10.2976 102.857 10.017 102.432 9.82617C101.983 9.62713 101.498 9.52709 101.009 9.5326C100.558 9.51513 100.109 9.59622 99.6922 9.77043C99.3116 9.9503 98.9821 10.2259 98.7344 10.5713V9.77043H96.7061V19.5736H98.8692V16.4595ZM98.9129 12.7099C99.1003 12.2704 99.4428 11.9189 99.8724 11.7251C100.094 11.626 100.333 11.576 100.575 11.5783C100.825 11.5751 101.072 11.6225 101.304 11.7177C101.522 11.8084 101.718 11.9452 101.881 12.119C102.045 12.2916 102.175 12.4947 102.265 12.7173C102.448 13.1952 102.448 13.7258 102.265 14.2038C102.17 14.4219 102.035 14.6198 101.868 14.7872C101.705 14.9605 101.509 15.0966 101.291 15.1867C101.066 15.2815 100.825 15.3295 100.583 15.3279C100.34 15.3294 100.1 15.2809 99.8766 15.185C99.6531 15.0892 99.451 14.9482 99.2825 14.7705C99.1182 14.5954 98.9884 14.3898 98.9001 14.1648C98.8086 13.9282 98.7622 13.6759 98.7635 13.4215C98.7612 13.1742 98.8102 12.9291 98.9074 12.7025L98.9129 12.7099Z"
            fill="#4EA2D0"
          />
          <path d="M106.287 16.123H105.289V17.1208H106.287V16.123Z" fill="#1D7DBA" />
          <path
            d="M110.316 17.1245V13.858H109.429V14.2036C109.348 14.0493 109.22 13.9255 109.065 13.8506C108.874 13.7717 108.668 13.7343 108.462 13.741C108.233 13.7375 108.006 13.7805 107.794 13.8673C107.592 13.9481 107.41 14.0716 107.259 14.2297C107.111 14.3908 106.996 14.5803 106.92 14.7871C106.837 15.0133 106.795 15.2533 106.798 15.495C106.795 15.7376 106.835 15.9786 106.916 16.2066C106.988 16.4142 107.101 16.6041 107.25 16.7641C107.395 16.9176 107.57 17.0384 107.763 17.119C107.973 17.2068 108.199 17.2504 108.426 17.2472C108.936 17.2472 109.265 17.0929 109.42 16.7864V17.1394L110.316 17.1245ZM109.302 15.8239C109.262 15.9219 109.205 16.0119 109.134 16.0896C109.062 16.1646 108.975 16.2234 108.879 16.2624C108.777 16.3045 108.667 16.326 108.557 16.3256C108.448 16.3256 108.341 16.3042 108.24 16.2624C108.143 16.2196 108.055 16.1563 107.983 16.0766C107.91 15.9999 107.852 15.9097 107.812 15.8109C107.73 15.6004 107.73 15.3655 107.812 15.155C107.851 15.057 107.908 14.9675 107.98 14.8911C108.05 14.8111 108.137 14.7477 108.235 14.7053C108.335 14.6641 108.442 14.6433 108.55 14.644C108.661 14.643 108.772 14.6638 108.876 14.7053C108.973 14.7447 109.061 14.8042 109.134 14.88C109.207 14.9613 109.263 15.0559 109.302 15.1587C109.342 15.2627 109.363 15.3738 109.362 15.4857C109.363 15.6013 109.343 15.7161 109.302 15.8239Z"
            fill="#1D7DBA"
          />
          <path d="M111.909 13.8584H110.955V17.1212H111.909V13.8584Z" fill="#1D7DBA" />
          <path d="M111.909 12.7676H110.955V13.5108H111.909V12.7676Z" fill="#1D7DBA" />
          <path
            d="M-6.60973e-05 11.9702C-0.0187171 13.4426 0.249352 14.904 0.788585 16.2698C1.32782 17.6356 2.12748 18.8785 3.14116 19.9264C4.15485 20.9743 5.36237 21.8063 6.69367 22.3742C8.02496 22.9421 9.45352 23.2345 10.8964 23.2345C12.3394 23.2345 13.7679 22.9421 15.0992 22.3742C16.4305 21.8063 17.638 20.9743 18.6517 19.9264C19.6654 18.8785 20.4651 17.6356 21.0043 16.2698C21.5435 14.904 21.8116 13.4426 21.793 11.9702C21.793 11.9331 21.793 11.8978 21.793 11.8606H15.6769C15.6769 11.8978 15.6769 11.9331 15.6769 11.9702C15.6769 12.9357 15.3963 13.8795 14.8707 14.6822C14.345 15.485 13.5979 16.1106 12.7237 16.48C11.8496 16.8494 10.8878 16.9459 9.95988 16.7574C9.03198 16.5689 8.1797 16.1039 7.51083 15.4211C6.84196 14.7382 6.38654 13.8683 6.20219 12.9213C6.01783 11.9744 6.11281 10.9929 6.47512 10.101C6.83743 9.20913 7.45079 8.44693 8.23763 7.91081C9.02446 7.37468 9.94943 7.08872 10.8955 7.08909H10.9119L10.9046 0.851566C9.47304 0.850346 8.05524 1.13704 6.73228 1.69526C5.40931 2.25347 4.20711 3.07228 3.1944 4.10486C2.18168 5.13744 1.37831 6.36357 0.830182 7.71315C0.282058 9.06274 -6.65971e-05 10.5093 -6.60973e-05 11.9702Z"
            fill="#1D7DBA"
          />
          <path
            d="M12.4688 0.96875V7.36421C13.1342 7.60007 13.7404 7.98303 14.244 8.4857C14.7476 8.98838 15.1362 9.59837 15.382 10.2721H21.6674C21.3103 7.92155 20.2242 5.74972 18.5687 4.07532C16.9131 2.40092 14.7752 1.31214 12.4688 0.96875Z"
            fill="#F7C217"
          />
        </g>
      </svg>
    )
  )

  return (
    <img
      alt={alt}
      id={id}
      className={classNames('cl-logo', className)}
      src={`data:image/svg+xml,${markup}`}
      onClick={onClick}
    />
  )
}

export default Logo
