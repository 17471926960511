import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import PropTypes from 'prop-types'

export default class Feedback extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(color = '#FFF') {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
        <svg width="15px" height="16px" viewBox="0 0 15 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-1375.000000, -810.000000)" fill={color} fillRule="nonzero">
                  <g transform="translate(1.168257, 15.500000)">
                      <g transform="translate(1351.831743, 783.500000)">
                          <g transform="translate(22.000000, 6.000000)">
                              <path d="M13.53602,5.5 L1.48627045,5.5 L0.522290481,5.5 L0.522290481,6.47550145 L0.522290481,17.2060175 L0.522290481,19.6333333 L0.5,20.5 L3.41423038,17.6937682 L13.53602,17.6937682 L14.5,17.6937682 L14.5,16.7182667 L14.5,6.47550145 L14.5,5.5 L13.53602,5.5 Z M8.3,15 L7.3,15 L7.3,14 L8.3,14 L8.3,15 Z M8.3,12 L7.3,12 L7.3,8 L8.3,8 L8.3,12 Z"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
        </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        className={this.props.className}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
