const unproxy = (proxy) => {
  const obj = proxy && proxy.get ? proxy.get() : proxy
  if (obj === undefined || obj === null) {
    return obj
  } else if (Array.isArray(obj)) {
    return obj.map(unproxy)
  } else if (typeof obj === 'object') {
    if (obj.toTimeString) return obj
    const res = {}
    Object.keys(obj).forEach((key) => {
      res[key] = unproxy(obj[key])
    })
    return res
  } else {
    return obj
  }
}

export default unproxy
