const distanceFromElement = (child, parent = document.body) => {
  if (typeof child === 'string') child = document.querySelector(child)
  if (typeof parent === 'string') parent = document.querySelector(parent)

  if (!child || !parent) {
    return Promise.reject('child or parent does not exist')
  }

  var xPosition = 0
  var yPosition = 0

  while (child && !child.isEqualNode(parent)) {
    xPosition += child.offsetLeft - child.scrollLeft + child.clientLeft
    yPosition += child.offsetTop - child.scrollTop + child.clientTop
    child = child.offsetParent
  }

  return { left: xPosition, top: yPosition }
}

export default distanceFromElement
