enum StepTypes {
  SubsetPop = 'Include Population',
  ExcludePop = 'Exclude Population',
  Subset = 'Include Subset',
  ExcludeSubset = 'Exclude Subset',
  IdList = 'ID List',
  Limit = 'Limit Count',
  LimitPercent = 'Limit Percent',
  IndexDate = 'Index Date',
  IndexTimestamp = 'Index Timestamp',
  TimeSeries = 'Time Series',
  AsEntity = 'As Entity'
}

enum StepTypeDescriptions {
  SubsetPop = 'Select an existing population to include in the new population',
  ExcludePop = 'Select an existing population to exclude from the new population',
  Subset = 'Include a subset of individuals which meet the defined criteria',
  ExcludeSubset = 'Exclude a subset of individuals which meet the defined criteria',
  IdList = 'Enter a list of comma separated IDs',
  Limit = 'Limit population to the defined count',
  LimitPercent = 'Limit population to the defined percent',
  IndexDate = 'Set the index date',
  TimeSeries = 'Return a series of data points indexed in time order',
  AsEntity = 'Sets the entity type for the population',
  RecentDataUpdate = 'Selects the primary entity ids that were updated as part of the most recent LoadDataTransaction of a given type',
  IndexDateFixed = 'Sets the IndexDate for everyone in the Population to the same fixed date.',
  IndexTimestamp = 'Set the index timestamp based on a CL Expression',
  IndexTimestampFixed = 'Sets the IndexTimestamp for everyone in the Population to the same fixed value.',
  UpdatedIdList = 'Select the primary entity ids updated as part of a data load.'
}

enum HiddenStepTypes {
  RecentDataUpdate = 'Recent Data Update',
  UpdatedIdList = 'Updated Id List',
  IndexTimestampFixed = 'Index Timestamp Fixed',
  IndexDateFixed = 'Index Date Fixed',
  ExcludeSteps = 'Exclude Steps'
}

export { StepTypes as default, StepTypeDescriptions, HiddenStepTypes }
