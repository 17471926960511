import React, { MouseEventHandler, useMemo } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import { noop } from 'lodash'

export interface CodeProps {
  alt: string
  id?: string
  className?: string
  fill?: string
  onClick?: MouseEventHandler<HTMLImageElement>
}

const Code: React.FC<CodeProps> = ({
  alt,
  id,
  fill = '#232829',
  className,
  onClick = noop
}) => {
  const markup = useMemo(
    () =>
      encodeURIComponent(
        renderToStaticMarkup(
          <svg
            width="15"
            height="10"
            viewBox="0 0 15 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.92021 9.03706C5.9918 9.06005 6.06441 9.07074 6.136 9.07074C6.42133 9.07074 6.68758 8.89906 6.78269 8.62728L9.50991 0.852903C9.62889 0.513422 9.43594 0.146731 9.07867 0.0333547C8.72004 -0.0796977 8.33516 0.103972 8.21619 0.443129L5.48897 8.2175C5.36999 8.55699 5.56294 8.92368 5.92021 9.03706ZM3.40894 7.77511C3.2344 7.77511 3.05986 7.71194 2.9269 7.58529L0.199684 4.99382C-0.0665612 4.74083 -0.0665612 4.33073 0.199684 4.07774L2.9269 1.48628C3.19281 1.23329 3.62507 1.23329 3.89098 1.48628C4.15722 1.73927 4.15722 2.14937 3.89098 2.40236L1.64613 4.53578L3.89098 6.6692C4.15722 6.92219 4.15722 7.33229 3.89098 7.58529C3.75803 7.71194 3.58348 7.77511 3.40894 7.77511ZM11.591 7.77511C11.4164 7.77511 11.2422 7.71194 11.109 7.58529C10.8427 7.33229 10.8427 6.92219 11.109 6.6692L13.3541 4.53578L11.109 2.40236C10.8427 2.14937 10.8427 1.73927 11.109 1.48628C11.3752 1.23329 11.8068 1.23329 12.073 1.48628L14.8003 4.07774C15.0665 4.33073 15.0665 4.74083 14.8003 4.99382L12.073 7.58529C11.9397 7.71194 11.7655 7.77511 11.591 7.77511Z"
              fill={fill}
            />
          </svg>
        )
      ),
    [fill]
  )

  return (
    <img
      alt={alt}
      id={id}
      className={classnames('icon-code', className)}
      onClick={onClick}
      src={`data:image/svg+xml,${markup}`}
    />
  )
}

Code.displayName = 'Code'
export default Code
