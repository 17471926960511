import React from 'react'

// Can be accessed at locale.t('modelRun.<key>')

export default {
  tooltip: {
    runsTable: <span>Information for all historical runs of this model.</span>,
    asOfDate: (
      <span>
        The <b>as of date</b> for data used in the model run.
      </span>
    )
  },
  message: {
    start: {
      success: ({ modelRunId }) => `Model run ID ${modelRunId} is in progress.`,
      failure: ({ errorMessage }) => `Failed to create model run. ${errorMessage}`
    },
    startRequested:
      'The model run is being created and will continue to run in the background. You can return to this page at any time to see if the model has finished running.',
    restart: {
      success: ({ oldModelRunId, newModelRunId }) =>
        `Model run ${oldModelRunId} has been restarted, it has been assigned a new model run ID, ${newModelRunId}.`
    },
    restartRequested: ({ modelRunId }) =>
      `Model run ${modelRunId} is being restarted and will continue to run in the background. You can return to this page at any time to see if the model has finished running.`
  }
}
