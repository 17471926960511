import React, { ReactNode } from 'react'
import { createRoot } from 'react-dom/client'
import { get, noop } from 'lodash'

import AlertPopup, { AlertPopupProps } from './AlertPopup'
import './Alert.scss'

type AlertCombos = Pick<AlertPopupProps, 'type' | 'message' | 'moreInfo'>[]

export type AlertOptions = {
  timeout?: number
  id?: string
  combos?: AlertCombos
  moreInfo?: { title: string; subTitle: string }[]
  style?: AlertPopupProps['style']
}

type AlertMethodResult = { updateMessage: (newMessage?: ReactNode) => void }

type AlertMethodType = (
  message: React.ReactNode,
  options?: number | AlertOptions
) => AlertMethodResult

interface IAlert extends React.FC<AlertPopupProps> {
  info: AlertMethodType
  success: AlertMethodType
  error: AlertMethodType
  warning: AlertMethodType
  dismiss: (alertId?: string) => void
}

const getOrCreateContainer = () => {
  const container = document.querySelector('.alert-container')
  if (container) return container

  const el = document.createElement('div')
  el.classList.add('alert-container')

  // Create container after the top navigation div
  const sibling = document.querySelector('.navbar_v3') || document.body.firstElementChild
  if (sibling) {
    sibling.insertAdjacentElement('afterend', el)
    return el
  }
}

const removeAlertNode = (container, node) => {
  container.removeChild(node)
}

interface CreateAlertProps extends AlertPopupProps {
  options?: AlertOptions | number
}

const createAlert = ({ options, ...args }: CreateAlertProps) => {
  const container = getOrCreateContainer()

  if (container) {
    const parent = document.createElement('div')
    // If id is defined, remove any alerts created with this id, then add id to new alert to be shown
    if (get(options, 'id')) {
      const alertId = get(options, 'id')
      dismissAlert(alertId)
      parent.id = 'alert_' + alertId
    }
    container.append(parent)

    const onUnmount = () => {
      try {
        removeAlertNode(container, parent)
      } catch (e) {
        // Do nothing
      }
    }

    // Extract timeout from 'options' param. 'options' can be a number or an object containing type AlertOptions
    const timeout =
      typeof options === 'number'
        ? options
        : options && typeof options === 'object'
        ? options.timeout
        : undefined

    const combos = typeof options === 'object' ? options?.combos : undefined
    const moreInfo = typeof options === 'object' ? options?.moreInfo : undefined
    const style = typeof options === 'object' ? options?.style : undefined

    const root = createRoot(parent)

    root.render(
      <AlertPopup
        onClose={onUnmount}
        timeout={timeout}
        noMargin
        combos={combos}
        moreInfo={moreInfo}
        style={style}
        {...args}
      />
    )

    return {
      updateMessage: (newMessage?: ReactNode) => {
        root.render(
          <AlertPopup
            onClose={onUnmount}
            timeout={timeout}
            noMargin
            combos={combos}
            moreInfo={moreInfo}
            style={style}
            {...args}
            message={newMessage}
          />
        )
      }
    }
  }

  return { updateMessage: noop }
}

const dismissAlert = (alertId?: string) => {
  const container = getOrCreateContainer()
  if (container) {
    // If id is defined, remove single alert
    if (alertId) {
      const alert = container.querySelector(`#alert_${alertId}`)
      if (alert) removeAlertNode(container, alert)
      return
    }

    // if no id is defined, remove all
    while (container.firstChild) {
      removeAlertNode(container, container.firstChild)
    }
  }
}

const Alert: IAlert = (props) => <AlertPopup {...props} timeout={0} />
Alert.info = (message, options) => createAlert({ type: 'info', message, options })
Alert.success = (message, options) => createAlert({ type: 'success', message, options })
Alert.error = (message, options) => createAlert({ type: 'error', message, options })
Alert.warning = (message, options) => createAlert({ type: 'warning', message, options })
Alert.dismiss = (alertId) => dismissAlert(alertId)

export default Alert
