import React, { useMemo } from 'react'
import mixpanel from 'utils/mixpanel'

import Button from 'components/Button'
import HeadlineLayout from 'components/Layout/HeadlineLayout'

import BikeAccident from '../../graphics/BikeAccident'
import ExceptionPageLayout from './components/ExceptionPageLayout'

const ServerError = () => {
  const match = document.location.search.match(/\?e=?(.+)/)
  const errorCode = match ? match[1] : ''

  mixpanel.trackPageViewed({
    page_name: 'Exception',
    exception_type: '500',
    exception_code: errorCode
  })

  var reason = useMemo(() => {
    switch (errorCode) {
      case '46851':
        return 'Check your network settings'
      default:
        return 'Server Error'
    }
  }, [errorCode])

  return (
    <ExceptionPageLayout className="server-error-page">
      <HeadlineLayout
        title={500}
        subtitle={reason}
        content={
          <Button
            type="primary"
            className="mt-16"
            onClick={() => (window.location.href = '/')}
          >
            Try Again
          </Button>
        }
        graphics={<BikeAccident className="w-2xl" />}
      />
    </ExceptionPageLayout>
  )
}

const showServerError = (error) => {
  const { pathname } = document.location

  if (pathname !== '/500') {
    var errorCode = (function (e) {
      switch (e.message) {
        case 'Network Error':
          return '46851'
        default:
          return ''
      }
    })(error)

    let route = '/500'
    if (errorCode) route += '?e=' + errorCode
    document.location = route
  }
}

export { ServerError as default, showServerError }
