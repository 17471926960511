import { Component } from 'react'
import PropTypes from 'prop-types'

import distanceFromElement from 'v1/utils/distanceFromElement'
import getElement from 'v1/utils/getElement'

let BUFFER = 55
let DELAY = 650

export default class ScrollTo extends Component {
  constructor(props) {
    super(props)

    this.scrolling = false

    this.scrollToElement = async (selector = '', options = {}) => {
      // Don't try to scroll if you're already scrolling
      if (this.scrolling && !options.instant) {
        return
      }

      this.scrolling = true
      let element = getElement(selector)

      if (element) {
        const scrollInfo = getScrollInfo(element)
        const scrollParent = scrollInfo.parent
        const scrollDistance = scrollInfo.distance - (options.offsetY || 0)

        // If the element cannot be seen in the window, scroll to it
        if (!isInView(element, options.forceTop)) {
          // const previousScrollTop = scrollParent.scrollTop
          if (options.instant) {
            scrollParent.scrollTop = scrollDistance
          } else {
            await smoothScrollTo(scrollParent, scrollDistance)
          }

          if (!isInView(element, options.forceTop) && element.localName !== 'body') {
            // scrollParent.scrollTop = previousScrollTop
            this.scrolling = false
            if (scrollParent !== document.documentElement) {
              await this.scrollToElement(scrollParent, options)
            }
          } else {
            DELAY = 100
          }
        }
      }

      await new Promise((resolve) => setTimeout(resolve, DELAY))
      return (this.scrolling = false)
    }
  }

  getChildContext() {
    return {
      scrollTo: async (selector, options = {}) => {
        await this.scrollToElement(selector, options)
      },
      scrollToInstantly: async (selector, options = {}) => {
        await this.scrollToElement(selector, {
          ...options,
          instant: true
        })
      }
    }
  }

  render() {
    return this.props.children
  }
}

ScrollTo.propTypes = {
  children: PropTypes.any
}

ScrollTo.childContextTypes = {
  scrollToInstantly: PropTypes.func,
  scrollTo: PropTypes.func
}

function isInView(element, forceTop) {
  const distance = distanceFromElement(element, document.body)
  const aboveView = distance.top < 75
  const belowView = distance.top > (forceTop ? 135 : document.body.offsetHeight)
  return !(aboveView || belowView)
}

// Find the parent element that is scrolled
// Return the scroll parent and how far the element is from the top of the parent
function getScrollInfo(element) {
  const defaultResult = {
    parent: document.documentElement,
    distance: 0
  }

  let style = getComputedStyle(element)
  let distance = (element.offsetTop || 0) - BUFFER

  const excludeStaticParent = style.position === 'absolute'
  const overflowRegex = /(auto|scroll)/

  if (style.position === 'fixed') {
    return defaultResult
  }

  for (let parent = element; (parent = parent.parentElement); ) {
    style = getComputedStyle(parent)

    if (excludeStaticParent && style.position === 'static') {
      continue
    } else {
      distance += parent.offsetTop
    }
    if (
      parent === defaultResult.parent ||
      overflowRegex.test(style.overflow + style.overflowY + style.overflowX)
    ) {
      return {
        parent,
        distance
      }
    }
  }

  return defaultResult
}

// Smoothly scroll to the target
async function smoothScrollTo(element, target) {
  target = Math.round(target)

  if (element.scrollTop === target) {
    return
  }

  const startTime = Date.now()
  const endTime = startTime + DELAY

  const startTop = element.scrollTop
  const distance = target - startTop

  // http://en.wikipedia.org/wiki/Smoothstep
  const smoothStep = (start, end, point) => {
    if (point <= start) {
      return 0
    }
    if (point >= end) {
      return 1
    }
    const x = (point - start) / (end - start) // interpolation
    return x * x * (3 - 2 * x)
  }

  return new Promise((resolve) => {
    // keep track of the element's scrollTop
    let previousTop = element.scrollTop

    const scrollFrame = function () {
      // if (element.scrollTop !== previousTop) {
      //   return reject('interrupted')
      // }

      // set the scrollTop for this frame
      const now = Date.now()
      const point = smoothStep(startTime, endTime, now)
      const frameTop = Math.round(startTop + distance * point)
      element.scrollTop = frameTop

      // If we've spent the time, we're done
      if (now >= endTime) {
        return resolve()
      }

      if (
        distance === frameTop &&
        element.scrollTop === previousTop &&
        element.scrollTop !== frameTop
      ) {
        return resolve()
      }

      previousTop = element.scrollTop
      setTimeout(scrollFrame, 0)
    }

    setTimeout(scrollFrame, 0)
  })
}
