import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export default class OvalEdit extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }
  customFill(fill = '#E0E0E0') {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17">
          <defs/>
          <g fill="none" fillRule="evenodd">
            <circle fill={fill} cx="8.5" cy="8.5" r="8.5" />
            <path fill="#676767" fillRule="nonzero" d="M13.1 6l-5.8 5.8-.3.2-2.4.6c-.1 0-.2 0-.2-.2L5 10l.2-.3L11.1 4c.2-.3.6-.3.9 0A21 21 0 0113 5c.3.3.3.7 0 1z"/>
          </g>
        </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        className={classnames('oval-edit', this.props.className)}
        style={this.props.style}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
