import Cookies from 'js-cookie'
import { isNil } from 'lodash'

import { unRotateString } from 'utils/helpers'

import generateCookieKey from './generateCookieKey'

export default function getRotatedCookie(key: string) {
  const cookieKey = generateCookieKey(key)
  const rotatedValue = Cookies.get(cookieKey)

  if (!isNil(rotatedValue)) {
    const value = unRotateString(rotatedValue)

    try {
      return JSON.parse(value)
    } catch (e) {
      return value
    }
  }
}
