import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export default class OldCode extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func,
    new: PropTypes.bool
  }

  customFill(color = '#e6e6e6') {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
        !this.props.new
        ? <svg width="22" height="17" xmlns="http://www.w3.org/2000/svg"><path d="M8.72 13.9v2.117a.2.2 0 01-.337.146l-8.09-7.61A.442.442 0 01.29 7.92l8.09-7.924a.2.2 0 01.34.143v2.414a.2.2 0 01-.06.144l-5.72 5.53 5.72 5.529a.2.2 0 01.06.143zm3.794 0v2.12a.2.2 0 00.337.146l8.144-7.614c.179-.17.18-.459.005-.631L12.853-.005a.2.2 0 00-.339.143v2.416a.2.2 0 00.06.144l5.684 5.54-5.683 5.519a.2.2 0 00-.061.143z" fill={color} fillRule="nonzero"/></svg>
        : <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.92021 9.03706C5.9918 9.06005 6.06441 9.07074 6.136 9.07074C6.42133 9.07074 6.68758 8.89906 6.78269 8.62728L9.50991 0.852903C9.62889 0.513422 9.43594 0.146731 9.07867 0.0333547C8.72004 -0.0796977 8.33516 0.103972 8.21619 0.443129L5.48897 8.2175C5.36999 8.55699 5.56294 8.92368 5.92021 9.03706ZM3.40894 7.77511C3.2344 7.77511 3.05986 7.71194 2.9269 7.58529L0.199684 4.99382C-0.0665612 4.74083 -0.0665612 4.33073 0.199684 4.07774L2.9269 1.48628C3.19281 1.23329 3.62507 1.23329 3.89098 1.48628C4.15722 1.73927 4.15722 2.14937 3.89098 2.40236L1.64613 4.53578L3.89098 6.6692C4.15722 6.92219 4.15722 7.33229 3.89098 7.58529C3.75803 7.71194 3.58348 7.77511 3.40894 7.77511ZM11.591 7.77511C11.4164 7.77511 11.2422 7.71194 11.109 7.58529C10.8427 7.33229 10.8427 6.92219 11.109 6.6692L13.3541 4.53578L11.109 2.40236C10.8427 2.14937 10.8427 1.73927 11.109 1.48628C11.3752 1.23329 11.8068 1.23329 12.073 1.48628L14.8003 4.07774C15.0665 4.33073 15.0665 4.74083 14.8003 4.99382L12.073 7.58529C11.9397 7.71194 11.7655 7.77511 11.591 7.77511Z" fill={color}/>
          </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        id={this.props.id}
        className={classnames('icon-code', this.props.className)}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
