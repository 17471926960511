import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export default class Cancel extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(color = '#8E8E8E') {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23">
          <path fill={color} d="M7.9321676,0.466666566 C3.81826428,0.466666566 0.452343377,3.76810622 0.452343377,7.80319914 C0.452343377,11.8382921 3.81826428,15.1397317 7.9321676,15.1397317 C12.0460709,15.1397317 15.4119918,11.8382921 15.4119918,7.80319914 C15.4119918,3.76810622 12.0460709,0.466666566 7.9321676,0.466666566 Z M7.9321676,2.30079971 C9.1663386,2.30079971 10.2883122,2.68596767 11.2045907,3.32791427 L3.36947482,11.0129321 C2.7149902,10.1142069 2.32229943,9.01372701 2.32229943,7.80319914 C2.32229943,4.75853812 4.82804055,2.30079971 7.9321676,2.30079971 Z M12.4948604,4.59346614 C13.149345,5.49219138 13.5420358,6.59267126 13.5420358,7.80319914 C13.5420358,10.8478602 11.0362947,13.3055986 7.9321676,13.3055986 C6.6979966,13.3055986 5.57602297,12.9204306 4.6597445,12.278484 L12.4948604,4.59346614 L12.4948604,4.59346614 Z" transform="translate(4 3)"/>
        </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        id={this.props.id}
        onClick={this.props.onClick ? this.props.onClick : null}
        className={classnames('cancel-icon', this.props.className)}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
