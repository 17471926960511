import React from 'react'

import './ModalHeader.scss'

export interface ModalHeaderProps {
  title?: string | React.ReactNode
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ title = '' }) => {
  return <h4 className="modal_v3__header">{title}</h4>
}

ModalHeader.displayName = 'ModalHeader'
export default ModalHeader
