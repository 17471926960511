import { useMemo } from 'react'
import { createState, useState } from '@hookstate/core'
import { filter, find, first, get, size } from 'lodash'

import { DataAdapterTemplateAccess } from 'state/DataAdapterTemplateState'
import { FeatureTemplateAccess } from 'state/FeatureTemplateState'
import { NavigationAccess } from 'state/NavigationState'
import { PersonStoreAccess } from 'state/PersonStoreState'
import API from 'providers/API'

import { mapValues } from 'utils/helpers'
import { getErrorDetails, unproxy } from 'utils/helpers'

// prettier-ignore
const EXCLUDED_ADAPTERS = [ 'CL_LatestPredictionIndexDate', 'CL_PredictionIndexDate', 'CL_LatestPredictionAsOfDate', 'CL_PredictionAsOfDate', 'CL_LatestPredictionPercentile', 'CL_PredictionPercentile', 'CL_LatestPredictionValue', 'CL_PredictionValue', 'CL_PredictionHistory', 'CL_LatestPredictionFactorName', 'CL_PredictionFactorName', 'CL_LatestPredictionFactorScore', 'CL_PredictionFactorScore', 'CL_LatestPredictionFactorScoreNorm', 'CL_PredictionFactorScoreNorm', 'CL_LatestPredictionFactorValue', 'CL_PredictionFactorValue', 'CL_LatestPredictionFactors', 'CL_PredictionFactorSummary', 'CL_LatestPredictionFactorSummary', 'CLX_Prediction', 'CLX_PredictionFactor' ]
// prettier-ignore
const BUILT_IN_ADAPTERS = ['CL_Prediction', 'CL_PredictionFactor', 'CL_LatestPrediction', 'CL_LatestPredictionFactor' ]

// hookState isn't synchronous so we'll point to a global obj for instant truth
let fetchState: FetchState = {}

const initialState = createState({
  dataAdapters: {},
  fetchState: {}
})

type DataAdapter = {
  addedInDataAdapterSnapshot?: number
  aggregation?: string
  createdAt?: UTCString
  createdBy?: OAuthId
  defaultSelect?: string[]
  description?: string
  entities?: string[]
  fields?: {
    dataType: { dataType: string; metadata: { role: string; source: string } }
    name: string
  }[]
  isArchived?: boolean
  name?: string
  select?: string[]
  sql?: string
  tags?: { color: string; id: string; name: string }[]
  version?: { major: number; minor: number }
  _derivedType?: string
}

const stateWrapper = (state) => {
  const wrapper = {
    get fetchedDataAdapters() {
      const { psId } = NavigationAccess()
      return !!get(fetchState, `${psId}.fetched`)
    },
    get fetchingDataAdapters() {
      const { psId } = NavigationAccess()
      return !!get(fetchState, `${psId}.fetching`)
    },
    get fetchingFailedDataAdapters() {
      const { psId } = NavigationAccess()
      return !!get(fetchState, `${psId}.failedToFetch`)
    },
    get dataAdapters(): DataAdapter[] | null {
      const { psId } = NavigationAccess()
      const dataAdapters = state.dataAdapters.get()
      if (!psId || !dataAdapters || !dataAdapters[psId]) return null
      return get(dataAdapters, `${psId}`)
    },
    findDataAdapter({ ...params }: Dictionary<any>): DataAdapter | undefined {
      // prettier-ignore
      if (!size(params)) throw new Error('DataAdapterState: findDataAdapter requires params to be passed in')

      return find<DataAdapter>(this.dataAdapters, params)
    },
    async fetchDataAdapters({
      psId = NavigationAccess().psId,
      includeArchived = false
    }: { psId?: string; includeArchived?: boolean } = {}) {
      if (!psId)
        return Promise.reject('DataAdapterState: Person Store ID was not specified')

      fetchState = { ...fetchState, [psId]: { fetching: true } }

      state.fetchState.set(fetchState)

      try {
        const res = await API.get(`/ps/personStore/${psId}/dataAdapters`, {
          params: { includeBuiltins: true, includeArchived }
        })
        const dataAdapters = res.data
          .filter((da) => !EXCLUDED_ADAPTERS.includes(da.name))
          .map(enhanceDataAdapter)

        fetchState = {
          ...fetchState,
          [psId]: { fetched: true, fetching: false }
        }

        state.dataAdapters.merge({ [psId]: dataAdapters })
        state.fetchState.set(fetchState)
        return dataAdapters
      } catch (err) {
        fetchState = {
          ...fetchState,
          [psId]: { failedToFetch: true, fetching: false }
        }

        state.fetchState.set(fetchState)
        return Promise.reject(err)
      }
    },
    async fetchDataAdapter({
      psId = NavigationAccess().psId,
      name,
      ...params
    }: {
      psId?: string
      name: string
    }) {
      if (!psId)
        return Promise.reject('DataAdapterState: Person Store ID was not specified')

      if (!name)
        return Promise.reject('DataAdapterState: Data adapter Name was not specified')

      try {
        const res = await API.get(`/ps/personStore/${psId}/dataAdapter/${name}`, {
          params
        })
        return enhanceDataAdapter(res.data)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchDataAdapterHistory({
      psId = NavigationAccess().psId,
      name
    }: {
      psId?: string
      name: string
    }) {
      if (!psId)
        return Promise.reject('DataAdapterState: Person Store ID was not specified')

      if (!name)
        return Promise.reject('DataAdapterState: Data adapter Name was not specified')

      try {
        const res = await API.get(`/ps/personStore/${psId}/dataAdapter/${name}/history`)

        return enhanceDataAdapter({
          ...res.data,
          versions: res.data.versions.map(enhanceDataAdapter)
        })
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async verifyDataAdapter({
      psId = NavigationAccess().psId,
      isDraft,
      bypassDefaultSelectCheck,
      defaultSelect,
      sql = '',
      ...dataAdapter
    }: {
      psId?: string
      isDraft?: boolean
      bypassDefaultSelectCheck?: boolean
    } & DataAdapter = {}) {
      if (!psId)
        return Promise.reject('DataAdapterState: Person Store ID was not specified')
      try {
        const res = await API.post(`/ps/personStore/${psId}/verifyDataAdapter`, {
          ...dataAdapter,
          sql: sql,
          defaultSelect: bypassDefaultSelectCheck ? ['*'] : defaultSelect,
          _derivedType: 'DataAdapterInput'
        })

        // If we skipped the default select check, it will return
        // a list of all columns. We need to auto-select the first option for the user
        if (bypassDefaultSelectCheck) {
          res.data.select = [first(res.data.select)]
          res.data.defaultSelect = res.data.select
        }

        delete res.data.createdAt
        delete res.data.createdBy
        return enhanceDataAdapter(res.data)
      } catch (err) {
        return Promise.reject(getErrorDetails(err))
      }
    },
    async queryDataAdapter({
      psId = NavigationAccess().psId,
      sessionId,
      limit = 100,
      defaultSelect,
      select,
      ...dataAdapter
    }: {
      psId?: string
      sessionId: string
      limit?: number
    } & DataAdapter) {
      if (!psId)
        return Promise.reject('DataAdapterState: Person Store ID was not specified')
      if (!sessionId) return Promise.reject('DataAdapterState: Missing session id')
      try {
        const res = await API.post(
          `/ps/personStore/${psId}/queryDataAdapter`,
          {
            ...dataAdapter,
            defaultSelect: defaultSelect || select,
            select: select,
            _derivedType: 'DataAdapterInput'
          },
          { params: { limit, querySessionId: sessionId } }
        )
        return res.data
      } catch (err) {
        return Promise.reject(getErrorDetails(err))
      }
    },
    async saveDataAdapter({
      psId = NavigationAccess().psId,
      defaultSelect = ['*'],
      sql = '',
      name,
      ...dataAdapter
    }: { psId?: string } & DataAdapter = {}) {
      if (!psId)
        return Promise.reject('DataAdapterState: Person Store ID was not specified')
      try {
        const res = await API.post(`/ps/personStore/${psId}/dataAdapter`, {
          ...dataAdapter,
          sql: sql,
          defaultSelect: defaultSelect,
          name,
          _derivedType: 'DataAdapterInput'
        })

        const newDataAdapter = enhanceDataAdapter(res.data)

        // Add new adapter to state
        if (this.fetchedDataAdapters) {
          const dataAdapters = filter(
            this.dataAdapters,
            (adapter) => adapter.name !== name
          ).map(unproxy)
          state.dataAdapters[psId].set([...dataAdapters, newDataAdapter])
        }

        // If the data adapter is a standard data adapter, refetch feature templates for the user
        const daTemplate = DataAdapterTemplateAccess().findDataAdapterTemplate({
          name: newDataAdapter.name
        })
        if (daTemplate && FeatureTemplateAccess().fetchedFeatureTemplates)
          FeatureTemplateAccess().fetchFeatureTemplates()

        // Update person store object to get the current dataAdapterSnapshot
        PersonStoreAccess().fetchPersonStore({ id: psId })

        return newDataAdapter
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async archiveDataAdapter({
      psId = NavigationAccess().psId,
      archive = true,
      name
    }: {
      psId?: string
      archive?: boolean
      name: string
    }) {
      if (!psId)
        return Promise.reject('DataAdapterState: Person Store ID was not specified')
      if (!name)
        return Promise.reject('DataAdapterState: Data adapter Name was not specified')

      try {
        await API.put(
          `/ps/personStore/${psId}/dataAdapter/archive?name=${name}&archive=${archive}`
        )

        // Update global state to show adapter as archived/unarchived
        const updatedDataAdapter = await this.fetchDataAdapter({ psId, name })
        if (this.fetchedDataAdapters) {
          const filteredList = this.dataAdapters!.map(unproxy).filter(
            (a) => a.name !== updatedDataAdapter.name
          )

          if (archive) {
            state.dataAdapters[psId].set(filteredList)
          } else {
            state.dataAdapters[psId].set([
              ...filteredList,
              enhanceDataAdapter(updatedDataAdapter)
            ])
          }
        }

        // If the data adapter is a standard data adapter, refetch feature templates for the user
        const daTemplate = DataAdapterTemplateAccess().findDataAdapterTemplate({
          name: updatedDataAdapter.name
        })
        if (daTemplate && FeatureTemplateAccess().fetchedFeatureTemplates)
          FeatureTemplateAccess().fetchFeatureTemplates()

        return enhanceDataAdapter(updatedDataAdapter)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async unarchiveDataAdapter({
      psId = NavigationAccess().psId,
      name
    }: {
      psId?: string
      name: string
    }) {
      return this.archiveDataAdapter({ psId, archive: false, name })
    }
  }
  return mapValues(wrapper, (f) =>
    typeof f === 'function' ? f.bind(wrapper) : f
  ) as typeof wrapper
}

const DataAdapterState = () => {
  const state = useState(initialState)
  return useMemo(() => stateWrapper(state), [state])
}
const DataAdapterAccess = () => stateWrapper(initialState)
export { DataAdapterState as default, DataAdapterAccess }

function enhanceDataAdapter({
  fields,
  filters,
  ...dataAdapter
}: {
  filters?: DataAdapter['fields']
} & DataAdapter = {}) {
  const createdBy = BUILT_IN_ADAPTERS.includes(dataAdapter.name!)
    ? 'cli:systems:cli:systems,api:full'
    : dataAdapter.createdBy

  // Temporary fix until PEP-181 is complete
  const modifiedFields = (fields || filters)?.map((field) => {
    if (field.dataType?.metadata?.source === 'ICD9-CM') {
      field.dataType.metadata.source = 'ICD10-CM'
    }
    return field
  })

  return {
    ...dataAdapter,
    fields: modifiedFields,
    createdBy
  }
}
