import React, { useMemo } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import { noop } from 'lodash'

export interface ExpandArrowProps {
  alt: string
  id?: string
  isExpanded: boolean
  fill?: string
  className?: string
  onClick?: () => void
}

const ExpandArrow: React.FC<ExpandArrowProps> = ({
  alt,
  id,
  isExpanded,
  fill = '#5E6B6E',
  className,
  onClick = noop
}) => {
  const markup = useMemo(
    () =>
      encodeURIComponent(
        renderToStaticMarkup(
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.8928 14.4806L12.8927 14.4807C12.8141 14.5595 12.7207 14.622 12.618 14.6646C12.5152 14.7072 12.4051 14.7291 12.2938 14.7291C12.1826 14.7291 12.0724 14.7072 11.9697 14.6646C11.8669 14.622 11.7736 14.5595 11.695 14.4807L11.6949 14.4806L7.1049 9.89064C7.02625 9.81199 6.96386 9.71861 6.9213 9.61585C6.87873 9.51308 6.85682 9.40294 6.85682 9.29171C6.85682 9.18047 6.87873 9.07033 6.9213 8.96756C6.96386 8.8648 7.02625 8.77142 7.1049 8.69277C7.18356 8.61412 7.27693 8.55173 7.3797 8.50916C7.48246 8.46659 7.59261 8.44468 7.70384 8.44468C7.81507 8.44468 7.92521 8.46659 8.02798 8.50916C8.13075 8.55173 8.22412 8.61412 8.30277 8.69277L12.1828 12.5728L12.2887 12.6787L12.3948 12.5729L16.2848 8.69291L16.2849 8.69277C16.3636 8.61412 16.4569 8.55173 16.5597 8.50916C16.6625 8.46659 16.7726 8.44469 16.8838 8.44469C16.9951 8.44469 17.1052 8.46659 17.208 8.50916C17.3107 8.55173 17.4041 8.61412 17.4828 8.69277C17.5614 8.77143 17.6238 8.8648 17.6664 8.96757C17.709 9.07033 17.7309 9.18047 17.7309 9.29171C17.7309 9.40294 17.709 9.51308 17.6664 9.61585C17.6238 9.71861 17.5614 9.81199 17.4828 9.89064L12.8928 14.4806Z"
              fill={fill}
              stroke="white"
              strokeWidth="0.3"
            />
          </svg>
        )
      ),
    []
  )

  return (
    <img
      alt={alt}
      id={id}
      style={isExpanded ? { transform: 'translateY(-1px) rotate(180deg)' } : undefined}
      className={classnames('expand-arrow-icon', className)}
      onClick={onClick}
      src={`data:image/svg+xml,${markup}`}
    />
  )
}

ExpandArrow.displayName = 'ExpandArrow'
export default ExpandArrow
