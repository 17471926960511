/**
 * Turn url into object with values
 * @param {string} url
 *
 * Example:
 * http://efg.com/session/12345/user/555/profile?name=john&age=23&city=austin#contact
 *
 * returns {
 *  host: efg.com
 *  paths: {
 *    session: 12345,
 *    user: 555
 *  },
 *  resource: 'profile',
 *  params: {
 *    name: john,
 *    age: 23,
 *    city: austin
 *  },
 *  hash: contact
 * }
 */

import { pickBy } from 'lodash'

interface URIObj {
  host: string
  params: Dictionary<string>
  paths: Dictionary<string>
  hash: string
  resource?: string
}

function parseUri(url = document.location.href) {
  const a = window.document.createElement('a')
  a.href = url

  // extract paths
  let pathname = a.pathname || ''
  if (pathname.startsWith('/')) pathname = pathname.substring(1)
  const paths = {}
  let key: string | null = null

  pathname.split('/').forEach((str) => {
    if (key) {
      paths[key] = decodeURI(str)
      key = null
    } else {
      key = str
    }
  })

  // extract params
  const param = (a.search || '').substring(1)
  const params = {}

  param.split('&').forEach((par) => {
    const kv = par.split('=')
    if (kv[1] !== undefined) {
      params[kv[0]] = decodeURI(kv[1])
    }
  })

  const res: URIObj = {
    host: a.hostname,
    params: params,
    paths: paths,
    hash: decodeURI((a.hash || '').substring(1))
  }

  if (key) res.resource = decodeURI(key)

  return res
}

// CLD-5154. I think we just need to be using encodeURIComponent instead of encodeURI in order to encode reserved characters.
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURI#encodeuri_vs_encodeuricomponent
// I'm relatively confident all usages of our custom encodeUri/decodeUri can be replaced with encodeURIComponent/decodeURIComponent, but leaving these as the change would be quite invasive.
// Because we separately handle the reserved URL characters (?, +, =, #, %, etc), there are few instances where the native encodeURI/decodeURI are actually appropriate.

function encodeUri(str = '') {
  if (typeof str === 'number' || typeof str === 'boolean') return str

  return encodeURIComponent(str)
}

function decodeUri(str = '') {
  if (typeof str === 'number' || typeof str === 'boolean') return str

  return decodeURIComponent(str)
}

/**
 * @param uri The URI string.
 * @param params A one-level-deep object. Only string, boolean and number values will be added to the final result URI.
 * @returns
 */
const addParamsToUri = (uri: string, params: QueryParams) => {
  const paramsString = new URLSearchParams(
    pickBy(
      params,
      (property) => property && typeof property !== 'object' && !Array.isArray(property)
    ) as Record<string, string>
  )

  if (paramsString.toString()) {
    var hashmarkIndex = uri.indexOf('#')

    if (hashmarkIndex !== -1) {
      uri = uri.slice(0, hashmarkIndex)
    }

    uri += (uri.indexOf('?') === -1 ? '?' : '&') + paramsString.toString()
  }

  return uri
}

const dictionaryToEncodedSearchString = (params?: QueryParams): string | undefined => {
  if (!params) return undefined

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent#description

  return Object.entries(params)
    .map(
      ([key, value]) =>
        `${fixedEncodeURIComponent(key)}=${fixedEncodeURIComponent(value)}`
    )
    .join('&')
}

const fixedEncodeURIComponent = (str) => {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return '%' + c.charCodeAt(0).toString(16).toUpperCase()
  })
}

export {
  parseUri as default,
  encodeUri,
  decodeUri,
  addParamsToUri,
  dictionaryToEncodedSearchString
}
