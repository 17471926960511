import { useMemo } from 'react'
import { createState, useState } from '@hookstate/core'
import { find, get, set } from 'lodash'

import { NavigationAccess } from 'state/NavigationState'
import API from 'providers/API'

import { mapValues } from 'utils/helpers'

// hookState isn't synchronous so we'll point to a global obj for instant truth
const fetchState = {}

const initialState = createState({
  tags: {},
  fetchState: {}
})

const stateWrapper = (state) => {
  const wrapper = {
    get fetchedTags() {
      const { psId } = NavigationAccess()
      return !!get(fetchState, `${psId}.fetched`)
    },
    get fetchingTags() {
      const { psId } = NavigationAccess()
      return !!get(fetchState, `${psId}.fetching`)
    },
    get tags() {
      const { psId } = NavigationAccess()
      const tags = state.tags.get()

      if (!psId || !tags || !tags[psId]) return null
      return get(tags, `${psId}`)
    },
    async createTag({
      psId = NavigationAccess().psId,
      name,
      color = '#2592DA' //This is defined as one of the standard colors for tags. See TagCreator.tsx
    }: {
      psId?: string
      name: string
      color?: HEX
    }) {
      if (!psId) return Promise.reject('TagState: psId was not specified')
      if (!name) return Promise.reject('TagState: tag name was not specified')

      try {
        const res = await API.post(`/tag/tags/new`, {
          psId,
          name,
          color
        })
        state.tags.merge([res.data])
        return res.data
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async createTags(tags: TagReference[]) {
      if (!tags.length) return []

      const newTags = await Promise.all(
        tags.map(async (tag) => {
          if (tag.custom) return await this.createTag(tag)

          const possibleTags = await this.suggestTags({ query: tag.name })
          const existingTag = find(possibleTags, { name: tag.name })
          return (
            existingTag ||
            (await this.createTag({
              name: tag.name,
              color: tag.color
            }))
          )
        })
      )

      return newTags
    },
    async suggestTags({
      psId = NavigationAccess().psId,
      limit = 5,
      query
    }: {
      psId?: string
      limit?: number
      query: string
    }) {
      if (!psId) return Promise.reject('TagState: psId was not specified')
      if (!query) return Promise.reject('TagState: query was not specified')

      try {
        const res = await API.get(`/tag/suggestTags`, {
          params: { psId, limit, query }
        })
        return res.data
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchTags({ psId = NavigationAccess().psId }: { psId?: string } = {}) {
      if (!psId) return Promise.reject('TagState: Person Store ID was not specified')

      set(fetchState, `${psId}.fetching`, true)
      state.fetchState.set(fetchState)

      try {
        const res = await API.get(`tag/tags?psId=${psId}`)
        const tags = res.data

        set(fetchState, `${psId}.fetched`, true)
        set(fetchState, `${psId}.fetching`, false)
        state.fetchState.set(fetchState)

        state.tags.set(tags)
        return tags
      } catch (err) {
        set(fetchState, `${psId}.failedToFetch`, true)
        set(fetchState, `${psId}.fetching`, false)
        state.fetchState.set(fetchState)
        return Promise.reject(err)
      }
    }
  }
  return mapValues(wrapper, (f) =>
    typeof f === 'function' ? f.bind(wrapper) : f
  ) as typeof wrapper
}

const TagState = () => {
  const state = useState(initialState)
  return useMemo(() => stateWrapper(state), [state])
}
export default TagState
