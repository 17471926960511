import React from 'react'

const CloseTagIcon = ({ onClick }) => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    onClick={onClick}
    className="close-tag-icon"
    focusable="false"
  >
    <path
      d="M4.40766 3.65156L7.05469 1.00452L6.2984 0.248229L3.65137 2.89526L1.00433 0.248229L0.24804 1.00452L2.89507 3.65156L0.24804 6.29859L1.00433 7.05488L3.65137 4.40785L6.2984 7.05488L7.05469 6.29859L4.40766 3.65156Z"
      fill="currentColor"
    />
  </svg>
)

export default CloseTagIcon
