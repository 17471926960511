import React, { useMemo } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import { noop } from 'lodash'

export interface ShareProps extends IconProps {
  alt: string
  id?: string
  className?: string
  fill?: Color
  onClick?: () => void
}

const Share: React.FC<ShareProps> = ({
  alt,
  id,
  fill = '#232829',
  className,
  onClick = noop
}) => {
  const markup = useMemo(
    () =>
      encodeURIComponent(
        renderToStaticMarkup(
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.99991 6.70002C8.70538 6.70002 10.0999 5.40804 10.0999 3.80002C10.0999 2.19201 8.70538 0.900024 6.99991 0.900024C5.29444 0.900024 3.89991 2.19201 3.89991 3.80002C3.89991 5.40804 5.29444 6.70002 6.99991 6.70002ZM9.04277 3.80002C9.04277 4.84298 8.13476 5.70002 6.99991 5.70002C5.86506 5.70002 4.95705 4.84298 4.95705 3.80002C4.95705 2.75707 5.86506 1.90002 6.99991 1.90002C8.13476 1.90002 9.04277 2.75707 9.04277 3.80002ZM0.900142 14.039C0.886638 14.6361 1.44522 15.1 2.10484 15.1H11.895C12.5546 15.1 13.1132 14.6361 13.0997 14.039C13.0598 12.278 12.4193 10.6566 11.359 9.45591C10.2985 8.25503 8.77052 7.43336 6.99991 7.43336C5.22929 7.43336 3.70128 8.25503 2.64081 9.45591C1.58055 10.6566 0.939972 12.278 0.900142 14.039ZM11.9766 14.0587C11.9768 14.0684 11.9727 14.0805 11.9592 14.0923C11.9453 14.1043 11.9231 14.1141 11.895 14.1141H2.10484C2.07676 14.1141 2.05447 14.1043 2.04066 14.0923C2.02712 14.0805 2.02304 14.0684 2.02327 14.0587C2.09434 10.9161 4.30809 8.41932 6.99991 8.41932C9.69172 8.41932 11.9055 10.9161 11.9766 14.0587Z"
              fill={fill}
            />
            <path d="M12 6H16" stroke={fill} strokeLinecap="round" />
            <path d="M14 8L14 4" stroke={fill} strokeLinecap="round" />
          </svg>
        )
      ),
    [fill]
  )

  return (
    <img
      alt={alt}
      id={id}
      onClick={onClick}
      className={classnames('share-icon', className)}
      src={`data:image/svg+xml,${markup}`}
    />
  )
}

Share.displayName = 'Share'
export default Share
