import { useRef } from 'react'
import { createState, useState } from '@hookstate/core'
import en from 'locales/en'
import rosetta from 'rosetta'

import varna from 'utils/varna'

// import rosetta from 'rosetta/debug';

/* 
  NOTE: Swap rosetta import to debug which keys we are missing from other locales. 
  
  # Pull text from locales/<locale>.js
  import { useLocale } from 'utils/hooks'
  const locale = useLocale()
  const string = locale.t('predictor.tooltip.about')

  # To change language
  locale.setLocale('de')
*/

export type Locale = {
  activeLocale: string
  t: (key: string, params?: any, lang?: string | undefined) => string
  setLocale: (loc: string) => void
}

export const languages = ['en']
export const defaultLanguage = 'en'

const localeSettings = createState({
  language: defaultLanguage
})

export default function useI18n() {
  const { current: i18n } = useRef(rosetta({ en }))
  const settings = useState(localeSettings)
  const activeLocale = settings.language.get()

  i18n.locale(activeLocale)

  return {
    activeLocale,
    t: (key: string, params?, lang?: (typeof languages)[number]) => {
      const doc = i18n.t(key, params, lang)
      if (!doc) varna.warn(`Missing documentation for key ${key}`)
      return doc
    },
    setLocale: (loc: (typeof languages)[number]) => {
      if (activeLocale !== loc) settings.language.set(loc)
    }
  }
}
