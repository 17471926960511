import { useMemo } from 'react'
import { createState, Downgraded, useState } from '@hookstate/core'
import {
  basicLogger,
  initialize,
  LDClient,
  LDContextCommon,
  LDFlagChangeset
} from 'launchdarkly-js-client-sdk'
import { camelCase, mapKeys, mapValues } from 'lodash'

import { unproxy } from 'utils/helpers'

import { LaunchDarklyKey } from 'v1/Constants'

let client: LDClient
let contexts = { kind: 'multi' }

/*
  Note: When you create these in LaunchDarkly, the key will be kebabCase ("new-feature")
        The keys as transformed to camelCase as they are sent to the app. ("newFeature")
        You'll need to add the camelCase versions below for the two to line up.
*/

const featureFlags = createState({
  acosbPlanProfessional: false,
  acosbPlanEnterprise: false,
  evaluate: false, // PSM Example for Peter/Carol
  phfPatientCharacteristicsQuery: false,
  providerCostDashboard: false,
  sendErrorsToRollbar: true,
  synced: false,
  teamDashboard: false,
  viewStagedSolutions: false
}).attach(Downgraded)

const addContext = (contextKey: string, context: LDContextCommon) => {
  contexts[contextKey] = context
  if (!client) {
    client = initialize(LaunchDarklyKey, contexts, {
      logger: basicLogger({ level: 'none' })
    })
  } else client.identify(contexts)
  return client
}

const setFeatureFlagUser = (user) => {
  client = addContext('user', user)
  client.on('ready', () => {
    featureFlags.merge({
      ...camelCaseObj(client.allFlags()),
      synced: true
    })
  })
  client.on('change', (changes: LDFlagChangeset) =>
    featureFlags.merge(camelCaseObj(mapValues(changes, 'current')))
  )
  client.identify
}

const setFeatureFlagPersonStore = (psId) => {
  if (psId !== contexts['person-store']?.id) {
    addContext('person-store', { key: psId, id: psId })
  }
}

const FeatureFlagState = () => {
  const state = useState(featureFlags)

  return useMemo(() => {
    return mapKeys(unproxy(state), (val, key) => camelCase(key))
  }, [state]) as typeof state.value
}

const FeatureFlagAccess = () => featureFlags.get()
export { FeatureFlagAccess, setFeatureFlagUser, setFeatureFlagPersonStore }
export default FeatureFlagState

function camelCaseObj(obj: Dictionary<any>) {
  return mapKeys(obj, (val, key) => camelCase(key))
}
