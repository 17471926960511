import React from 'react'
import classnames from 'classnames'

export interface TooltipPopupProps {
  id?: string
  invert?: boolean
  minWidth?: number | string
  maxWidth?: number | string
  overlay: React.ReactNode
  overlayStyle?: React.CSSProperties
}

const TooltipPopup: React.FC<TooltipPopupProps> = ({
  overlay,
  id,
  invert,
  minWidth,
  maxWidth,
  overlayStyle
}) => {
  return (
    <div
      className={classnames('tooltip-popup__content', { inverted: invert })}
      style={{ maxWidth, minWidth, ...overlayStyle }}
      key="content"
    >
      <div className="tooltip-popup__arrow" key="arrow">
        <span className="tooltip-popup__arrow-content" />
      </div>
      <div className="tooltip-popup__inner" id={id} role="tooltip">
        {overlay}
      </div>
    </div>
  )
}

TooltipPopup.displayName = 'TooltipPopup'
export default TooltipPopup
