import { AuthorizationAccess } from 'state/AuthorizationState'

import { rotateString } from 'utils/helpers'

import { AppEnv } from 'v1/Constants'

// In case the cookie is set to lax, we need to make the key
// specfic to the user and environment. This will return a key
// similar to "cl_master_014939253_selectedPersonStore"
export default function generateCookieKey(key: string) {
  const { uniqueId } = AuthorizationAccess()

  const env = rotateString(AppEnv)
  const rotatedKey = rotateString(key)
  return 'cl_' + env + '_' + uniqueId + '_' + rotatedKey
}
