import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export default class Rocket extends PureComponent {
  static propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func,
    raw: PropTypes.bool
  }

  getRaw(color = '#8E8E8E') {
    // prettier-ignore
    return (
      <svg className="icon-rocket" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h14v14H0z"/>
            <g className="icon-rocket-fill" fill={color} fillRule="nonzero" stroke={color} strokeWidth=".5">
                <path d="M13.039 1.316l-.07-.293-.294-.071c-.168-.041-.473-.09-.98-.09-.956 0-2.158.176-3.21.468a9.205 9.205 0 0 0-1.532.56c-.454.22-.825.467-1.074.715-.227.227-.523.677-.883 1.337-.083.153-.17.317-.258.492-.73.208-1.596.72-1.962.951-.832.52-1.506 1.066-1.801 1.457a.493.493 0 0 0 .216.756l.297.113c.478.182 1.063.402 1.517.625l-.001.003 2.633 2.635c.223.455.446 1.044.63 1.526.039.106.077.205.113.297a.49.49 0 0 0 .756.216c.391-.295.937-.97 1.457-1.8.23-.368.743-1.231.953-1.961.178-.09.346-.178.502-.263.665-.36 1.115-.658 1.339-.885.643-.643 1.211-2.022 1.523-3.69.135-.726.212-1.466.215-2.08.004-.524-.044-.843-.086-1.018zM4.003 5.981c-.233.52-.447 1.021-.623 1.442a16.2 16.2 0 0 0-1.117-.47c.264-.216.614-.47 1.036-.734.276-.174.55-.33.806-.46a8.3 8.3 0 0 0-.102.222zM7.76 10.69c-.263.421-.518.771-.734 1.036a16.43 16.43 0 0 0-.469-1.115c.423-.176.924-.39 1.45-.627l.213-.096c-.131.254-.287.526-.46.802zm4.383-8.363a11.048 11.048 0 0 1-.199 1.907c-.136.73-.33 1.417-.563 1.991-.216.535-.46.956-.69 1.183l-.002.002c-.054.056-.298.272-1.11.714-.53.287-1.195.61-1.977.963-.646.29-1.255.547-1.72.74L4.163 8.104c.192-.466.45-1.077.739-1.72.354-.786.678-1.45.962-1.975.44-.806.658-1.053.714-1.11.168-.168.454-.353.805-.523A8.167 8.167 0 0 1 8.75 2.28c.973-.272 2.075-.434 2.948-.434.177 0 .317.007.43.017.01.12.016.273.015.464zm-9.61 7.936c-.534 0-.986.354-1.132.842L.886 12.8a.237.237 0 0 0 .294.295l1.695-.515a1.187 1.187 0 0 0-.343-2.32z"/>
                <path d="M8.131 5.855A.875.875 0 1 0 9.37 4.618.875.875 0 0 0 8.13 5.855z"/>
            </g>
        </g>
      </svg>
    )
  }

  customFill(color) {
    return encodeURIComponent(renderToStaticMarkup(this.getRaw(color)))
  }

  render() {
    if (this.props.raw) {
      return this.getRaw(this.props.fill)
    }

    return (
      <img
        alt={this.props.alt}
        className={classnames('icon-rocket', this.props.className)}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
