import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export default class OvalErrorX extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(color = '#CE4D30') {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
        <svg width="16" height="16" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M8 17.1102C12.4183 17.1102 16 13.4298 16 8.88978C16 4.34974 12.4183 0.669312 8 0.669312C3.58172 0.669312 0 4.34974 0 8.88978C0 13.4298 3.58172 17.1102 8 17.1102ZM10.8572 13L12 11.8257L9.1428 8.88978L12 5.95384L10.8572 4.77954L8 7.71548L5.1428 4.77954L4 5.95384L6.8572 8.88978L4 11.8257L5.1428 13L8 10.0641L10.8572 13Z" fill={color}/>
        </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        id={this.props.id}
        className={classnames('oval-error-x-icon', this.props.className)}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
