import base64Decode from './base64Decode'

const InvalidTokenError = (message) => {
  const err = new Error(message)
  err.name = 'InvalidTokenError'
  return err
}

InvalidTokenError.prototype = new Error()
InvalidTokenError.prototype.name = 'InvalidTokenError'

const jwtDecode = (token, options?: { header: boolean }) => {
  if (typeof token !== 'string') {
    throw InvalidTokenError('Invalid token specified')
  }

  // If user inputed an empty token, just return it decoded
  // Passing it through JSON.parse will throw an error
  if (token.length === 0) return ''

  const pos = options?.header === true ? 0 : 1
  try {
    return JSON.parse(base64Decode(token.split('.')[pos]))
  } catch (e: any) {
    throw InvalidTokenError('Invalid token specified: ' + e.message)
  }
}

export default jwtDecode
