const S4 = (): string => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)

/**
 * Returns a unique id x characters long. x defaults to 8.
 * @param {Number} len  length of id returned
 */

const uid = (len: number = 8): string => {
  if (isNaN(len) || len < 1) {
    console.error('uid argument must be a number greater than 0')
    len = 8
  }

  let res = ''
  while (res.length < len) {
    res += S4()
  }

  return res.substring(0, len)
}

export default uid
