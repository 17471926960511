import React, { useEffect, useRef, useState } from 'react'
import { useDebounceFn } from 'ahooks'
import classnames from 'classnames'
import { colorToHsl } from 'colors-convert'
import { find, noop, reject, size } from 'lodash'

import TagState from 'state/TagState'

import Tooltip from 'components/Tooltip'
import { isChildOfClass, uid } from 'utils/helpers'

import Tag, { TagProps } from '../Tag'
import './TagCreator.scss'

const colors = [
  { hex: '#2592DA', name: 'blue' },
  { hex: '#0055FF', name: 'cobalt' },
  { hex: '#1FAD58', name: 'green' },
  { hex: '#5F26D9', name: 'purple' },
  { hex: '#FFC300', name: 'gold' },
  { hex: '#CE4D30', name: 'red' },
  { hex: '#A8D3F0', name: 'light blue' },
  { hex: '#9DBCFB', name: 'light cobalt' },
  { hex: '#A8F0C5', name: 'light green' },
  { hex: '#BFA8F0', name: 'light purple' },
  { hex: '#FFE799', name: 'light gold' },
  { hex: '#ECB8AC', name: 'light red' }
]

type CustomTagProps = {
  id: string
  color: string
  name: string
  custom: boolean
}

export interface TagCreatorProps {
  autoSuggest: boolean
  tags: TagProps[]
  onCreate?: (tag: CustomTagProps) => void
  disabled?: boolean
  placeholder?: string
}

const TagCreator: React.FC<TagCreatorProps> = ({
  onCreate = noop,
  autoSuggest,
  tags = [],
  disabled = false,
  placeholder = 'Type to add tag(s)'
}) => {
  const tagState = TagState()
  const inputRef = useRef<HTMLInputElement>(null)
  const [tagName, setTagName] = useState('')
  const [popupPosition, setPopupPosition] = useState(-1)
  const [suggestions, setSuggestions] = useState([])
  const [showColors, setShowColors] = useState(false)
  const [colorSelected, setColorSelected] = useState(colors[0].hex)
  const isActive = useRef(false)

  const resetCreator = () => {
    isActive.current = false
    setTagName('')
    setPopupPosition(-1)
    setShowColors(false)
    setColorSelected(colors[0].hex)
  }

  const focusInput = () => {
    if (inputRef.current) {
      const input: any = inputRef.current
      input.focus()
    }
  }

  const handleInputChange = (e) => {
    e.persist()
    const val = e.target.value
    setPopupPosition(val.length < 2 ? -1 : getTextWidth(val))
    setTagName(val)
    setSuggestions([])

    if (autoSuggest && val) {
      debouncedSuggest(val)
    }
  }

  const { run: debouncedSuggest } = useDebounceFn(
    (val) => {
      tagState.suggestTags({ query: val }).then((list) => {
        const unUsedSuggestions: any = reject(list, ({ id }) => find(tags, { id }))
        setSuggestions(unUsedSuggestions)
      })
    },
    { wait: 250 }
  )

  const handleInputFocus = () => {
    isActive.current = true
    if (popupPosition === 0) {
      setPopupPosition(getTextWidth(tagName))
    }
  }

  const handleComponentBlur = () => {
    isActive.current = false
    setPopupPosition(0)
  }

  const handleSuggestionClick = (suggestion) => {
    resetCreator()
    onCreate(suggestion)
    focusInput()
  }

  const handleColorSelection = (color = colorSelected) => {
    onCreate({
      id: uid(10),
      name: tagName,
      color: color,
      custom: true
    })
    resetCreator()
    focusInput()
  }

  const readyForColorSelection = () => {
    if (showColors) return handleColorSelection()
    setShowColors(true)
  }

  const handleKeyPress = (e) => {
    if (['Enter'].includes(e.key)) {
      readyForColorSelection()
    } else if (e.key === 'Backspace') {
      setShowColors(false)
    }
  }

  useEffect(() => {
    const onDOMClick = (e) => {
      if (isActive.current) {
        const isChild = isChildOfClass(e.target, 'tag-creator_v3')
        if (!isChild) handleComponentBlur()
      }
    }

    window.addEventListener('click', onDOMClick)
    return () => window.removeEventListener('click', onDOMClick)
  }, [])

  return (
    <span className="tag-creator_v3">
      <input
        ref={inputRef}
        className="tag-creator_v3__input"
        value={tagName}
        disabled={disabled}
        placeholder={size(tags) ? '' : placeholder}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        onFocus={handleInputFocus}
      />
      {popupPosition > 0 && (
        <Tooltip
          visible
          className="tag-creator_v3__popup-container"
          placement="right-start"
          useParentContainer
          offsetX={7}
          offsetY={-7}
          overlay={
            <div
              className={classnames('tag-creator_v3__popup', {
                'show-colors': showColors,
                'show-suggestions': autoSuggest && !!size(suggestions)
              })}
            >
              <div className="tag-creator_v3__popup-header">
                <button
                  className="tag-creator_v3__create-btn"
                  onClick={readyForColorSelection}
                >
                  <span>+</span> Create new tag "{tagName}"
                </button>
              </div>
              <div className="tag-creator_v3__popup-suggestions">
                {suggestions.map((suggestion: any, i) => (
                  <Tag
                    onClick={() => handleSuggestionClick(suggestion)}
                    key={suggestion.id + i}
                    {...suggestion}
                  />
                ))}
              </div>
              <div className="tag-creator_v3__popup-body">
                {colors.map(({ hex: color, name }, i) => {
                  const hslColor = colorToHsl(color)

                  return (
                    <button
                      key={color + i}
                      className={classnames('tag-creator_v3__popup-color', {
                        selected: colorSelected === color
                      })}
                      onFocus={() => setColorSelected(color)}
                      onClick={() => {
                        handleColorSelection(color)
                      }}
                      style={{ backgroundColor: color }}
                      aria-label={`Set tag color to ${name}`}
                    >
                      <svg
                        focusable="false"
                        viewBox="0 0 24 24"
                        fill={
                          hslColor.l < 50 ||
                          (hslColor.l < 80 && Math.abs(hslColor.h - 45) > 5)
                            ? '#fff'
                            : '#000'
                        }
                      >
                        <path d="M9.5,18.2c-0.4,0.4-1,0.4-1.4,0l-3.8-3.8C4,14,4,13.4,4.3,13s1-0.4,1.4,0l3.1,3.1l8.6-8.6c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4 L9.5,18.2z" />
                      </svg>
                    </button>
                  )
                })}
              </div>
            </div>
          }
        >
          <div className="tag-creator_v3__popup-parent" style={{ left: popupPosition }} />
        </Tooltip>
      )}
    </span>
  )
}

TagCreator.displayName = 'TagCreator'
export default TagCreator

function getTextWidth(text) {
  const el = document.createElement('span')
  el.style.fontSize = '16'
  el.style.fontFamily = 'Mulish'
  el.style.fontWeight = '550'
  el.style.opacity = '0'
  el.innerText = text
  document.body.appendChild(el)
  const width = el.offsetWidth
  document.body.removeChild(el)
  return width
}
