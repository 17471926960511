import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import PropTypes from 'prop-types'

export default class OvalNew extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(color = '#4a4a4a') {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(

        <svg style={{marginLeft: '-1px', marginTop: '-1px'}} width="18px" height="18px" viewBox="-1 -1 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <circle stroke="#CBCDD1" fill="#FFFFFF" cx="8.0306542" cy="8.0306542" r="7.99999505"></circle>
            <polygon fill={color} points="8.53172491 4.69714218 8.53172491 7.56691967 11.3641662 7.56691967 11.3641662 8.47422637 8.53172491 8.47422637 8.53172491 11.3641662 7.51566487 11.3641662 7.51566487 8.47422637 4.69714218 8.47422637 4.69714218 7.56691967 7.51566487 7.56691967 7.51566487 4.69714218"></polygon>
          </g>
      </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        className={this.props.className}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
