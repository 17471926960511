import { localStorage } from 'utils/storage'

/* 
  Talk across tabs without the need for a service worker
*/

export default class TabSync {
  private id
  private callback = (message: string) => {}

  constructor(id) {
    this.id = id

    if (window.localStorage) {
      // Cleanup any leftover tabSync messages. Wait a few seconds before doing so to give more resources to the page load
      const leftovers = localStorage.keys().filter((key) => key.startsWith(id))
      setTimeout(() => {
        leftovers.forEach((key) => localStorage.removeItem(key))
      }, 6000)

      this.onStorage = this.onStorage.bind(this)
      window.addEventListener('storage', this.onStorage)
    }
  }

  onStorage(event: StorageEvent) {
    if (event.storageArea !== window.localStorage) return
    if (event.newValue === null || !event.key) return
    if (event.key.substring(0, this.id.length) !== this.id) return
    const data = JSON.parse(event.newValue)
    this.callback(data)
  }

  onMessage(callback: (message: string) => void) {
    this.callback = callback
  }

  message(message: string) {
    const value = JSON.stringify(message)
    const key = this.id + Math.floor(Math.random() * 100000)
    localStorage.setItem(key, value)
    setTimeout(() => {
      localStorage.removeItem(key)
    })
  }
}
