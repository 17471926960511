import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export default class Warning extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(color = '#FFBC53') {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
        <svg width="16" height="14" xmlns="http://www.w3.org/2000/svg">
          <defs/>
          <path d="M8.1.4c.3 0 .5.2.6.5l7 12.4v.5l-.4.2H1c-.2 0-.4 0-.4-.2v-.5L7.6 1c.1-.4.3-.6.5-.6zm1 10.5c0-.5-.5-.9-1-.9-.4 0-.8.4-.8 1 0 .4.4.8.9.8.4 0 .8-.4.8-.9zM8 9.2c-.3 0-.5-.3-.5-.6l-.3-2.7c0-.4.4-.8.9-.8.4 0 .9.4.8.8l-.3 2.7c0 .3-.2.6-.5.6z" fill={color} fillRule="evenodd"/>
        </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        id={this.props.id}
        className={classnames('warning-icon', this.props.className)}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
