import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import './Arrow.scss'

export default class Arrow extends PureComponent {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(color = '#8E8E8E') {
    // prettier-ignore
    return encodeURIComponent(renderToStaticMarkup(
      <svg width="16px" height="12px" viewBox="0 0 8 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs />
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1350.000000, -405.000000)" fill={color} fillRule="nonzero">
            <g transform="translate(288.000000, 53.000000)">
              <g transform="translate(32.000000, 157.000000)">
                <g transform="translate(28.000000, 171.000000)">
                  <path d="M1012,26.684194 C1012,26.8344223 1011.94996,26.9862155 1011.84987,27.1129707 L1006.5186,33.7449248 C1006.3888,33.9061073 1006.19331,34 1005.98531,34 C1005.77888,34 1005.58183,33.9061073 1005.45359,33.7449248 L1000.15047,27.1411385 C999.914329,26.8469414 999.961245,26.4165998 1000.25525,26.1803032 C1000.54926,25.9440066 1000.97933,25.990953 1001.21548,26.2851501 L1005.98688,32.225428 L1010.78486,26.2554174 C1011.02101,25.9612203 1011.45108,25.9142739 1011.74509,26.1505705 C1011.91242,26.2867149 1012,26.4838896 1012,26.684194 Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    ))
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        className={classnames(
          'cl_arrow',
          this.props.className,
          this.props.opened || this.props.open ? 'arrow-open' : 'arrow-close'
        )}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
