import { useRef } from 'react'

/* 
  NOTE: !!!!!!
  This will be rarely used and if it is, it should have a good reason.
  This is useful when you need to run something once before the component mounts.
  This will slow down the time to render since it will be added to the event loop before the render function. 
*/

export default function useComponentWillMount(callback) {
  const willMount = useRef(true)
  if (willMount.current) {
    callback()
    willMount.current = false
  }
}
