import React, { PureComponent } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export default class Magnifier extends PureComponent {
  static defaultProps = {
    alt: 'magnifier'
  }

  static propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func
  }

  customFill(color = '#9B9B9B') {
    // prettier-ignore
    return encodeURIComponent(
      renderToStaticMarkup(
        <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-357.000000, -11.000000)" fill={color}>
                  <g>
                      <g transform="translate(357.000000, 11.000000)">
                          <path d="M5.88875,10.696875 C3.136875,10.696875 0.9060625,8.505 0.9060625,5.796875 C0.9060625,3.08875 3.136875,0.8925 5.88875,0.8925 C8.640625,0.8925 10.871875,3.08875 10.871875,5.796875 C10.871875,8.505 8.640625,10.696875 5.88875,10.696875 L5.88875,10.696875 Z M13.8635,13.234375 L10.2501875,9.6775 C11.1960625,8.649375 11.7775,7.293125 11.7775,5.796875 C11.7775,2.594375 9.141125,0 5.88875,0 C2.636375,0 0,2.594375 0,5.796875 C0,8.995 2.636375,11.589375 5.88875,11.589375 C7.294,11.589375 8.582875,11.10375 9.59525,10.294375 L13.223,13.864375 C13.4001875,14.039375 13.68675,14.039375 13.8635,13.864375 C14.0406875,13.69375 14.0406875,13.409375 13.8635,13.234375 L13.8635,13.234375 Z"></path>
                      </g>
                  </g>
              </g>
          </g>
        </svg>
      )
    )
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        id={this.props.id}
        className={classnames('icon-magnifier', this.props.className)}
        onClick={this.props.onClick ? this.props.onClick : null}
        src={`data:image/svg+xml,${this.customFill(this.props.fill)}`}
      />
    )
  }
}
