import { useMemo } from 'react'
import { createState, useState } from '@hookstate/core'
import { filter, find, get, identity, keyBy, orderBy, pickBy, set } from 'lodash'

import { NavigationAccess } from 'state/NavigationState'
import API from 'providers/API'

import { mapValues } from 'utils/helpers'
import { unproxy } from 'utils/helpers'

// hookState isn't synchronous so we'll point to a global obj for instant truth
const fetchState = {}

const initialState = createState({
  populations: {},
  fetchState: {}
})

const stateWrapper = (state) => {
  const wrapper = {
    get fetchedPopulations(): boolean {
      const { psId } = NavigationAccess()
      const status = state.fetchState[psId!].get() || {}
      return status.fetchedPopulations || !!get(fetchState, `${psId}.fetchedPopulations`)
    },
    get fetchingPopulations() {
      const { psId } = NavigationAccess()
      const status = state.fetchState[psId!].get() || {}
      return (
        status.fetchingPopulations || !!get(fetchState, `${psId}.fetchingPopulations`)
      )
    },
    get populations(): Population[] | null {
      const { psId } = NavigationAccess()
      const populations = state.populations.get()
      if (!psId || !populations || !populations[psId]) return null
      return orderBy(Object.values(populations[psId]), 'name')
    },
    get populationIds(): string[] | null {
      const populations = this.populations
      if (!populations) return null
      return populations.map((p) => (p as { id: string }).id)
    },
    findPopulation({ ...predicates }: {} | VersionedId): Population | null {
      const populations = this.populations
      if (!populations) return null

      return find(unproxy(populations), predicates) || null
    },
    async fetchPopulations({
      psId = NavigationAccess().psId,
      includeArchived = false
    }: { psId?: string; includeArchived?: boolean } = {}): Promise<Population[]> {
      if (!psId) return Promise.reject('PopulationState: Person Store ID was not specified') //prettier-ignore

      if (!includeArchived) {
        set(fetchState, `${psId}.fetchingPopulations`, true)
        state.fetchState.set(fetchState)
      }

      try {
        const res = await API.get(
          `/ps/personStore/${psId}/populations?includeArchived=${includeArchived}`
        )
        const populationsData = keyBy(res.data, 'id')
        if (!includeArchived) {
          set(fetchState, `${psId}.fetchedPopulations`, true)
          set(fetchState, `${psId}.fetchingPopulations`, false)
          state.fetchState.set(fetchState)
          state.populations.merge({ [psId]: populationsData })
        }
        return orderBy(res.data, 'name')
      } catch (err) {
        if (!includeArchived) {
          set(fetchState, `${psId}.failedToFetchPopulations`, true)
          set(fetchState, `${psId}.fetchingPopulations`, false)
          state.fetchState.set(fetchState)
        }
        return Promise.reject(err)
      }
    },
    async fetchPopulation({
      popId: { id, majorVersion, minorVersion } = {},
      psId = NavigationAccess().psId
    }: {
      popId?: { id?: string; majorVersion?: number; minorVersion?: number }
      psId?: string
    } = {}): Promise<Population> {
      if (!id)
        // prettier-ignore
        return Promise.reject('PopulationState: Population details should be passed as popId: { id, majorVersion, minorVersion}')

      try {
        const params = { majorVersion, minorVersion, psId }
        const { data } = await API.get(`/ps/population/${id}`, { params })

        // Ensure psId in the URL matches the psId associated with this populationId in the backend
        if (psId !== data.psId) return Promise.reject(`No Population found with id ${id}`)

        return data
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchPopulationHistory({
      id,
      psId = NavigationAccess().psId
    }: {
      id?: string
      psId?: string
    } = {}) {
      if (!id) return Promise.reject('PopulationState: Population ID was not specified')

      try {
        const res = await API.get(`/ps/populationHistory/${id}?includeArchived=true`)

        // Ensure psId in the URL matches the psId associated with this populationId in the backend
        if (psId !== res.data.psId)
          return Promise.reject(`No PopulationHistory found with id ${id}`)
        return res.data
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchPopulationEntity(popSteps: PopulationSelectionStep[] = []) {
      const psId = NavigationAccess().psId

      try {
        const res = await API.post(`/ps/personStore/${psId}/populations/entity`, [
          popSteps
        ])

        return res.data
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchPopulationUsage({
      psId = NavigationAccess().psId,
      id
    }: { psId?: string; id?: string } = {}) {
      // prettier-ignore
      if (!psId) return Promise.reject('PopulationState: Person Store ID was not specified')
      if (!id) return Promise.reject('PopulationState: Population ID was not specified')

      try {
        const res = await API.get(`/pred/personStore/${psId}/modelPopulationUsage/${id}`)

        return res.data
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async fetchPopulationFunnel({
      psId = NavigationAccess().psId,
      steps,
      clExpressionParams,
      asOfDate,
      primaryEntityName = 'person',
      dataAdapterSnapshot,
      allowStale = false,
      retryCount = 3
    }: {
      psId?: string
      steps?: {
        clExpression?: string
        exclude?: boolean
        id?: string
        entity?: any
        _derivedType: string
      }[]
      clExpressionParams?: Dictionary<string>
      asOfDate?: string
      primaryEntityName?: string
      dataAdapterSnapshot?: number
      allowStale?: boolean
      retryCount?: number
    } = {}) {
      if (!psId)
        return Promise.reject('PopulationState: Person Store ID was not specified')
      if (!steps) return Promise.reject('PopulationState: Population steps not specified')

      try {
        let previousEntity = primaryEntityName

        const params = pickBy({ psId, dataAdapterSnapshot }, identity)

        const url = `ps/populations/${
          allowStale && !asOfDate ? 'funnelAllowStale' : 'funnel'
        }`

        const requestBody: any = { popSteps: steps }
        if (clExpressionParams) requestBody.parameters = clExpressionParams
        if (asOfDate) requestBody.asOfDate = asOfDate

        const { data: funnelData } = await API.post(url, requestBody, {
          params
        })

        const isTimeout = funnelData === null
        if ((isTimeout || (funnelData.isStale && !funnelData.cached)) && retryCount > 0) {
          return this.fetchPopulationFunnel({
            psId,
            steps,
            clExpressionParams,
            asOfDate,
            primaryEntityName,
            dataAdapterSnapshot,
            allowStale: isTimeout ? allowStale : false,
            retryCount: isTimeout ? retryCount - 1 : 3
          })
        }

        const data = funnelData.cached || funnelData

        data.simplifiedFunnel = data.simplifiedFunnel.map(({ ...d }, i) => {
          const step = i > 0 ? steps[i - 1] : { _derivedType: 'EntirePopulation' }

          if (step && (step.clExpression || step.entity)) {
            const stepEntity =
              step.entity ||
              get(
                step.clExpression?.match(/entity=\[?([^\].]+)\]/i) ||
                  step.clExpression?.match(/resource=\[?([^\].]+)\]/i),
                '[1]'
              )
            previousEntity = stepEntity || previousEntity
          }

          return {
            ...step,
            entityCount: d.entityCount || d.count || 0,
            primaryEntityCount: d.primaryEntityCount,
            entity: previousEntity
          }
        })

        const res = { data, isStale: null }
        if (allowStale) res.isStale = funnelData.isStale

        return res
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async savePopulation({
      psId = NavigationAccess().psId,
      id,
      ...population
    }: { psId?: string; id?: string } = {}) {
      if (!psId)
        return Promise.reject('PopulationState: Person Store ID was not specified')
      const pop = { ...population, id, psId }

      try {
        const res = pop.id
          ? await API.put(`/ps/population/${pop.id}`, pop)
          : await API.post(`/ps/population?psId=${psId}`, pop)

        // Add new population to state
        if (this.fetchedPopulations) {
          const populations = filter(this.populations, ({ id }) => id !== pop.id).map(
            unproxy
          )
          state.populations[psId].set([...populations, res.data])
        }

        return res.data
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async archivePopulation({
      psId = NavigationAccess().psId,
      archive = true,
      id
    }: { psId?: string; archive?: boolean; id?: string } = {}) {
      // prettier-ignore
      if (!psId) return Promise.reject('PopulationState: Person Store ID was not specified')
      if (!id) return Promise.reject('PopulationState: Population ID was not specified')

      try {
        await API.put(`/ps/population/${id}/archive?archive=${archive}`)

        // Update global state to show population as archived/unarchived
        const updatedPopulation = await this.fetchPopulation({ popId: { id }, psId })
        if (this.fetchedPopulations)
          state.populations[psId].set([
            ...this.populations!.map(unproxy),
            updatedPopulation
          ])

        return updatedPopulation
      } catch (err) {
        return Promise.reject(err)
      }
    }
  }
  return mapValues(wrapper, (f) =>
    typeof f === 'function' ? f.bind(wrapper) : f
  ) as typeof wrapper
}

const PopulationState = () => {
  const state = useState(initialState)
  return useMemo(() => stateWrapper(state), [state])
}
const PopulationAccess = () => stateWrapper(initialState)
export { PopulationState as default, PopulationAccess }
