import React from 'react'

import { Link } from 'providers/Router'

/*
  Can be accessed at: 

  const locale = useLocale()
  locale.t('terminology.<key>')
*/

export default {
  inline: {},
  tooltip: {
    CCSR: ({ psId }) => (
      <span>
        <b>CCSR</b> expands on CCS categories to reflect the specificity of the
        ICD-10-CM/PCS. View{' '}
        <Link
          href={`/platform/${psId}/data-sources/terminologies/CCSR`}
          className="inline"
        >
          Terminologies &gt; CCSR
        </Link>{' '}
        to learn more information.
      </span>
    )
  }
}
